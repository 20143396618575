import React from 'react';
import { useSnackbar, } from 'notistack';
import {
  authenticationServices,
  helpersServices,
} from '../services';
import { AxiosResponse, } from 'axios';
import jwtDecode from 'jwt-decode';
import { helpers, } from '../utils';
import { axiosClient, } from '../config/helpers';
import { Interfaces, } from '../config';
import { Redirect, } from 'react-router-dom';

const initialHealthState: Interfaces.HealthStateV1 = {
  status: null,
  isAuthenticationEnabled: false,
  license: null,
  version: null,
  deploymentType: 'local',
};

interface AuthContextType {
  user: Interfaces.User | null;
  loading: boolean;
  error: string | null;
  health: Interfaces.HealthStateV1;
  deploymentType: Interfaces.DeploymentType;
  loadingHealth: boolean;
  errorHealth: string | null;
  authenticate: () => void;
  // eslint-disable-next-line no-unused-vars
  isAuthorized: (permission: Interfaces.AuthorityType) => boolean;
  // eslint-disable-next-line no-unused-vars
  hasAllAuthorities: (permissions: Array<Interfaces.AuthorityType>) => boolean;
  // eslint-disable-next-line no-unused-vars
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  fetchApiStatus: () => void;

}

const AuthContext = React.createContext<AuthContextType>({
  user: null,
  error: null,
  health: initialHealthState,
  deploymentType: 'local',
  authenticate: () => {},
  isAuthorized: () => false,
  hasAllAuthorities: () => false,
  loading: false,
  loadingHealth: false,
  errorHealth: null,
  login: async () => {},
  logout: () => {},
  fetchApiStatus: () => {},
});

const AuthProvider = ({ children, }: { children: React.ReactNode}) => {
  const [user, setUser,] = React.useState<Interfaces.User | null>(null);
  const [loading, setLoading,] = React.useState(false);
  const [error, setError,] = React.useState<string | null>(null);
  const [isAuthEnabled, setIsAuthEnabled,] = React.useState(true);
  const [health, setHealth,] = React.useState<Interfaces.HealthStateV1>({ ...initialHealthState, });
  const [loadingHealth, setLoadingHealth,] = React.useState(false);
  const [errorHealth, setErrorHealth,] = React.useState<string | null>(null);
  const [deploymentType, setDeploymentType,] = React.useState<Interfaces.DeploymentType>('local');

  const { enqueueSnackbar, } = useSnackbar();

  const authenticate = () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      return;
    }
    const tokenResponse: Interfaces.TokenResponse = jwtDecode(token);
    if (tokenResponse) {
      const authorities: Array<Interfaces.Authority> = JSON.parse(tokenResponse?.authorities || '[]') || [];
      setUser({
        ...tokenResponse,
        authorities: authorities.map((val) => val.authority),
      });
      setError(null);
    } else {
      setError('Invalid token');
    }
  };

  const isAuthorized = (permissionRequired: Interfaces.AuthorityType) => {
    if (!isAuthEnabled) {
      return true;
    }
    return !!(user && (user.authorities.includes(permissionRequired) || user.authorities.includes('ADAPTIVE_ADMIN')));
  };

  const hasAllAuthorities = (permissionsRequired: Array<Interfaces.AuthorityType>) => {
    if (!isAuthEnabled) {
      return true;
    }
    if (!user) {
      return false;
    }
    if (user.authorities.includes('ADAPTIVE_ADMIN')) {
      return true;
    }
    let hasAccess = true;
    permissionsRequired.forEach((permission) => {
      if (!user.authorities.includes(permission)) {
        hasAccess = false;
      }
    });
    return hasAccess;
  };

  const login = async (username: string, password: string) => {
    let hashedPassword = password;
    if (deploymentType === 'cloud') {
      hashedPassword = await helpers.sha256(password);
    }
    authenticationServices.authenticate({
      username: username,
      password: hashedPassword,
    })
      .then((response: AxiosResponse<{ token: string }>) => {
        sessionStorage.setItem('token', response.data.token);
        axiosClient().defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        authenticate();
      })
      .catch((error) => {
        const msg = helpers.getErrorMessage(error);
        setError(msg);
        enqueueSnackbar(msg);
      })
      .finally(() => setLoading(false));
  };

  const logout = () => {
    sessionStorage.removeItem('token');
    axiosClient().defaults.headers.common['Authorization'] = '';
    setUser(null);
  };

  const fetchApiStatus = () => {
    setLoadingHealth(true);
    setErrorHealth(null);
    helpersServices.fetchApiStatus()
      .then((response: AxiosResponse<Interfaces.HealthStateV1>) => {
        setHealth(response.data);
        const hasAuth = response.data.isAuthenticationEnabled;
        setIsAuthEnabled(hasAuth);
        setDeploymentType(response.data.deploymentType);
        if (hasAuth) {
          authenticate();
        } else {
          logout();
        }
      })
      .catch((error) => {
        setHealth({
          ...health,
          status: 'STOPPED',
        });
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => setLoadingHealth(false));
  };

  React.useEffect(() => {
    fetchApiStatus();
  }, []);

  const memoizedValue = React.useMemo(
    () => {
      return {
        user,
        health,
        deploymentType: deploymentType,
        authenticate,
        isAuthorized,
        hasAllAuthorities,
        login,
        logout,
        error,
        loading,
        loadingHealth,
        errorHealth,
        fetchApiStatus,
      };
    },
    [user, loading, error, health, loadingHealth, errorHealth,]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

const AuthorizedHoc = ({
  children,
  authoritiesRequired,
}: {
  children: React.ReactNode,
  authoritiesRequired: Array<Interfaces.AuthorityType>;
}) => {
  const { hasAllAuthorities, } = useAuth();
  const { enqueueSnackbar, } = useSnackbar();

  React.useEffect(() => {
    if (!hasAllAuthorities(authoritiesRequired)) {
      enqueueSnackbar('Not authorized to view this page', { variant: 'error', });
    }
  }, [authoritiesRequired,]);

  if (!hasAllAuthorities(authoritiesRequired)) {
    return <Redirect to={'/'} />;
  }

  return (
    <>
      {children}
    </>
  );
};

const MockedAuthProvider = ({ children, }: { children: React.ReactNode }) => {
  return (
    <AuthContext.Provider
      value={{
        user: {},
        isAuthorized: () => true,
      } as any}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider, useAuth, AuthorizedHoc, MockedAuthProvider, };

