import React, { useState, } from 'react';
import OverflowTip from './OverflowTip';
import {
  Typography,
  createStyles,
  makeStyles,
  Grid,
  Tooltip,
} from '@material-ui/core';
import {
  AddCircle,
  Delete,
} from '@material-ui/icons';
import { Icon, } from '../../Icon';

const styles = makeStyles((theme) => createStyles({
  schemaItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  schemaItemText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    marginLeft: 5,
    width: 170,
  },
  schemaItemIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  schemaAddIcon: {
    color: theme.palette.primary.main,
    marginRight: 4,
    '&:hover': {
      color: '#83abe7',
    },
  },
  schemaItemDeleteIcon: {
    color: 'rgba(0,0,0,0.30)',
    fontSize: 22,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  tableItem: {
    padding: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableWraper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tableItemText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    marginLeft: 5,
    width: 158,
  },
  tableIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  tableDeleteIcon: {
    color: 'rgba(0,0,0,0.30)',
    marginRight: 4,
    fontSize: 22,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  columnItem: {
    padding: 1,
    display: 'flex',
    alignItems: 'center',
  },
  columnItemText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginLeft: 5,
    width: 175,
  },
  columnIcon: {
    fontSize: 20,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
}));


const SchemaTreeItem = ({
  label,
  handleAddTable,
  handleDeleteSchema,
}: {
  label: string,
  handleAddTable: () => void,
  handleDeleteSchema: () => void,
}) => {
  const classes = styles();
  const [hover, setHover,] = useState(false);
  return (
    <div className={classes.schemaItem} onMouseEnter={() => setHover(true)} onMouseLeave={(() => setHover(false))}>
      <Typography variant={'caption'} className={classes.schemaItemText}>
        <OverflowTip>
          {label}
        </OverflowTip>
      </Typography>
      <div className={classes.schemaItemIcons}>
        { hover &&
      <Tooltip title={'Delete Schema'}>
        <Delete
          className={classes.schemaItemDeleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteSchema();
          }}
        />
      </Tooltip>
        }
        <Tooltip title={'Add Table'}>
          <AddCircle
            className={classes.schemaAddIcon}
            onClick={(e) => {
              e.stopPropagation();
              handleAddTable();
            }}
          />
        </Tooltip>
      </div>

    </div>
  );
};

const TableTreeItem = ({ label, handleDelete, }: {label: string, handleDelete: () => void} ) => {
  const [hover, setHover,] = useState(false);
  const classes = styles();
  return (
    <Grid container className={classes.tableItem} onMouseEnter={() => setHover(true)} onMouseLeave={(() => setHover(false))}>
      <div className={classes.tableWraper}>
        <Icon type="table" color="#D67E0A" size="md" />
        <Typography className={classes.tableItemText} variant={'caption'}>
          <OverflowTip>
            {label}
          </OverflowTip>
        </Typography>
      </div>
      { hover &&
      <Tooltip title={'Delete Table'}>
        <Delete
          className={classes.tableDeleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        />
      </Tooltip>
      }
    </Grid>
  );
};

const ColumnTreeItem = ({ label, }: {label: string} ) => {
  const classes = styles();
  return (
    <div className={classes.columnItem}>
      <Icon type="column" color="#1E5CAE" size="md" />
      <Typography
        variant={'caption'}
        className={classes.columnItemText}
      >
        <OverflowTip>
          {label}
        </OverflowTip>
      </Typography>
    </div>
  );
};

export const TreeItems = {
  Schema: SchemaTreeItem,
  Table: TableTreeItem,
  Column: ColumnTreeItem,
};
