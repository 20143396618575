import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    methodInput: {
      width: '170px',
      '& .MuiInputBase-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    urlInput: {
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  })
);
