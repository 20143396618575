import React from 'react';
import { SearchInput, } from './SearchInput/SearchInput';
import { NoSearch, } from './NoSearch/NoSearch';
import {
  Box,
  Grid,
} from '@material-ui/core';
import { useAuth, } from '../../context';

function Search() {
  const { isAuthorized, } = useAuth();
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_SEARCH_READ'));

  return (
    <Grid justifyContent="center" container>
      <Box alignItems="center" justifyContent="center">
        { readAccess && (
          <>
            <SearchInput/>
            <NoSearch/>
          </>
        )}
      </Box>
    </Grid>
  );
}

export default Search;
