import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';


const ModalConfirmation = ({
  open,
  onClose,
  title,
  onBtnClick,
  colorBtn,
  btnText,
  onCancelBtnClick,
} : {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined,
  title: string,
  onBtnClick: () => void,
  colorBtn: 'primary' | 'secondary' | 'inherit' | 'default',
  btnText: string,
  onCancelBtnClick: () => void,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogActions style={{
        justifyContent: 'center',
        paddingBottom: '20px',
      }}>
        <Button
          onClick={onBtnClick}
          color={colorBtn}
          variant="contained"
        >
          {btnText}
        </Button>
        <Button
          onClick={onCancelBtnClick}
          color="default"
          variant="contained"
        >
        Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmation;
