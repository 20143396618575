import React, {
  useEffect,
  useState,
} from 'react';
import {
  Typography,
  Button,
  CardMedia,
  MenuItem,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import { useStyles, } from './styles';
import {
  MergeTypeOutlined,
  Edit,
  ArrowRightAltRounded,
  ArrowDropDown,
} from '@material-ui/icons';
import gitlab_logo from '../../../assets/icons/gitlab.png';
import github_logo from '../../../images/github.png';
import bitbucket_logo from '../../../images/bitbucket.png';
import { Interfaces, } from '../../../config';
import {
  datasourceServices,
  gitConfigServices,
} from '../../../services';
import { helpers, } from '../../../utils';
import { useSnackbar, } from 'notistack';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { AxiosResponse, } from 'axios';
import { rosettaTranslationMatrix, } from '../helpers';
import { DataSourceLogo, } from '../../DatasourceIcon';

// eslint-disable-next-line no-unused-vars
const DeploymentHeader = ({ collection, handleAdd, handleAddWithTests, }: { collection: Interfaces.Collection,  handleAdd: (targetDatasourceId: string, operationLevel: string,) => void, handleAddWithTests: (targetDatasourceId: string, operationLevel: string,) => void }) => {
  const { id, } = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const options = ['Deploy', 'Deploy with tests',];

  const [dataSources, setDataSources,] = useState<Array<Interfaces.JDBCDataSource>>([]);
  const [gitConfig, setGitConfig,] = useState<Interfaces.ExternalRepo | null>(null);
  const [loadingDataSources, setLoadingDataSources,] = useState<boolean>(false);
  const [targetDataSource, setTargetDataSource,] = useState<string | null>(null);

  const fetchDataSources = () => {
    setLoadingDataSources(true);
    datasourceServices.fetchDataSources().then((result: AxiosResponse<Array<Interfaces.JDBCDataSource>>) => {
      setDataSources(result.data.filter((db: Interfaces.JDBCDataSource) => db.type === 'JDBC'));
    }).catch((error) => {
      enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
    }).finally(() => {
      setLoadingDataSources(false);
    });
  };

  const [selectedIndex, setSelectedIndex,] = React.useState(0);

  const fetchGitConfig = () => {
    if (collection.externalRepoId && collection.externalRepoId !== '-1') {
      gitConfigServices.fetchGitConfigById(collection.externalRepoId)
        .then((response2: AxiosResponse<{ data: Interfaces.ExternalRepo}>) => {
          setGitConfig(response2.data.data);
        });
    }
  };

  useEffect(() => {
    fetchDataSources();
    fetchGitConfig();
  }, []);

  const getGitConfigLogo = (gitConfigType: string) => {
    if (gitConfigType === 'GITHUB') {
      return github_logo;
    } else if (gitConfigType === 'GITLAB') {
      return gitlab_logo;
    }
    return bitbucket_logo;
  };

  const [open, setOpen,] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const clickButton = () => {
    selectedIndex === 0 && targetDataSource && handleAdd(targetDataSource, operationLevel);
    selectedIndex === 1 && targetDataSource && handleAddWithTests(targetDataSource, operationLevel);
  };


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const [operationLevel, setOperationLevel,] = React.useState('schema');

  const handleChangeOperationLevel = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOperationLevel(event.target.value as string);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        padding: 10,
        borderRadius: 7,
        marginBottom: 20,
        height: 200,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6'>
            {collection.name}
          </Typography>
          <Tooltip title="Edit">
            <IconButton
              style={{
                padding: 5,
                marginRight: 10,
                marginLeft: 6,
              }}
              aria-label="edit"
              onClick={() => history.push(`/collection/edit/${id}`)}
            >
              <Edit color={'primary'} style={{ fontSize: 18, }}/>
            </IconButton>
          </Tooltip>
        </div>
        {
          gitConfig && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
              }}
            >
              <CardMedia
                component="img"
                image={getGitConfigLogo(gitConfig.type)}
                alt="git"
                className={classes.gitIcon}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 10,
                }}
              >
                <Typography variant={'subtitle2'}>{gitConfig.url}</Typography>
                <MergeTypeOutlined />
                <a href={gitConfig.url} target={'_blank'} rel="noreferrer">{gitConfig.branch}</a>
              </div>
            </div>
          )
        }
      </div>
      <div style={{ display: 'flex', }}>
        <TextField
          label="Source"
          select
          value={1}
          onChange={(e) => {
            setTargetDataSource(e.target.value);
          }}
          defaultValue={1}
          variant="outlined"
          disabled

          InputProps={{
            startAdornment:
              loadingDataSources ? (
                <CircularProgress size={20} style={{ marginRight: 10, }} />
              ) : ''
            ,
            classes: { input:  classes.customInput, },
          }}
          fullWidth
          style={{
            margin: '20px 0',
          }}
        >
          <MenuItem value={1}>
            <div style={{
              display: 'flex',
              maxHeight: '30px',
              minHeight: '30px',
            }}>
              <div
                style={{
                  marginRight: '10px',
                  maxHeight: '30px',
                  minHeight: '30px',
                  display: 'grid',
                  alignContent: 'center',
                }}
              >
                <DataSourceLogo
                  name={collection.database.databaseType as any}
                  type={'jdbc' as any}
                  margin={'0 6px 10px 6px'}
                  maxWidth={'50px'}
                  maxHeight={'30px'}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  alignContent: 'center',
                  marginTop: -8,
                }}
              >
                {collection.name}
              </div>
            </div>
          </MenuItem>
        </TextField>
        <ArrowRightAltRounded
          fontSize='large'
          style={{
            marginTop: 30,
            marginRight: 10,
            marginLeft: 10,
          }}
        />
        <TextField
          id="outlined-select-currency"
          select
          label="Target"
          value={targetDataSource}
          onChange={(e) => {
            setTargetDataSource(e.target.value);
          }}
          defaultValue={-1}
          variant="outlined"
          disabled={loadingDataSources}
          InputProps={{
            startAdornment: loadingDataSources ? (
              <CircularProgress size={20} style={{ marginRight: 10, }} />
            ) : '',
            classes: { input:  classes.customInput, },
          }}
          fullWidth
          style={{
            margin: '20px 0',
          }}
        >
          {
            dataSources
              .filter((ds) =>
                rosettaTranslationMatrix[ds.driverName].includes(collection.database.databaseType))
              .map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <div style={{
                    display: 'flex',
                    maxHeight: '30px',
                    minHeight: '30px',
                  }}>
                    <div
                      style={{
                        marginRight: '10px',
                        maxHeight: '30px',
                        minHeight: '30px',
                        display: 'grid',
                        alignContent: 'center',
                      }}
                    >
                      <DataSourceLogo
                        name={option.driverName as any}
                        type={option.type as any}
                        margin={'0 6px 10px 6px'}
                        maxWidth={'50px'}
                        maxHeight={'30px'}
                      />
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        alignContent: 'center',
                        marginTop: -8,
                      }}
                    >
                      {option.databaseName} ({option.name})
                    </div>
                  </div>
                </MenuItem>
              ))
          }
        </TextField>
        <TextField
          label="Operation level"
          select
          variant={'outlined'}
          id="demo-simple-select"
          value={operationLevel}
          onChange={handleChangeOperationLevel}
          InputProps={{ classes: { input:  classes.customInput, }, }}
          style={{
            minWidth: 150,
            marginTop: 20,
            marginLeft: 10,
          }}
        >
          <MenuItem value={'schema'}>Schema</MenuItem>
          <MenuItem value={'database'}>Database</MenuItem>
        </TextField>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 10,
          marginLeft: 'auto',
        }}
      >

        <Tooltip title={targetDataSource ? '' : 'Select a target datasource'}>
          <div>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
              <Button
                onClick={() => clickButton()}
                style={{
                  width: 190,
                }}
                size={'medium'}
                disabled={!targetDataSource}
              >
                {options[selectedIndex]}
              </Button>
              <Button
                color="primary"
                size={'medium'}
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
                disabled={!targetDataSource}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <Popper
              style={{ zIndex: 1, }}
              open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement, }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {options.map((option, index) => (
                          <MenuItem
                            style={{
                              minWidth: 230,
                            }}
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default DeploymentHeader;
