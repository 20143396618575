import React, {
  useEffect,
  useState,
} from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import { useSnackbar, } from 'notistack';
import { useParams, } from 'react-router-dom';

import { PlayCircleOutline, } from '@material-ui/icons';
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Box,
} from '@material-ui/core';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/ext-error_marker';
import 'ace-builds/src-noconflict/snippets/sql';
import langTools from 'ace-builds/src-noconflict/ext-language_tools';
import { helpers, } from '../../../utils';
import { Interfaces, } from '../../../config';
import { NoDataV2, } from '../../NoDataV2';
import LoadingComponent from '../../Loading';
import {
  dataServices,
  datasourceServices,
} from '../../../services';
import { AxiosResponse, } from 'axios';
import { DataTable, } from './DataTable';
import { SchemaTree, } from './SchemaTree';
import { useAuth, } from '../../../context';

const useStyles = makeStyles(() => ({
  schemaViewContainer: {
    marginRight: 30,
    maxHeight: 200,
    maxWidth: '100vh',
  },
  schemaViewGrid: {
    overflowY: 'auto',
    marginTop: 10,
    height: '81vh',
  },
}));

const setCompletions = (
  schema: Interfaces.Schema,
  selectedDataSource: string,
  defaultCompleter: Array<any>,
  setDefaultCompleter: any
) => {
  if (defaultCompleter.length === 0) {
    setDefaultCompleter([
      langTools.snippetCompleter,
      langTools.textCompleter,
      langTools.keyWordCompleter,
    ]);
  } else {
    langTools.setCompleters(defaultCompleter);
  }
  langTools.addCompleter({
    getCompletions: function (
      editor: any,
      session: any,
      pos: any,
      prefix: any,
      callback: any
    ) {
      const completions: any = [];
      for (let field of schema?.fields || []) {
        completions.push({
          value: field.name,
          meta: selectedDataSource,
        });
        (field?.fields?.map((_field) => _field.name) || []).forEach(function (w) {
          completions.push({
            value: w,
            meta: selectedDataSource,
          });
        });
      }
      callback(null, completions);
    },
  });
};

const Editor = () => {
  const classes = useStyles();
  const [query, setQuery,] = useState('');
  const [limit, setLimit,] = useState(10);
  const [defaultCompleter, setDefaultCompleter,] = useState<Array<any>>([]);
  const [dataSources, setDataSources,] = useState<Array<Interfaces.DataSourceType>>([]);
  const [schema, setSchema,] = useState<Interfaces.Schema | null>(null);
  const [selectedDataSource, setSelectedDataSource,] = useState<Interfaces.DataSourceType | null>(null);
  const [rows, setRows,] = useState<Array<any>>([]);

  const [loadingDataSources, setLoadingDataSources,] = useState(false);
  const [loadingSchema, setLoadingSchema,] = useState(false);
  const [loadingRows, setLoadingRows,] = useState(false);

  const { enqueueSnackbar, } = useSnackbar();
  const params = useParams<{ dataSourceName: string }>();
  const { isAuthorized, } = useAuth();
  const [readDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_READ'));
  const [executeDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_EXECUTE'));

  useEffect(() => {
    setLoadingDataSources(true);
    datasourceServices.fetchDataSources()
      .then((response: AxiosResponse<Array<Interfaces.DataSourceType>>) => {
        setDataSources(response.data);
        if (params.dataSourceName) {
          const idx = response.data.findIndex((ds) => ds.name === params.dataSourceName);
          if (idx !== -1) {
            const tmpDs = response.data[idx];
            if (tmpDs.type === 'JDBC') {
              setSelectedDataSource(tmpDs);
            }
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingDataSources(false);
      });
    return;
  }, []);

  useEffect(() => {
    if (selectedDataSource) {
      setLoadingSchema(true);
      dataServices.fetchActiveSchema(selectedDataSource.name)
        .then((response: AxiosResponse<Interfaces.Schema>) => {
          const tmpSchema = helpers.addMissingFieldsToSchema(response.data, '', false);
          setSchema(tmpSchema);
          setCompletions(
            tmpSchema,
            selectedDataSource.name,
            defaultCompleter,
            setDefaultCompleter
          );
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        })
        .finally(() => {
          setLoadingSchema(false);
        });
      return;
    }
  }, [selectedDataSource,]);

  if (loadingDataSources) {
    return <LoadingComponent />;
  }

  return (
    <Grid container>
      { readDSAccess && executeDSAccess && (
        <>
          <Grid item xs={3}>
            <Grid className={classes.schemaViewContainer}>
              <TextField
                variant="outlined"
                fullWidth
                select
                size="small"
                label="Select datasource"
                disabled={!!params.dataSourceName || loadingDataSources || loadingSchema}
                value={selectedDataSource?.name}
                onChange={(e) => {
                  const idx = dataSources.findIndex((ds) => ds.name === e.target.value);
                  if (idx !== -1) {
                    setSelectedDataSource(dataSources[idx]);
                  }
                }}
              >
                {
                  dataSources
                    .filter((dataSource) => dataSource.type === 'JDBC')
                    .map((dataSource) => (
                      <MenuItem key={dataSource.name} value={dataSource.name}>
                        {dataSource.name}
                      </MenuItem>
                    ))
                }
              </TextField>
              {
                loadingSchema && (
                  <LoadingComponent size={50} />
                )
              }
              {
                selectedDataSource && schema && !loadingSchema && (
                  <Grid className={classes.schemaViewGrid}>
                    <SchemaTree schema={schema} dataSource={selectedDataSource}/>
                  </Grid>
                )
              }
              {
                selectedDataSource === null && (
                  <NoDataV2 message={'No data source selected'} />
                )
              }
              {
                selectedDataSource && !schema && !loadingSchema && (
                  <NoDataV2 message={'No schema available'} />
                )
              }
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <AceEditor
              placeholder="Write a SQL statement"
              mode="sql"
              width="auto"
              fontSize={18}
              height="200px"
              value={query}
              onChange={(value: any) => {
                setQuery(value);
              }}
              enableBasicAutocompletion={true}
              enableLiveAutocompletion={true}
            />
            <Box
              style={{
                marginTop: '15px',
                marginBottom: '20px',
              }}
            >
              <Button
                variant="contained"
                endIcon={
                  loadingRows ? (
                    <CircularProgress size={16} />
                  ) : (
                    <PlayCircleOutline />
                  )
                }
                color="primary"
                style={{ height: 42, }}
                disabled={loadingRows || !selectedDataSource || loadingSchema || loadingDataSources}
                onClick={() => {
                  if (selectedDataSource) {
                    setLoadingRows(true);
                    datasourceServices.executeQuery(selectedDataSource.name, query, limit)
                      .then((response: AxiosResponse<Array<any>>) => {
                        setRows(response.data);
                      })
                      .catch((error) => {
                        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
                      })
                      .finally(() => {
                        setLoadingRows(false);
                      });
                  }
                }}
              >
              Run Query
              </Button>

              <TextField
                style={{
                  margin: 1,
                  minWidth: 100,
                  marginLeft: 30,
                }}
                id="demo-simple-select-autowidth"
                name="limit"
                select
                label="Limit"
                variant="outlined"
                size="small"
                value={limit}
                onChange={(event: any) => {
                  setLimit(event.target.value || 10);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={1000}>1000</MenuItem>
              </TextField>
            </Box>
            <Box style={{ marginTop: '10px', }}>
              {
                loadingRows && (
                  <LoadingComponent />
                )
              }
              {
                !loadingRows && rows.length > 0 && (
                  <DataTable rows={rows} />
                )
              }
              {
                !loadingRows && rows.length === 0 && (
                  <NoDataV2
                    message={'No data retrieved'}
                  />
                )
              }
            </Box>
          </Grid>
        </>
      )}

    </Grid>
  );
};

export default Editor;
