import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { useStyles, } from './styles';

const AlertsTableHead = ({
  onRequestSort,
  order,
  orderBy,
}: {
  // eslint-disable-next-line no-unused-vars
  onRequestSort: (val: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
}) => {
  const classes = useStyles();
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'inputRuleType',
      numeric: false,
      disablePadding: false,
      label: 'Input rule name',
    },
    {
      id: 'outputRuleType',
      numeric: false,
      disablePadding: false,
      label: 'Output rule name',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Typography variant="subtitle2" align="center">
            #
          </Typography>
        </TableCell>
        {
          headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={
                orderBy === headCell.id ? order : false
              }
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order : 'asc'
                }
                onClick={() => {
                  onRequestSort(headCell.id);
                }}
              >
                {headCell.label}
                {
                  orderBy === headCell.id && (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  )
                }
              </TableSortLabel>
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
};

export default AlertsTableHead;
