import React, {
  useEffect,
  useState,
} from 'react';
import Styles from './style';
import {
  RouteComponentProps,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  InfoOutlined,
  Save,
} from '@material-ui/icons';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faFileAlt, } from '@fortawesome/free-solid-svg-icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Interfaces, } from '../../../config';
import { useDSCalls, } from '../../../hooks/useDSCalls';
import LoadingComponent from '../../Loading';

type Params = {
  oldName: string;
};

interface Props extends RouteComponentProps {
  schemaTypes: Array<string>;
}

function File(props: Props) {
  const classes = Styles();
  const { oldName, } = useParams<Params>();
  const location = useLocation<any>();
  const inEditMode = location.pathname.includes('edit');
  const { schemaTypes, } = props;
  const [state, setState,] = useState<Interfaces.BaseDataSource>({
    id: '',
    name: '',
    type: 'FILE',
    uri: '',
    schemaType: schemaTypes[0],
  });
  const {
    dataSources,
    loading: loadingDS,
    error: errorDS,
    editDataSource,
    addDataSource,
    testDataSource,
    dataSourceToEdit: dataSourceToEditHook,
    loadingEdit,
    errorEdit,
    loadingTest,
    errorTest,
    testNewDataSource,
    testExistingDataSource,
    setDataSourceToEdit,
    setTestDataSource,
  } = useDSCalls();

  const dataSourceToEdit = dataSourceToEditHook as Interfaces.BaseDataSource | null;

  useEffect(() => {
    setTestDataSource(null);
    if (inEditMode) {
      if (dataSourceToEdit) {
        setState({
          ...state,
          id: dataSourceToEdit?.id,
          name: dataSourceToEdit?.name,
          type: dataSourceToEdit?.type,
          uri: dataSourceToEdit?.uri,
          schemaType: dataSourceToEdit?.schemaType,
        });
      } else {
        if (!dataSources) {return;}
        const dataSourceToChange = dataSources.find((item) => item.name === oldName) as Interfaces.BaseDataSource;
        setDataSourceToEdit(dataSourceToChange);
      }
    }
  }, [dataSourceToEdit, dataSources,]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const postSubmitAction = () => {props.history.push('/data-sources');};

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (inEditMode) {
      editDataSource( state, oldName, postSubmitAction);
    } else {
      addDataSource(state, postSubmitAction);
    }
  };

  const handleTestSuccess = () => {
    if (loadingTest) {
      return <CircularProgress
        color="inherit"
        style={{
          width: 11,
          height: 11,
        }}
      />;
    }
    if (errorTest) {
      return <FiberManualRecordIcon style={{ fill: 'red', }} />;
    }
    if (testDataSource && !errorTest) {
      return <FiberManualRecordIcon style={{ fill: 'green', }} />;
    } else if (!testDataSource && !errorDS) {
      return <FiberManualRecordIcon style={{ fill: 'grey', }} />;
    }
  };

  const onTestConnection = () => {
    inEditMode ? testExistingDataSource(state) : testNewDataSource(state);
  };

  if (loadingEdit) {
    return <LoadingComponent message={'Loading data source'}/>;
  }

  if (errorEdit) {
    return (
      <p>{errorEdit}</p>
    );
  }

  if (inEditMode && !dataSourceToEdit && !loadingDS) {
    return (
      <p>No file source was selected for edit mode</p>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center'>
        <FontAwesomeIcon
          icon={faFileAlt}
          color="grey"
          size="9x"
          className={classes.img}
        />
      </Grid>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={8}>
          <form name="addFileForm" onSubmit={handleSubmit}>
            <TextField
              className={classes.paddingBox}
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              value={state.name}
              fullWidth
              size="small"
              onChange={handleChange}
            />
            <TextField
              className={classes.paddingBox}
              id="schemaType"
              name="schemaType"
              // label="Type"
              variant="outlined"
              value={state.schemaType}
              fullWidth
              size="small"
              onChange={handleChange}
              select
              SelectProps={{
                native: true,
              }}
              defaultValue='CSV'
            >
              {schemaTypes.map((option: string) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <Grid container direction="row">
              <Grid item xs={9}>
                <TextField
                  className={classes.paddingBox}
                  style={{ paddingRight: 5, }}
                  id="uri"
                  name="uri"
                  label="Uri"
                  variant="outlined"
                  value={state.uri}
                  fullWidth
                  size="small"
                  onChange={handleChange}
                />
              </Grid>
              <Grid xs={1} alignItems='center'>
                <Tooltip title="Uri must start with 'file:///'" placement="top">
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  // className={classes.button}
                  startIcon={handleTestSuccess()}
                  fullWidth
                  onClick={() => {
                    onTestConnection();
                  }}
                >
                  Test
                </Button>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              startIcon={loadingDS ?
                <CircularProgress
                  color="inherit"
                  style={{
                    width: 16,
                    height: 11,
                  }} />
                :
                <Save />}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="default"
              size="large"
              className={classes.button}
              onClick={() => {
                props.history.goBack();
              }}
            >
              Cancel
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(File);
