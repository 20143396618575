import React, {
  useEffect,
  useState,
} from 'react';
import Style from './style';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import logo from '../CardView/logo';
import { withRouter, } from 'react-router';
import {
  RouteComponentProps,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  Constants,
  Interfaces,
} from '../../../config';
import {
  InfoOutlined,
  Loop,
} from '@material-ui/icons';
import { DirectoryTree, } from '../DirectoryTree';
import LoadingComponent from '../../Loading';
import { NoDataV2, } from '../../NoDataV2';
import { useDSCalls, } from '../../../hooks';

type Params = {
  oldName: string;
};

interface Props extends RouteComponentProps {
  schemaTypes: Array<string>;
}

function Ftp(props: Props) {
  const classes = Style();
  const location = useLocation<any>();
  const { oldName, } = useParams<Params>();
  const inEditMode = location.pathname.includes('edit');
  const [expandedElements, setExpandedElements,] = useState<Array<string>>([]);
  const [exploreState, setExploreState,] = useState(true);


  const getTypeFromName = (directory: Interfaces.Directory) => {
    if (directory.isDirectory) {
      return 'DIRECTORY';
    }
    const extension = directory.path.split('.').pop();
    if (extension === 'csv') {
      return 'CSV';
    }
    return 'AVRO';
  };

  const getTypeFromUri = (uri: string) => {
    const extension = uri.split('.').pop();
    if (extension === 'csv') {
      return 'CSV';
    } else if (extension === 'avro') {
      return 'AVRO';
    }
    return 'DIRECTORY';
  };

  const { schemaTypes, } = props;
  const [state, setState,] = useState<Interfaces.BaseDataSource>({
    id: '',
    name: '',
    schemaType: schemaTypes[0],
    uri: '',
    type: 'FTP',
  });
  const {
    dataSources,
    loading: loadingDS,
    editDataSource,
    addDataSource,
    dataSourceToEdit: dataSourceToEditHook,
    directories,
    loadingEdit,
    errorEdit,
    loadingDirectories,
    errorDirectories,
    fetchDirectories,
    setDataSourceToEdit,
    fetchNestedDirectory,
    setTestDataSource,
  } = useDSCalls();

  const dataSourceToEdit = dataSourceToEditHook as Interfaces.BaseDataSource | null;
  const endpoint = inEditMode && dataSourceToEdit
    ? `data-sources/${dataSourceToEdit.id}/ftp/dir`
    : Constants.api.endpoints.ftpDirectories;

  const fetchDirectoriesFTP = (uri: string | null) => {
    fetchDirectories({
      uri,
    }, endpoint);
  };

  useEffect(() => {
    setTestDataSource(null);
    if (inEditMode) {
      if (dataSourceToEdit) {
        setState({
          ...state,
          id: dataSourceToEdit?.id,
          name: dataSourceToEdit?.name,
          type: dataSourceToEdit?.type,
          schemaType: dataSourceToEdit?.schemaType,
          uri: dataSourceToEdit?.uri,
        });
        fetchDirectoriesFTP(dataSourceToEdit.uri === '' ? null : dataSourceToEdit.uri);
        setExploreState(false);
      } else {
        if (!dataSources) {
          return;
        }
        const dataSourceToChange = dataSources.find((item) => item.name === oldName) as Interfaces.BaseDataSource;
        setDataSourceToEdit(dataSourceToChange);
      }
    }
  }, [dataSourceToEdit, dataSources,]);

  const handleChange = (event: any) => {
    let property = event.target.name;
    let value = event.target.value;

    setState({
      ...state,
      [property]: value,
    });
  };

  const postSubmitAction = () => {props.history.push('/data-sources');};

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (inEditMode) {
      editDataSource( state, oldName, postSubmitAction);
    } else {
      addDataSource(state, postSubmitAction);
    }
  };

  if (loadingEdit) {
    return <LoadingComponent message={'Loading data source'}/>;
  }

  if (errorEdit) {
    return (
      <p>{errorEdit}</p>
    );
  }

  if (inEditMode && !dataSourceToEdit && !loadingDS) {
    return (
      <p>No data source was selected for edit mode</p>
    );
  }

  return (
    <form name="ftpDataSource" onSubmit={handleSubmit}>
      <div className={classes.root}>
        <Grid container justifyContent='center'>
          <img
            src={logo.images.ftp}
            alt="FTP"
            className={classes.img}
          />
        </Grid>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={6}>
            <TextField
              className={classes.paddingBox}
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              size="small"
              value={state.name}
              onChange={(event: any) => {
                handleChange(event);
              }}
            />
            <TextField
              className={classes.paddingBox}
              id="type"
              name="schemaType"
              variant="outlined"
              value={state.schemaType}
              fullWidth
              size="small"
              onChange={handleChange}
              select
              SelectProps={{
                native: true,
              }}
            >
              {schemaTypes.map((option: string) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="row">
              <Grid item xs={11}>
                <TextField
                  className={classes.paddingBox}
                  style={{ width: '109%', }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        <Tooltip title={'Reload the files'} placement="top">
                          <IconButton
                            disabled={exploreState}
                            color={'primary'}
                            edge={'end'}
                            onClick={() => {
                              fetchDirectoriesFTP(state.uri === '' ? null : state.uri);
                              setState({
                                ...state,
                                schemaType: getTypeFromUri(state.uri),
                              });
                            }}
                          >
                            <Loop />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  id="uri"
                  name="uri"
                  label="Uri"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={state.uri}
                  onChange={(event: any) => {
                    handleChange(event);
                  }}
                />
              </Grid>
              <Grid xs={1}>
                <Tooltip title="Uri must start with 'file:///'" placement="top">
                  <InfoOutlined
                    fontSize="small"
                    style={{
                      marginLeft: 45,
                      marginTop: 3,
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            {
              exploreState
                ? (
                  <Box className={classes.treeBoxEmpty}>
                    <Button
                      disabled={state.uri === '' || state.uri === null}
                      onClick={() => {
                        fetchDirectoriesFTP(state.uri === '' ? null : state.uri);
                        setExploreState(false);
                      }}
                      color='primary'
                      variant='contained'
                      className={classes.exploreButton}
                    >
                      Explore
                    </Button>
                    {
                      (state.uri === '' || state.uri === null)
                        && <Typography display={'block'} style={{ color: '#949494', }} variant={'caption'}>
                          Uri is required
                        </Typography>
                    }
                  </Box>
                )
                : (
                  <Box  className={classes.treeBox}>
                    {loadingDirectories && directories.length === 0 && <LoadingComponent size={60} message={'Loading bucket'} />}
                    {
                      errorDirectories &&
                      <div style={{ textAlign: 'center', }}>
                        <NoDataV2 message={errorDirectories} />
                        <Button
                          onClick={() => {
                            fetchDirectoriesFTP(state.uri === '' ? null : state.uri);
                            setExploreState(false);
                          }}
                          color='primary'
                          variant='contained'
                        >
                          Retry
                        </Button>
                      </div>
                    }
                    {directories.length > 0 && !errorDirectories && (
                      <DirectoryTree
                        directories={directories}
                        isLoading={loadingDirectories}
                        expandedElements={expandedElements}
                        onTreeItemClick={(directory: Interfaces.Directory) => {
                          setState({
                            ...state,
                            uri: directory.path,
                            schemaType: getTypeFromName(directory),
                          });
                          if (directory.isDirectory) {
                            if (!directory.directories) {
                              fetchNestedDirectory({
                                uri: directory.path,
                              }, endpoint, directory.path);
                            }
                            if (expandedElements.includes(directory.path)) {
                              setExpandedElements([...expandedElements,].filter((expanded: string) => expanded !== directory.path));
                            } else {
                              setExpandedElements([...expandedElements, directory.path,]);
                            }
                          }
                        }}/>
                    )}
                  </Box>
                )
            }
          </Grid>
        </Grid>
        <div style={{ width: '100%', }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            startIcon={loadingDS ?
              <CircularProgress
                color="inherit"
                style={{
                  width: 16,
                  height: 11,
                }} />
              :
              <SaveIcon />}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="default"
            size="large"
            className={classes.button}
            onClick={() => {
              props.history.goBack();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
}

export default withRouter(Ftp);
