import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
const useStyles = makeStyles(() =>
  createStyles({
    schemaViewerContainer: {
      maxHeight: '100vh',
    },
    schemaViewerSide: {
      paddingRight: 20,
      paddingLeft: 20,
      paddingTop: '1vh',
      paddingBottom: '1vh',
      maxHeight: '98vh',
      overflowX: 'hidden',
      minWidth: 440,
      width: '34vw',
      maxWidth: '34vw',
    },
    headerContainer: {
      backgroundColor: 'white',
      marginRight: 1,
      borderRadius: 1,
      border: '1px solid #8080803b',
      height: '10vh',
      minHeight: 100,
      maxHeight: '10vh',
      padding: 8,
      minWidth: 400,
      maxWidth: 600,
    },
    accordion: {
      marginTop: 10,
      minWidth: 400,
      maxWidth: 600,
    },
    changesAccordion: {
      marginTop: 18,
      marginBottom: 30,
      minWidth: 400,
      maxWidth: 600,
    },
    treeViewGrid: {
      backgroundColor: 'white',
      border: '1px solid #8080803b',
      marginTop: 15,
      padding: '10px',
      overflow: 'auto',
      height: '59vh',
      maxHeight: '60vh',
      width: '32vw',
      minWidth: 360,
      maxWidth: '32vw',
    },
    snapshotInfoText: {
      paddingLeft: '10px',
      marginRight: '20px',
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    targetVersionFormContainer: {
      backgroundColor: 'white',
      borderRadius: 4,
      border: '1px solid #8080803b',
      maxWidth: 600,
      minWidth: 400,
      maxHeight: '12vh',
      marginTop: 15,
      marginBottom: 15,
    },
    targetVersionForm: {
      margin: 10,
    },
    compareForm: {

    },
    drawer: {
      padding: 10,
    },
  })
);

export { useStyles, };
