import React, { useState, } from 'react';
import { EnhancedTableHead, } from './EnhancedTableHead';
import { EnhancedTableToolbar, } from './EnhancedTableToolbar';
import {
  Grid,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
  Table,
  TableContainer,
  TablePagination,
  TableBody,
  TableRow,
  TableCell,
  styled,
  Tooltip,
  Button,
  Switch,
} from '@material-ui/core';
import {
  Delete,
  Edit,
} from '@material-ui/icons';
import styles from '../Styles';
import { Interfaces, } from '../../../config';
import { useHistory, } from 'react-router-dom';
import { useAuth, } from '../../../context';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation';
import LoadingComponent from '../../Loading';
import { useSnackbar, } from 'notistack';
import { tagsServices, } from '../../../services';
import { helpers, } from '../../../utils';
import { NoDataComponent, } from '../../NoDataComponent';

export const emptyNewBusinessTag = {
  name: '',
  description: '',
  collection: '',
  matchingPatterns: [] as Array<string>,
  matchingPatternsStr: '',
  isActive: true,
  rules: [],
};

const TagsTable = () => {
  const { enqueueSnackbar, } = useSnackbar();
  const [tags, setTags,] = useState<Interfaces.BusinessTag[]>([]);
  const [filteredTags, setFilteredTags,] = useState<Interfaces.BusinessTag[]>([]);
  const [loadingTags, setLoadingTags,] = useState<boolean>(false);
  const [checkedTags, setCheckedTags,] = useState<Interfaces.BusinessTag[]>([]);
  const [selectedTag, setSelectedTag,] = useState<Interfaces.NewBusinessTag>({ ...emptyNewBusinessTag, });
  const [loadingSelectedTag, setLoadingSelectedTag,] = useState<boolean>(false);
  const [filters, setFilters,] = React.useState({
    checked: false,
    order: 'asc',
    orderBy: 'tagsName',
    page: 0,
    rowsPerPage: 5,
    deletePopUp: false,
  });
  const history = useHistory();
  const { isAuthorized, } = useAuth();
  const [adminAccess,] = React.useState(isAuthorized('ADAPTIVE_ADMIN'));
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_TAG_READ'));
  const [writeAccess,] = React.useState(isAuthorized('ADAPTIVE_TAG_WRITE'));

  const fetchTags = () => {
    setLoadingTags(true);
    tagsServices.fetchTags()
      .then((response) => {
        const responseTags = response.data.map((tag: Interfaces.BusinessTag) => {
          if (tag.rules === null) {
            tag.rules = [];
          }
          return tag;
        });
        const sortedTags = responseTags.sort((a: Interfaces.BusinessTag, b: Interfaces.BusinessTag) => {
          return a.name.localeCompare(b.name);
        });
        setTags(sortedTags);
        setFilteredTags(sortedTags);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingTags(false);
      });
  };

  const addTagFromCsv = (file: any) => {
    setLoadingSelectedTag(true);
    tagsServices.addTagFromCsv(file)
      .then(() => {
        enqueueSnackbar('Tags added successfully', { variant: 'success', });
        fetchTags();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingSelectedTag(false);
      });
  };

  const deleteTag =  (id: string) => {
    setLoadingSelectedTag(true);
    tagsServices.deleteTag(id)
      .then(() => {
        enqueueSnackbar('Tag deleted successfully', { variant: 'success', });
        setSelectedTag({ ...emptyNewBusinessTag, });
        fetchTags();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingSelectedTag(false);
      });
  };

  const activateBusinessTag = (id: string) => {
    setLoadingSelectedTag(true);
    tagsServices.activateBusinessTag(id)
      .then(() => {
        enqueueSnackbar('Tag activated successfully', { variant: 'success', });
        setSelectedTag({ ...emptyNewBusinessTag, });
        fetchTags();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingSelectedTag(false);
      });
  };

  const deActivateBusinessTag = (id: string) => {
    setLoadingSelectedTag(true);
    tagsServices.deActivateBusinessTag(id)
      .then(() => {
        enqueueSnackbar('Tag deactivated successfully', { variant: 'success', });
        setSelectedTag({ ...emptyNewBusinessTag, });
        fetchTags();
      })
      .catch((error: any) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingSelectedTag(false);
      });
  };

  const descendingComparator = (a: any, b: any, orderBy: any) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: any, orderBy: any) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (dataSources: any, comparator: any) => {
    const stabilizedThis = dataSources.map((el: any, index: number) => [
      el,
      index,
    ]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  };

  const handleSearch = (event: any) => {
    let filteredData = tags.filter((value: any) => value.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredTags(filteredData);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = filters.orderBy === property && filters.order === 'asc';
    setFilters({
      ...filters,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const handleChangePage = (event: any, newPage: number) => {
    setFilters({
      ...filters,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setFilters({
      ...filters,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const importFromCSV = (event: any) => {
    addTagFromCsv(event.target.files[0]);
  };

  const isSelected = (name: Interfaces.BusinessTag) => checkedTags.indexOf(name) !== -1;


  const deleteAction = () => {
    selectedTag?.id && deleteTag(selectedTag?.id);
  };

  const handleEdit = (tag: Interfaces.BusinessTag) => {
    setCheckedTags([tag,]);
    history.push(`/tag/edit/${tag.id}`);
  };

  const { order, orderBy, page, rowsPerPage, } = filters;
  const Input = styled('input')({
    display: 'none',
  });
  const classes = styles();

  React.useEffect(() => {
    fetchTags();
  }, []);

  if (loadingTags && tags.length === 0) {
    return <LoadingComponent message={'Loading tags'}/>;
  }

  if (!loadingTags && tags.length === 0) {
    const navigationData = {
      component: 'tags',
      header: 'No Tags',
      text1: 'You have not configured a tag yet',
      text2: 'Create a tag to get started',
      buttonText: 'Add Business Tag',
      route: '/tag/add',
    };

    const tagsData = {
      buttonText2: 'Import from CSV',
    };
    return <NoDataComponent data={navigationData} tags={tagsData}/>;
  }

  return (
    <div className={classes.rootTableTags}>
      <Grid
        className={classes.dividerTags}
        container
        direction="row"
        alignItems="center"
      >
        { writeAccess && readAccess && (
          <Grid item xs={8} style = {{ marginBottom:'20px', }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/tag/add');
              }}
              style = {{ marginRight:'10px', }}
            >
                add business tags
            </Button>
            <Input
              id="contained-button-file"
              type="file"
              accept=".csv"
              onChange={(event) => {
                importFromCSV(event);
              }}
              hidden
            />
            <label htmlFor="contained-button-file">
              <Button
                className={classes.buttonsMargin}
                variant="contained"
                color="primary"
                component="span"
              >
                  Upload from csv
              </Button>
            </label>
          </Grid>
        ) }
      </Grid>
      <Paper className={classes.paperTags}>
        <EnhancedTableToolbar
          numSelected={checkedTags.length}
          handleSearch={handleSearch}
          checkedTags={checkedTags}
        />
        <TableContainer>
          <Table
            className={classes.tableTags}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(
                filteredTags,
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((tag: any, index: number) => {
                  const isItemSelected = isSelected(tag);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={tag.name}
                      className={'tags-table-row'}
                      // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Typography variant="subtitle2" align="center">
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{tag.name}</TableCell>
                      <TableCell align="left">{tag.collection}</TableCell>
                      <TableCell align="left">
                        {tag.matchingPatterns}
                      </TableCell>
                      <TableCell align="left">{tag.rules?.map((rule: any) => rule.name).join(', ')}</TableCell>
                      <TableCell align="left">{tag.createdAt}</TableCell>
                      <TableCell align="left">{tag.updatedAt}</TableCell>
                      <TableCell align="left">
                        { readAccess && writeAccess && (
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                handleEdit(tag);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        )}
                        { adminAccess && (
                          <Tooltip title="Delete">
                            {loadingSelectedTag ? (
                              <IconButton
                                className={classes.iconButtonLoader}
                              >
                                <CircularProgress
                                  color="inherit"
                                  className={classes.loaderTags}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  setSelectedTag(tag);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </Tooltip>
                        )}
                        <Tooltip title={ tag.isActive ? 'Deactivate' : 'Activate'}>
                          <Switch
                            color='primary'
                            checked={tag.isActive}
                            onClick={() => {
                              if (tag.isActive) {
                                deActivateBusinessTag(tag.id);
                              } else {
                                activateBusinessTag(tag.id);
                              }
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25,]}
          component="div"
          count={filteredTags.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ModalConfirmation
          open={!!selectedTag.name}
          onClose={() => setSelectedTag(emptyNewBusinessTag)}
          title={'Are you sure you want to delete the tag?'}
          onBtnClick={() => deleteAction()}
          colorBtn={'secondary'}
          btnText={'Delete'}
          onCancelBtnClick={() => setSelectedTag(emptyNewBusinessTag)} />
      </Paper>
    </div>
  );
};

export { TagsTable, };
