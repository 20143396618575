import React, { useEffect, } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { makeStyles, } from '@material-ui/core/styles';
import {
  AboutUs,
  AddDataSources,
  Catalogs,
  Dashboard,
  DataSourceDashboard,
  EditDataSource,
  Editor,
  Loader,
  SchemaEvolution,
  Search,
  SearchDetails,
  SearchResults,
  TagsTable,
  TagsForm,
  DataTransfer,
  CatalogSelection,
  AdminPanel,
  GitConfigForm,
  Collections,
  AddCollection,
  Deployment,
  AddCatalog,
  ViewCatalog,
  AlertForm,
  Alerts,
  AlertReport,
  AlertHistories,
  Schedules,
  Sidebar,
  LoginWrapper,
  SuperAdmin,
  UserProfile,
} from './components';
import { Constants, } from './config';
import { ApiOffline, } from './components/ApiOffline';
import ReactGA from 'react-ga';
import { useAuth, } from './context';
import { axiosClient, } from './config/helpers';
import { AuthorizedHoc, } from './context/AuthContext';
import {
  SuccessfulRegistration,
  ResetPassword,
  ResendVerification,
} from './components/Register';
import TryNow from './components/TryNow';
import RosettaLogo from './assets/icons/cropped-databasee-removebg-preview.png';

ReactGA.initialize(Constants.gaTrackingId);

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'flex',
  },
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '20px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  main: {
    padding: '10px 30px 30px 30px',
    marginTop: '64px',
    height: 'calc(-64px + 100vh)',
    overflowY: 'auto',
  },
}));

function App() {
  const classes = useStyles();
  const {
    user,
    loading,
    logout,
    health,
    loadingHealth,
    deploymentType,
  } = useAuth();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (deploymentType === 'cloud') {
      let link: any = document.querySelector('link[rel~=\'icon\']');
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = RosettaLogo;
      document.title = 'RosettaDB';
    }
  }, [deploymentType,]);

  if (loadingHealth || loading || !health.status) {
    return <Loader/>;
  }

  if (health.status === Constants.api.states.stopped) {
    return (
      <div>
        <ApiOffline/>
      </div>
    );
  }

  if (health.isAuthenticationEnabled && !user) {
    return (
      <div>
        <div className={classes.root}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={LoginWrapper}/>
              <Route path={'/register-success/:id'} component={SuccessfulRegistration} />
              <Route path={'/password-reset/:token'} component={ResetPassword} />
              <Route path={'/resend-verification'} component={ResendVerification} />
              <Route path={'/try-now'} component={TryNow} />
              <Route component={() => <Redirect to={'/'} />}/>
            </Switch>
          </BrowserRouter>
        </div>
      </div>
    );
  }

  axiosClient().interceptors.response.use((response) => {
    return response;
  }, async function (error) {
    if (error?.response?.status === 401) {
      logout();
    }
    return Promise.reject(error);
  });

  return (
    <div>
      <div className={classes.root}>
        <BrowserRouter>
          <Sidebar />
          <main className={classes.content}>
            <div className={classes.main}>
              <Switch>
                <Route exact path="/" component={Dashboard}/>
                <Route path="/sql">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SQLEXPL_READ',]}>
                    <Editor/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/data-sources/:dataSourceName/sql">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SQLEXPL_READ',]}>
                    <Editor/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/data-sources">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DS_READ',]}>
                    <DataSourceDashboard />
                  </AuthorizedHoc>
                </Route>
                <Route path="/data-source/edit/:type/:oldName/:isJdbc?">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DS_WRITE', 'ADAPTIVE_DS_READ',]}>
                    <EditDataSource/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/data-source/:type">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DS_WRITE',]}>
                    <AddDataSources/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/catalog/edit/:catalogId/:tab?">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_DS_READ', 'ADAPTIVE_DS_EXECUTE', 'ADAPTIVE_CAT_WRITE',]}>
                    <AddCatalog/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/catalog/view/:catalogId/:external?">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_DS_READ',]}>
                    <ViewCatalog/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/catalog/edit/:catalogId">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_DS_READ', 'ADAPTIVE_DS_EXECUTE', 'ADAPTIVE_CAT_WRITE',]}>
                    <AddCatalog/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/catalog/add">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_DS_READ', 'ADAPTIVE_DS_EXECUTE', 'ADAPTIVE_CAT_WRITE',]}>
                    <AddCatalog/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/catalogs">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ',]}>
                    <Catalogs/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/tag/edit/:id">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_TAG_READ', 'ADAPTIVE_TAG_WRITE',]}>
                    <TagsForm/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/tag/add">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_TAG_READ', 'ADAPTIVE_TAG_WRITE',]}>
                    <TagsForm/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/tags">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_TAG_READ',]}>
                    <TagsTable/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/schedules">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SCHED_READ',]}>
                    <Schedules/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/search/details/:runId">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SEARCH_READ',]}>
                    <SearchDetails/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/search/:query/:page">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SEARCH_READ',]}>
                    <SearchResults/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/search">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_SEARCH_READ',]}>
                    <Search/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/about" component={AboutUs}/>
                <Route path="/admin">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_GIT_READ',]}>
                    <AdminPanel/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/data-transfer">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DT_READ',]}>
                    <DataTransfer/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/select-catalog">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_CAT_READ', 'ADAPTIVE_EV_READ',]}>
                    <CatalogSelection/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/schema-evolution/:catalogId?">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_EV_READ',]}>
                    <SchemaEvolution />
                  </AuthorizedHoc>
                </Route>
                <Route path="/git-config/add">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_GIT_READ', 'ADAPTIVE_GIT_WRITE',]}>
                    <GitConfigForm/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/git-config/edit/:gitConfigId">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_GIT_READ', 'ADAPTIVE_GIT_WRITE',]}>
                    <GitConfigForm/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/collections">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_COL_READ',]}>
                    <Collections/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/collection/add">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_COL_READ', 'ADAPTIVE_COL_WRITE', 'ADAPTIVE_DS_READ',]}>
                    <AddCollection/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/collection/edit/:id">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_COL_READ', 'ADAPTIVE_COL_WRITE', 'ADAPTIVE_DS_READ',]}>
                    <AddCollection/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/collection/:id/deployment">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_DEP_READ', 'ADAPTIVE_COL_READ', 'ADAPTIVE_DS_READ',]}>
                    <Deployment/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/alerts/add/:id?">
                  <AlertForm />
                </Route>
                <Route path={'/alerts/report/:id'}>
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ALERT_READ',]}>
                    <AlertReport/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/alerts">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ALERT_READ',]}>
                    <Alerts/>
                  </AuthorizedHoc>
                </Route>
                <Route path="/alert/histories">
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ALERT_READ',]}>
                    <AlertHistories/>
                  </AuthorizedHoc>
                </Route>
                <Route path={'/super/admin/:tab?'}>
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ADMIN',]}>
                    <SuperAdmin />
                  </AuthorizedHoc>
                </Route>
                <Route path={'/profile/:id'}>
                  <AuthorizedHoc authoritiesRequired={['ADAPTIVE_ADMIN',]}>
                    <UserProfile />
                  </AuthorizedHoc>
                </Route>
                <Route component={() => <Redirect to={'/'} />}/>
              </Switch>
            </div>
          </main>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
