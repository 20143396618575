import React from 'react';
import {
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import moment from 'moment/moment';
import { useStyles, } from './styles';
import { Interfaces, } from '../../../config';

const SnapshotInfo = ({ schema, } : {schema: Interfaces.Schema}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.headerContainer}>
      <Grid item xs={6}>
        {
          schema.version && (
            <Typography className={classes.snapshotInfoText} variant="subtitle1">
              Version: {schema.version}
            </Typography>
          )
        }
        {
          schema.totalRecords && (
            <Typography className={classes.snapshotInfoText} variant="subtitle1">
              TotalRecords: {schema.totalRecords}
            </Typography>
          )
        }
        <Typography className={classes.snapshotInfoText} variant="subtitle1">
          Total Fields: {`${schema.fields?.length}`}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.snapshotInfoText} variant="subtitle1">
          Run ID: <Link href={`/search/details/${schema.properties?.runId}`}>{schema.properties?.runId}</Link>
        </Typography>
        <Typography className={classes.snapshotInfoText} variant="subtitle1">
          Time: {schema.properties?.createdDate ? moment(schema.properties?.createdDate).format('DD MMM YYYY hh:mm:ss'):''}
        </Typography>
      </Grid>
    </Grid>
  );
};

export { SnapshotInfo, };
