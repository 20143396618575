import { makeStyles, } from '@material-ui/core/styles';

const useStyles = makeStyles({
  firstMessage: {
    color: '#f54242',
    fontSize: '20px',
  },
  secondMessage: {
    color: '#737373',
    fontSize: '20px',
  },
  marginBottom: {
    marginBottom: '10px',
    marginTop: '10px',
  },
  marginRight: {
    marginRight: '10px',
  },
  addRuleButton: {
    marginTop: '10px',
    marginLeft: '10px',
  },
  logoSize: {
    width: '150px',
    height: '200px',
    marginLeft: '35px',
  },
  rootToolbarTags: {
    paddingLeft: '10px',
    paddingRight: '5px',
  },
  titleTags: {
    flex: '1 1 45%',
  },
  searchPadding: {
    paddingRight: '10px',
  },
  rootTableTags: {
    width: '100%',
  },
  paperTags: {
    width: '100%',
    marginBottom: '10px',
  },
  tableTags: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttonsMargin: {
    marginLeft: '0px',
  },
  dividerTags: {
    marginBottom: '0px',
  },
  errorMargin: {
    marginLeft: '20px',
  },
  iconButtonLoader: {
    padding: '3px 3px',
    marginRight: '10px',
  },
  loaderTags: {
    width: 15,
    height: 15,
    fontWeight: 'bolder',
  },
  rulesForm: {
    marginBottom: 20,
  },
  rulesFormDeleteButton: {
    color: '#9a9a9a',
    marginRight: 20,
    '&:hover': {
      color: '#757474',
    },
  },
  selectedRules: {
    marginTop: 10,
    marginBottom: 10,
  },
  panel: {
    padding: 20,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    background: '#FFFFFF',
  },
});

export default useStyles;
