import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '10px',
    },
    inline: {
      margin: theme.spacing(1),
    },
    img: {
      width: '19%',
      marginBottom: '0.1rem',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paddingBox: {
      paddingBottom: '15px',
    },
    button: {
      margin: theme.spacing(1),
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
    treeBox: {
      border: '1px solid #c0c0c0',
      borderRadius: '5px',
      overflowY: 'auto',
      height: '77%',
    },
    buttonTest: {
      float: 'right',
    },
    treeBoxEmpty: {
      textAlign: 'center',
      border: '1px solid #c0c0c0',
      borderRadius: '5px',
      overflowY: 'auto',
      height: '77%',
      minHeight: '250px',
      paddingTop: 100,
      marginBottom: 15,
    },
    exploreButton: {
      height: 50,
      width: 120,
      fontSize: '1.2rem',
    },
  })
);

export default useStyles;
