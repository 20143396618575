import React, { useEffect, } from 'react';
import { useParams, } from 'react-router-dom';
import { makeStyles, } from '@material-ui/core/styles';
import CardView from '../CardView/CardView';
import Grid from '@material-ui/core/Grid';
import {
  Azure,
  File,
  Ftp,
  GoogleBucket,
  Jdbc,
  S3,
  DB2,
  Oracle,
  Snowflake,
  BigQuery,
  MSSQL,
  PostgreSQL,
  MySQL,
  Kinetica,
} from '../../../components';
import { HDFSSource, } from '../HDFSSource';
import { useDSCalls, } from '../../../hooks';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 1000,
    margin: '0 auto',
  },
});

function AddDataSources() {
  const classes = useStyles();
  const routeParameter: any = useParams();

  const { schemaTypes, fetchSchemaTypes, } = useDSCalls();

  useEffect(() => {
    fetchSchemaTypes();
  },[]);


  const itemsToRender = [
    {
      name: 'PostgreSQL',
      img: 'postgresql',
      route: '/data-source/postgresql',
    },
    {
      name: 'MySQL',
      img: 'mysql',
      route: '/data-source/mysql',
    },
    {
      name: 'JDBC',
      img: 'jdbc',
      route: '/data-source/jdbc',
    },
    {
      name: 'Snowflake',
      img: 'snowflake',
      route: '/data-source/snowflake',
    },
    {
      name: 'BigQuery',
      img: 'bigquery',
      route: '/data-source/bigquery',
    },
    {
      name: 'MSSQL',
      img: 'mssql',
      route: '/data-source/mssql',
    },
    {
      name: 'S3 Amazon',
      img: 's3',
      route: '/data-source/s3',
    },
    {
      name: 'Microsoft Azure',
      img: 'azure',
      route: '/data-source/azure',
    },
    {
      name: 'Google Bucket',
      img: 'googleBucket',
      route: '/data-source/google-bucket',
    },
    {
      name: 'File',
      img: 'file',
      route: '/data-source/file',
    },
    {
      name: 'FTP',
      img: 'ftp',
      route: '/data-source/ftp',
    },
    {
      name: 'HDFS',
      img: 'hdfs',
      route: '/data-source/hdfs',
    },
    {
      name: 'DB2',
      img: 'db2',
      route: '/data-source/db2',
    },
    {
      name: 'Oracle',
      img: 'oracle',
      route: '/data-source/oracle',
    },
    {
      name: 'Kinetica',
      img: 'kinetica',
      route: '/data-source/kinetica',
    },
  ];

  const result = itemsToRender.map((item, index) => {
    return (
      <Grid key={index} xs={6} md={4}>
        <CardView itemDetails={item}/>
      </Grid>
    );
  });

  const renderComponent = () => {
    switch (routeParameter.type) {
    case 'jdbc': {
      return <Jdbc/>;
    }
    case 'file': {
      return <File schemaTypes={schemaTypes}/>;
    }
    case 'ftp': {
      return <Ftp schemaTypes={schemaTypes}/>;
    }
    case 'google-bucket': {
      return <GoogleBucket schemaTypes={schemaTypes}/>;
    }
    case 's3': {
      return <S3 schemaTypes={schemaTypes}/>;
    }
    case 'azure': {
      return <Azure schemaTypes={schemaTypes}/>;
    }
    case 'hdfs': {
      return <HDFSSource schemaTypes={schemaTypes} />;
    }
    case 'db2': {
      return <DB2 />;
    }
    case 'oracle': {
      return <Oracle />;
    }
    case 'snowflake': {
      return <Snowflake/>;
    }
    case 'bigquery': {
      return <BigQuery/>;
    }
    case 'mssql': {
      return <MSSQL />;
    }
    case 'postgresql': {
      return <PostgreSQL />;
    }
    case 'mysql': {
      return <MySQL />;
    }
    case 'kinetica': {
      return <Kinetica />;
    }
    default: {
      return (
        <Grid container justifyContent='center' className={classes.root} direction="row">
          {result}
        </Grid>
      );
    }
    }
  };
  return <div className={classes.root}>{renderComponent()}</div>;
}

export default AddDataSources;
