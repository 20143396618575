import React, {
  useEffect,
  useState,
} from 'react';
import * as interfaces from '../../config/interfaces';
import {
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Tooltip,
  Popover,
  CardHeader,
  CardContent,
  CardActions,
  Card,
} from '@material-ui/core';
import { TagsComponent, } from '..';
import styles from './Styles';
import {
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { Rules, } from './Rules';
import {
  InfoOutlined,
  FileCopy,
} from '@material-ui/icons';
import AceEditor from 'react-ace';
import { Interfaces, } from '../../config';
import LoadingComponent from '../Loading';
import { useSnackbar, } from 'notistack';
import { emptyNewBusinessTag, } from './TagsTable/TagsTable';
import { tagsServices, } from '../../services';
import { helpers, } from '../../utils';

interface Params {
  id: string;
}

function TagTemplate(props: Interfaces.TagTemplate) {
  const [anchorEl, setAnchorEl,] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button size='small' style={{ height: 30,
        width: '100%', }} onClick={handleClick} aria-describedby={props.name}>
        <ListItem
        >
          <ListItemText primary={props.name} />
        </ListItem>
      </Button>
      <Popover
        id={props.name}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Card style={{ width: '650px', }}>
          <CardHeader
            style={{ borderBottom: '1px solid #80808045',
              boxShadow: '0 0 6px 0 #80808045',
              height: 30, }}
            title={props.name}
          />
          <CardContent style={{ padding: 0, }}>
            <AceEditor
              mode="javascript"
              showPrintMargin={false}
              width="665px"
              fontSize={14}
              value={props.data}
              readOnly
              height="150px"
            />
          </CardContent>
          <CardActions
            style={{ marginLeft: 'auto',
              borderTop: '1px solid #80808045',
              boxShadow: '0 0 6px 0 #80808045', }}>
            <Button
              style={{ marginLeft: '71%', }}
              size="small"
              color="default"
              variant="contained"
              startIcon={<FileCopy />}
              onClick={()=>{
                if (props.doInsert) {
                  props.doInsert(props.name, props.data);
                }
                handleClose();
              }}
            >
                Insert
            </Button>
            <Button
              style={{ marginLeft: 'auto', }}
              size="small"
              color="default"
              variant="contained"
              startIcon={<FileCopy />}
              onClick={() => {
                navigator.clipboard.writeText(props.data);
                handleClose();
                // setButtonText('Copied!');
                // setDisabled(true);
              }}
            >
                Copy
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
    // <div>{props.name}</div>
  );
}

function TagsForm() {
  const classes = styles();
  const { enqueueSnackbar, } = useSnackbar();
  const [templates, setTemplates,] = useState<Interfaces.TagTemplate | null>(null);
  const [loadingTemplates, setLoadingTemplates,] = useState<boolean>(false);
  const [staticRules, setStaticRules,] = useState<Interfaces.TagRule[]>([]);
  const [checkedTags, setCheckedTags,] = useState<Interfaces.BusinessTag[]>([]);
  const [selectedTag, setSelectedTag,] = useState<Interfaces.NewBusinessTag>({ ...emptyNewBusinessTag, });
  const [loadingSelectedTag, setLoadingSelectedTag,] = useState<boolean>(false);
  const [newCustomRule, setNewCustomRule,] = useState<interfaces.TagRule>({
    type: 'STATIC',
    name: '',
  });

  const params = useParams<Params>();
  const location = useLocation();
  const history = useHistory();
  const fetchTagRules = () => {
    tagsServices.fetchTagRules()
      .then((response) => {
        const responseStaticRules = response.data?.map((rule: string) => {
          return {
            name: rule,
            type: 'STATIC',
          };
        });
        setStaticRules(responseStaticRules);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  const fetchTagTemplates = () => {
    setLoadingTemplates(true);
    tagsServices.fetchTagTemplates()
      .then((response) => {
        setTemplates(response.data?.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };

  const fetchTagById = (id: string) => {
    setLoadingSelectedTag(true);
    tagsServices.fetchTagById(id)
      .then((response) => {
        const { data, } = response;
        if (data.matchingPatterns) {
          data.matchingPatterns = data.matchingPatterns.split(', ');
        } else {
          data.matchingPatterns = [];
        }
        setSelectedTag({ ...data, });
        setCheckedTags([data,]);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingSelectedTag(false);
      });
  };

  const addTag = (newTag: Interfaces.NewBusinessTag, callBack: () => void) => {
    const input = {
      ...newTag,
      matchingPatterns: newTag.matchingPatterns.join(','),
    };
    setLoadingSelectedTag(true);
    tagsServices.addTag(input)
      .then(() => {
        enqueueSnackbar('Tag added successfully', { variant: 'success', });
        setSelectedTag({ ...emptyNewBusinessTag, });
        callBack && callBack();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingSelectedTag(false);
      });
  };

  const editTag = (tag: Interfaces.NewBusinessTag, callBack?: () => void) => {
    const input = {
      ...tag,
      matchingPatterns: tag.matchingPatterns.join(','),
    };
    delete input.__typename;
    setLoadingSelectedTag(true);
    tagsServices.editTag(input)
      .then(() => {
        enqueueSnackbar('Tag edited successfully', { variant: 'success', });
        setSelectedTag({ ...emptyNewBusinessTag, });
        callBack && callBack();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingSelectedTag(false);
      });
  };

  const businessTagSetAttribute = (key: string, value: string | Array<Interfaces.TagRule>) => {
    const temBusinessTag = {
      ...selectedTag,
      [key]: value,
    };
    setSelectedTag(temBusinessTag);
  };

  const addMatchingPatterns = (tag: string) => {
    const updatedTags = [...selectedTag.matchingPatterns, tag,];
    const matchingPatternsStr = updatedTags.join();
    const tempTag = {
      ...selectedTag,
      matchingPatterns: updatedTags,
      matchingPatternsStr: matchingPatternsStr,
    };
    setSelectedTag(tempTag);

  };

  const deleteMatchingPatterns = (tag: string) => {
    const updatedData = [...selectedTag.matchingPatterns,].filter(
      (el: string) => el !== tag
    );
    const matchingPatternsStr = updatedData.join();
    const tempTag = {
      ...selectedTag,
      matchingPatterns: updatedData,
      matchingPatternsStr: matchingPatternsStr,
    };
    setSelectedTag(tempTag);
  };

  useEffect(() => {
    fetchTagRules();
    fetchTagTemplates();
    if (location.pathname.includes('/edit/')) {
      if (checkedTags[0]) {
        const dataToRenderEditForm: Interfaces.NewBusinessTag = {
          ...checkedTags[0],
          matchingPatterns:
            !checkedTags[0].matchingPatterns || checkedTags[0].matchingPatterns === ''
              ? []
              : checkedTags[0].matchingPatterns?.split(','),
          matchingPatternsStr: '',
        };
        setSelectedTag(dataToRenderEditForm);
      } else {
        fetchTagById(params.id);
      }
    }
  }, []);

  const onFormSubmit = (event: any) => {
    event.preventDefault();
    if (loadingSelectedTag) {return;}
    const newBusinessTag: interfaces.NewBusinessTag = {
      ...selectedTag,
    };
    delete newBusinessTag.matchingPatternsStr;
    if (location.pathname.includes('/edit/')) {
      editTag(newBusinessTag, () => history.push('/tags'));
    } else {
      addTag(newBusinessTag, () => history.push('/tags'));
    }
  };

  function changeHandler(event: any) {
    businessTagSetAttribute(event.target.name, event.target.value);
  }

  function handleRuleSelect(rule: interfaces.TagRule) {
    if (selectedTag.rules.findIndex((r: interfaces.TagRule) => r.name === rule.name) === -1) {
      const rules: Array<interfaces.TagRule> = [...selectedTag.rules, rule,];
      businessTagSetAttribute('rules', rules);
    } else {
      const rules: Array<interfaces.TagRule> = selectedTag.rules.filter((r: interfaces.TagRule) => r.name !== rule.name);
      businessTagSetAttribute('rules', rules);
    }
  }

  const handleCustomRuleInput = (e: any, code?: string) => {
    if (code === 'code') {
      setNewCustomRule({
        ...newCustomRule,
        code: e,
      });
    } else {
      const { name, value, } = e.target;
      setNewCustomRule({ ...newCustomRule,
        [name]: value, });
    }
  };

  const handleCustomRuleAdd = () => {
    if (selectedTag.rules.findIndex((r: interfaces.TagRule) => r.name === newCustomRule.name) === -1) {
      handleRuleSelect(newCustomRule);
      setNewCustomRule({ ...newCustomRule,
        name: '',
        code: '', });
    }
  };

  if (loadingSelectedTag) {
    return <LoadingComponent message={'Loading tag'}/>;
  }

  return (
    <form name="addBusinessTagForm" onSubmit={onFormSubmit}>
      <Grid item xs={12} style={{ marginBottom: 20, }}>
        <Typography variant='h5'>Auto Tagging Definition</Typography>
      </Grid>
      <Grid container direction='row'>
        <Grid item xs={4} className={classes.panel}>
          <Typography variant='h6'>Tag</Typography>
          <Grid container direction="row" alignItems='center'>
            <Grid item xs={11}>
              <TextField
                className={classes.marginBottom}
                id="outlined-basic"
                size="small"
                label="Tag Name"
                variant="outlined"
                fullWidth
                name="name"
                value={selectedTag.name}
                onChange={(event) => changeHandler(event)}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip style={{ marginLeft: 10, }} title="The name of the tag which fields will be tagged with." placement="top">
                <InfoOutlined fontSize="small"/>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <TextField
              className={classes.marginBottom}
              id="outlined-basic"
              size="small"
              label="Description"
              variant="outlined"
              fullWidth
              name="description"
              value={selectedTag.description}
              onChange={(event) => changeHandler(event)}
            />
          </Grid>
          <Grid container direction="row" alignItems='center'>
            <Grid item xs={11}>
              <TextField
                className={classes.marginBottom}
                id="outlined-basic"
                size="small"
                label="Collection Name"
                variant="outlined"
                fullWidth
                name="collection"
                value={selectedTag.collection}
                onChange={(event) => changeHandler(event)}
              />
            </Grid>
          </Grid>
          <Typography style={{ marginTop: 30,
            marginBottom: 20, }} variant='h6'>Automatically tag fields matching this pattern</Typography>
          <TagsComponent
            metadata={selectedTag}
            form="business-tag"
            onAddTag={(tag: string) => {
              addMatchingPatterns(tag);
            }}
            onDeleteTag={(tag: string) => {
              deleteMatchingPatterns(tag);
            }}
            title="Matching Patterns:"
            isHidden={true}
            secondaryTitle="Add a pattern"
            isHiddenTooltip={false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={7} className={classes.panel}>
          <Grid container direction='row' spacing={3}>
            <Grid item xs={9}>
              <Typography variant='h6'>Business Rule</Typography>
              <Rules
                staticRules={staticRules}
                newBusinessTag={selectedTag}
                handleRuleSelect={handleRuleSelect}
                handleCustomRuleChange={handleCustomRuleInput}
                newCustomRule={newCustomRule}
                addCustomRule={handleCustomRuleAdd}
              />
            </Grid>
            {loadingTemplates && <CircularProgress />}
            {templates && <Grid item xs={3} style={{ marginTop: '10px', }}>
              <Typography variant='h6' style={{ paddingLeft: '16px', }}>
                Templates
              </Typography>
              <List component="nav" aria-label="main mailbox folders" style={{ marginBottom: '30px', }}>
                {templates && Object.keys(templates).map((templateName)=>
                  <TagTemplate
                    key={templateName}
                    name={templateName}
                    data={templates[templateName]}
                    doInsert={(name:string, data:string)=>setNewCustomRule({ ...newCustomRule,
                      name: name,
                      code:data, })}
                  />
                )}
              </List>
            </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={2} style={{ marginTop: '30px',  }}>
          <Button
            type="submit"
            className={classes.marginRight}
            variant="contained"
            color="primary"
            disabled={selectedTag.matchingPatterns?.length === 0}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="default"
            href="#contained-buttons"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
        </Grid>

      </Grid>
    </form>
  );
}

export default TagsForm;
