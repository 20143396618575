import s3 from './s3.png';
import file from './file.png';
import jdbc from './jdbc.png';
import googleBucket from './googlecloud.png';
import azure from './azure.png';
import ftp from './ftp.png';
import hdfs from './hdfs.png';
import db2 from './db2.png';
import oracle from './oracle.png';
import snowflake from './snowflake.png';
import bigquery from './bigquery.png';
import mssql from './mssql.png';
import postgresql from './postgresql.png';
import mysql from './mysql.png';
import kinetica from './kinetica.png';

const obj = {
  images: {
    s3: s3,
    file: file,
    googleBucket: googleBucket,
    jdbc: jdbc,
    azure: azure,
    ftp: ftp,
    hdfs: hdfs,
    db2: db2,
    oracle: oracle,
    snowflake: snowflake,
    bigquery: bigquery,
    mssql: mssql,
    postgresql: postgresql,
    mysql: mysql,
    kinetica: kinetica,
  },
};

export default obj;
