import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    row: {
      width: '100%',
      display: 'flex',
      justifyContent:'space-between',
      alignItems: 'center',
    },
    treeViewGrid: {
      maxHeight: '100%',
      overflow: 'auto',
    },
    marginTop: {
      marginTop: '10px',
    },
    marginTopFetch: {
      marginTop: '17px',
    },
    minWH: {
      minWidth: '100%',
      minHeight: '130px',
      maxWidth: '80%',
      maxHeight: '230px',
    },
    button: {
      width: '25%',
      marginTop: '10px',
    },
    buttonPreview: {
      float: 'right',
    },
    paddingRight: {
      paddingRight: '15px',
    },
    detailsWrapper: {
      background: 'white',
      padding: '10px',
      borderRadius: 10,
    },
    sectionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: 10,
    },
    sectionTitle: {
      fontSize: '16px',
      color: '#4B4B4B',
      fontFamily: 'Inter-SemiBold !important',
      margin: '0 0 0 4px',
      '& hr': {
        marginLeft: 0,
        marginTop: 5,
        width: 200,
      },
    },
    detailsStyle: {
      color: '#4B4B4B',
      paddingLeft: '0px',
      fontWeight: 600,
    },
    description: {
      color: '#606B71',
      margin: '10px 0',
      fontFamily: 'Inter-Medium !important',
    },
    actor: {
      color: '#606B71',
      fontFamily: 'Inter-SemiBold !important',
      marginBottom: 0,
    },
    fieldsWrapper: {
      marginTop: 10,
      padding: '15px 0',

      '& p': {
        fontFamily: 'Inter-Medium !important',
        marginBottom: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& a': {
        marginTop: '1em',
        display: 'block',
        color: '#2F5AC9',
        fontFamily: 'Inter-Bold !important',
      },
    },
    fieldsWrapperMain: {
      marginTop: 10,
      padding: '20px 10px',
      background: 'white',
      color: '#606B71',
      borderRadius: '5px',

      '& p': {
        fontFamily: 'Inter-Medium !important',
        marginBottom: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& a': {
        marginTop: '1em',
        display: 'block',
        color: '#2F5AC9',
        fontFamily: 'Inter-Bold !important',
      },
    },
    inputLabel: {
      marginLeft: 14,
      marginTop: 4,
    },
    actionButtons: {
      width: 90,
      fontWeight: 'bold',
      marginRight: 10,
      marginTop: 3,
      height: 30,
    },
    linkStyle: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    descriptionInput: {
      fontSize: '10pt',
    },
    saveButton: {
      marginRight: 3,
      width: 200,
    },
    closeButton: {
      marginLeft: 3,
      width: 200,
    },
    tabsContainer: {
      marginBottom: 0,
      borderBottom: '1px solid #E7E7EEFF',
    },
    customButtonBaseRoot: {
      border: '1px solid #B8B9BBFF',
      borderRadius: '5px',
      padding: 2,
      minHeight: 30,
      minWidth: 140,
      marginRight: 10,
    },
    tabWrapper: {
      height: 30,
    },
    tabSelected: {
      border: '2px solid #3f51b5',
      color: '#3f51b5',
      fontWeight: 'bold',
    },
    runButton: {
      paddingRight: 20,
      paddingLeft: 20,
    },
    textSize: {
      fontSize: 'medium',
    },
    backMargin: {
      marginBottom: '10px',
    },
    containedBackground: {
      padding: 4,
      borderRadius: 6,
    },
    title: {
      fontWeight: 'bold',
    },
    dsLink: {
      textDecoration: 'none',
      color: '#000',
    },
    breadCrumbs: {
      display: 'flex',
      fontSize: 16,
      fontWeight: 600,
      width: '100%',
    },
    breadCrumbsLink: {
      color: '#8537A9',
      cursor: 'pointer',
      marginRight: 3,
      marginLeft: 3,
    },
    breadCrumbsDatabase: {
      color: '#1156ad',
      cursor: 'pointer',
      marginRight: 3,
      marginLeft: 3,
    },
    foreignKeyIcon: {
      marginBottom: -6,
      color: '#0b52a4',
      paddingRight: 4,
      fontSize: 22,
    },
  })
);
