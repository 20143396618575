import React, {
  useEffect,
  useState,
} from 'react';
import {
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Pagination, } from '@material-ui/lab';
import { makeStyles, } from '@material-ui/core/styles';
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Helpers, } from '../../../config';
import { parseInt, } from 'lodash';
import { SearchDataDto, } from '../../../config/interfaces';
import LoadingComponent from '../../Loading';
import { SearchInput, } from '../SearchInput/SearchInput';
import { useAuth, } from '../../../context';
import {
  faTable,
  faColumns,
  faProjectDiagram,
  faBorderNone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import mysql from '../../DataSources/CardView/logo/mysql.png';
import postgres from '../../DataSources/CardView/logo/postgresql.png';
import mssql from '../../DataSources/CardView/logo/mssql.png';
import kinetica from '../../DataSources/CardView/logo/kinetica.png';
import db2 from '../../DataSources/CardView/logo/db2.png';
import oracle from '../../DataSources/CardView/logo/oracle.png';
import snowflake from '../../DataSources/CardView/logo/snowflake.png';
import bigquery from '../../DataSources/CardView/logo/bigquery.png';
import googleBucket from '../../DataSources/CardView/logo/googlecloud.png';
import jdbc from '../../DataSources/CardView/logo/jdbc.png';
import s3 from '../../DataSources/CardView/logo/s3.png';
import ftp from '../../DataSources/CardView/logo/ftp.png';
import hdfs from '../../DataSources/CardView/logo/hdfs.png';
import azure from '../../DataSources/CardView/logo/azure.png';
import file from '../../DataSources/CardView/logo/file.png';
import { Query, } from '../../../config/helpers';
import { searchServices, } from '../../../services';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../../utils';


const useStyles = makeStyles((theme) => ({
  results: {
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    marginBottom: '3px',
    fontWeight: 'bolder',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 12,
  },
  sticky: {
    position: 'fixed',
    paddingRight: '200px',
  },
  table: {
    minWidth: 650,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#e8e8e8',
      cursor: 'pointer',
    },
  },
  header: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
  },
  textColor: {
    color: 'gray',
    paddingLeft: '40%',
  },
  borderBottom: {
    borderBottom: '2px solid grey',
    marginLeft: '15%',
    marginRight: '15%',
    height: '75px',
  },
  textAlign: {
    textAlign: 'center',
  },
  cardsContainer: {
    display: 'flex',
  },
  card: {
    width: '10vw',
    height: '10vw',
    marginRight: 10,
  },
  cardText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '8vw',
    whiteSpace: 'nowrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  icon: {
    maxWidth: '50px',
    maxHeight: '30px',
  },
  dsCell: {
    alignItems: 'center',
  },
}));

interface MatchParams {
  query: string;
  page: string;
}

const getDSJDBCIcon = (drivername: string | undefined) => {
  switch (drivername) {
  case 'mysql':
    return mysql;
  case 'postgres':
    return postgres;
  case 'sqlserver':
    return mssql;
  case 'kinetica':
    return kinetica;
  case 'db2':
    return db2;
  case 'snowflake':
    return snowflake;
  case 'oracle':
    return oracle;
  case 'bigquery':
    return bigquery;
  default:
    return jdbc;
  }
};

const getDSOtherIcon = (type: string | undefined) => {
  switch (type) {
  case 'GCS':
    return googleBucket;
  case 'S3':
    return s3;
  case 'FTP':
    return ftp;
  case 'HDFS':
    return hdfs;
  case 'AZURE_BLOB':
    return azure;
  case 'FILE':
    return file;
  default:
    return jdbc;
  }
};

const emptyStats = {
  dataSources: 0,
  records: 0,
  fields: 0,
  tags: 0,
  labels: 0,
};

const initialPagination = {
  pageElements: 20,
  totalPages: 0,
  page: 1,
};

function SearchResults() {
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const elementsPerPage = 20;
  const history = useHistory();
  const [results, setResults,] = React.useState<SearchDataDto[]>([]);
  const [loadingResults, setLoadingResults,] = React.useState<boolean>(false);
  const [filteredResults, setFilteredResults,] = React.useState<SearchDataDto[]>([]);
  const [stats, setStats,] = useState(emptyStats);
  const [pagination, setPagination,] = useState(initialPagination);

  const match = useRouteMatch<MatchParams>('/search/:query/:page');
  const { isAuthorized, } = useAuth();
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_SEARCH_READ'));

  const { query, page, } = match ? match.params : {
    query: '',
    page: '1',
  };

  function getStats(results: Array<SearchDataDto>) {
    let stats = {
      dataSources: new Set(results.map((x) => x.dataSourceName)).size,
      records: 0,
      fields: 0,
      tags: 0,
      labels: 0,
    };
    results.forEach((result) => {
      if (result.tags.length > 0) {
        stats = {
          ...stats,
          tags: stats.tags + 1,
        };
      }
      if (result.labels.length > 0) {
        stats = {
          ...stats,
          labels: stats.labels + 1,
        };
      }
      if (result.type.toString() === 'RECORD') {
        stats = {
          ...stats,
          records: stats.records + 1,
        };
      } else {
        stats = {
          ...stats,
          fields: stats.fields + 1,
        };
      }
    });
    return stats;
  }

  function filterResults(results: Array<SearchDataDto>, page: number) {
    const pageElements = pagination.pageElements;
    return results.slice((page - 1) * pageElements, page * pageElements);
  }

  function getTotalPages(tableResult: Array<any>) {
    const pageElements = pagination.pageElements;
    return Math.ceil(tableResult.length / pageElements);
  }

  const doSearch = (query: Query, page: number) => {
    setLoadingResults(true);
    searchServices.doSearch(query)
      .then((response) => {
        setResults(response.data.reverse());
        setStats(getStats(response.data));
        setFilteredResults(filterResults(response.data, page));
        setPagination({
          ...pagination,
          totalPages: getTotalPages(response.data),
          page: page,
        });
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingResults(false);
      });
  };

  const changePage = (page: number, data: Array<SearchDataDto>) => {
    setPagination({
      ...pagination,
      page: page,
    });
    setFilteredResults(data);
  };

  useEffect(() => {
    const decodedQuery = decodeURIComponent(query);
    const _page = parseInt(page);
    const _query = Helpers.mapSearchKeyword(decodedQuery);
    doSearch(_query, _page);
  }, [query, page,]);

  function handlePageChange(page: number) {
    history.replace(`/search/${query}/${page}`);
    const filteredData = results.slice(
      (page - 1) * elementsPerPage,
      page * elementsPerPage
    );
    changePage(page, filteredData);
  }

  function onTableRowClick(item: SearchDataDto, row: number) {
    const { runId, } = item;
    history.push({
      pathname: `/search/details/${runId}?selectedPath=${item.path}`,
      state: {
        item,
        row,
      },
    });
  }

  const { totalPages, } = pagination;

  const renderStats = () => {
    return (
      <div className={classes.header}>
        <div className={classes.cardsContainer} >
          <Card className={classes.card}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom className={classes.cardText}>
                Data Sources
              </Typography>
              <Typography
                className={classes.textAlign}
                variant="h3"
                component="h3"
              >
                {stats.dataSources}
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom className={classes.cardText}>
                Records
              </Typography>
              <Typography
                className={classes.textAlign}
                variant="h3"
                component="h3"
              >
                {stats.records}
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom className={classes.cardText}>
                Fields
              </Typography>
              <Typography
                className={classes.textAlign}
                variant="h3"
                component="h3"
              >
                {stats.fields}
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom className={classes.cardText}>
                Tags
              </Typography>
              <Typography
                className={classes.textAlign}
                variant="h3"
                component="h3"
              >
                {stats.tags}
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom className={classes.cardText}>
                Labels
              </Typography>
              <Typography
                className={classes.textAlign}
                variant="h3"
                component="h3"
              >
                {stats.labels}
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div style={{ width: '100%', }}>
          <SearchInput small/>
        </div>
      </div>
    );
  };

  const renderResult = () => {

    return filteredResults.map((item: SearchDataDto, index: number) => {
      const row = (index + 1) + (pagination.page - 1) * pagination.pageElements;

      return (
        <TableRow
          className={classes.tableRow}
          key={index}
          onClick={() => {
            item.dataSourceName !== undefined && onTableRowClick(item, row);
          }}
        >
          <TableCell>{row}</TableCell>
          <TableCell align="left">
            <div style={{ display: 'flex',
              alignItems: 'center',  }}>
              <FontAwesomeIcon
                style={{ marginRight: '5px',
                  color: 'gray', }}
                version={'regular'}
                icon={
                  item.sourceType === 'schema' ?
                    faProjectDiagram : item.sourceType === 'table' ? faTable : item.sourceType === 'view'
                      ? faBorderNone : faColumns
                }
              />
              <p>{item.name}</p>
            </div>
          </TableCell>
          <TableCell align="left">
            { item.parentName ? (
              <div style={{ display: 'flex',
                alignItems: 'center',  }}>
                <FontAwesomeIcon
                  style={{ marginRight: '5px',
                    color: 'gray', }}
                  version={'regular'}
                  icon={
                    item.parentSourceType === 'schema' ?
                      faProjectDiagram : item.parentSourceType === 'table' ? faTable : item.parentSourceType === 'view'
                        ? faBorderNone : faColumns
                  }
                />
                <p>{item.parentName}</p>
              </div>) :
              (
                <div></div>
              )}
          </TableCell>
          <TableCell align="left">
            <Grid container className={classes.dsCell}>
              <Grid item xs={3}>
                { item?.dataSourceType === 'JDBC' ?
                  <img
                    className={classes.icon}
                    alt={item?.dataSourceName?.toUpperCase()}
                    src={getDSJDBCIcon(item?.dataSourceDriverName)}
                  /> :
                  <img
                    className={classes.icon}
                    alt={item?.dataSourceName?.toUpperCase()}
                    src={getDSOtherIcon(item?.dataSourceType)}
                  />
                }
              </Grid>
              <Grid item xs={9}>
                <div>{item.dataSourceName}</div>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell>{item.catalogName}</TableCell>
          <TableCell align="left">{item.type}</TableCell>
          <TableCell align="left">{item.sourceType ? item.sourceType : item.type}</TableCell>
          <TableCell align="left">{
            item.tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                className={classes.chip}
                onClick={(e) => {
                  e.stopPropagation();
                  const encoded = encodeURIComponent(tag);
                  history.push(`/search/tag:${encoded}/1`);
                }}
                color="primary"
              />
            ))
          }</TableCell>
          <TableCell align="left">{item.labels.length}</TableCell>
        </TableRow>
      );
    });
  };

  if (loadingResults) {
    return <LoadingComponent/>;
  }

  return (
    <div>
      { readAccess && (
        <>
          {renderStats()}
          <Grid container className={classes.results}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.heading} align="left">
                        #
                      </TableCell>
                      <TableCell className={classes.heading} align="left">
                        Name
                      </TableCell>
                      <TableCell className={classes.heading} align="left">
                        Parent
                      </TableCell>
                      <TableCell className={classes.heading} align="left">
                        Data Source
                      </TableCell>
                      <TableCell className={classes.heading} align="left">
                        Catalog
                      </TableCell>
                      <TableCell className={classes.heading} align="left">
                        Column Type
                      </TableCell>
                      <TableCell className={classes.heading} align="left">
                        Source Type
                      </TableCell>
                      <TableCell className={classes.heading} align="left">
                        Tags
                      </TableCell>
                      <TableCell className={classes.heading} align="left">
                        Labels
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderResult()}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={3}/>
          </Grid>
          <br/>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            xs={12}
          >
            <Pagination
              count={totalPages}
              page={pagination.page}
              onChange={(event, page) => handlePageChange(page)}
              shape="rounded"
            />
          </Grid>
        </>
      )}
    </div>
  );
}

export { SearchResults, };
