import { Interfaces, } from '../../config';
import isEqual from 'lodash/isEqual';
import { v4, } from 'uuid';
import {
  GroupedSnapshotsType,
  SchemaChange,
} from './interfaces';
import moment from 'moment';

const fieldsToSanitize = [
  'run_id',
  'expanded',
  '_expanded',
  'selected',
  '_selected',
  'active',
  '_active',
  'createdDate',
  'createdDateAsString',
  'isChanged',
  'isNew',
  'isRemoved',
  '_uid',
  '_path',
  '__typename',
  'properties',
  'totalRecords',
  'version',
];

const _sanitizeFieldsForComparison = (schema: Interfaces.Schema) => {
  let tempSchema = JSON.parse(JSON.stringify(schema));
  fieldsToSanitize.forEach((field) => {
    tempSchema[field] = '';
  });
  tempSchema.fields = tempSchema.fields?.map((column: any) =>
    _sanitizeFieldsForComparison(column)
  );
  return tempSchema;
};

const _areFieldsEqual = (latestField: Interfaces.Schema, oldestField: Interfaces.Schema) => {
  latestField = _sanitizeFieldsForComparison(latestField);
  oldestField = _sanitizeFieldsForComparison(oldestField);
  return isEqual(latestField, oldestField);
};

const _deepHighLightNew = (schema: Interfaces.Schema, value = true) => {
  schema.isNew = value;
  schema.fields?.forEach((field: any) => {
    field.isNew = value;
    _deepHighLightNew(field, value);
  });
};

const _deepHighLightRemoved = (schema: Interfaces.Schema, value = true) => {
  schema.isRemoved = value;
  schema.fields?.forEach((field: Interfaces.Schema) => {
    field.isRemoved = value;
    _deepHighLightRemoved(field, value);
  });
};

const _resetHighlighting = (field: Interfaces.Schema, recursive = false) => {
  field.isChanged = false;
  _deepHighLightNew(field, false);
  _deepHighLightRemoved(field, false);

  if (recursive) {
    field.fields?.forEach((f) => _resetHighlighting(f, recursive));
  }
};

const _highlightSchema = (schema: Interfaces.Schema, changes: SchemaChange) => {
  schema.fields?.forEach((field) => {
    _resetHighlighting(field);
    if (changes.changedFields.find((f) => f.uidA === field._uid || f.uidB === field._uid)) {
      field.isChanged = true;
      return _highlightSchema(field, changes);
    }
    if (changes.addedFields.find((f) => f.uidA === field._uid)) {
      _deepHighLightNew(field);
    }
    if (changes.removedFields.find((f) => f.uidB === field._uid)) {
      _deepHighLightRemoved(field);
    }
  });
};

const calculateTotalRecords = (data: Interfaces.Schema) => {
  let totalRecords = 0;
  data.fields?.forEach((field) => {
    if (field.sourceType === 'schema') {
      field.fields?.forEach((field) => {
        if (field.sourceType === 'table' && field.properties.totalRecords) {
          totalRecords += field.properties.totalRecords;
        }
      });
    } else if (field.sourceType === 'table' && field.properties.totalRecords) {
      totalRecords += field.properties.totalRecords;
    }
  });
  return totalRecords;
};

const _addUid = (data: Interfaces.Schema) => {
  data['_uid'] = v4();
  data.fields?.forEach((field) => _addUid(field));
  return data;
};

const getErrorMessage = (error: any) => {
  return error?.response?.data?.message || error.message;
};

const highlightSchemas = (schemas: Array<Interfaces.Schema>, changes: SchemaChange) => {
  schemas.forEach((schema) => _highlightSchema(schema, changes));
};

const calculateChanges = (
  schemaA: Interfaces.Schema,
  schemaB: Interfaces.Schema,
  originalSchema: Interfaces.Schema,
  addedFields: Array<{ name: string, uidA?: string, uidB?: string }> = [],
  removedFields: Array<{ name: string, uidA?: string, uidB?: string }> = [],
  changedFields: Array<{ name: string, uidA?: string, uidB?: string }> = [],
  tagsNumberInA: number = 0,
  tagsNumberInB: number = 0,
  labelsNumberInA: number = 0,
  labelsNumberInB: number = 0
): SchemaChange => {
  originalSchema.fields?.forEach((originalField) => {
    const fieldExistsInA = schemaA.fields?.find((field: Interfaces.Schema) => originalField.name === field.name);
    const fieldExistsInB = schemaB.fields?.find((field: Interfaces.Schema) => originalField.name === field.name);

    if (fieldExistsInA && fieldExistsInB) {
      if (!_areFieldsEqual(fieldExistsInA, fieldExistsInB)) {
        changedFields.push({
          name: fieldExistsInA.name,
          uidA: fieldExistsInA._uid,
          uidB: fieldExistsInB._uid,
        });
      }

      tagsNumberInA += fieldExistsInA.tags.length;
      tagsNumberInB += fieldExistsInB.tags.length;
      labelsNumberInA += fieldExistsInA.labels.length;
      labelsNumberInB += fieldExistsInB.labels.length;

      return calculateChanges(
        fieldExistsInA,
        fieldExistsInB,
        originalField,
        addedFields,
        removedFields,
        changedFields,
        tagsNumberInA,
        tagsNumberInB,
        labelsNumberInA,
        labelsNumberInB
      );
    }

    if (fieldExistsInA && !fieldExistsInB) {
      tagsNumberInA += fieldExistsInA.tags.length;
      labelsNumberInA += fieldExistsInA.labels.length;
      addedFields.push({
        name: fieldExistsInA.name,
        uidA: fieldExistsInA._uid,
      });
    }

    if (!fieldExistsInA && fieldExistsInB) {
      removedFields.push({
        name: fieldExistsInB.name,
        uidB: fieldExistsInB._uid,
      });
    }
  });
  return {
    addedFields,
    removedFields,
    changedFields,
    tagsChange: tagsNumberInA - tagsNumberInB,
    labelsChange: labelsNumberInA - labelsNumberInB,
  };
};

const groupSnapshots = (snapshots: Array<Interfaces.Schema>): Array<GroupedSnapshotsType> => {
  const result: Array<GroupedSnapshotsType> = [];
  let tempList: Array<Interfaces.Schema> = [];

  snapshots.forEach((snapshot, index) => {
    if (index === 0) {
      snapshot.version = '1.0';
      tempList.push(snapshot);
    } else {
      if (_areFieldsEqual(snapshot, snapshots[index - 1])) {
        snapshot.version = `1.${result.length}`;
        tempList.push(snapshot);
      } else {
        snapshot.version = `1.${result.length + 1}`;
        result.push({
          snapshots: tempList,
          expanded: tempList.length < 3,
        });
        tempList = [];
        tempList.push(snapshot);
      }
    }
  });

  result.push({
    snapshots: tempList,
    expanded: false,
  });
  return result;
};

// eslint-disable-next-line no-unused-vars
const prepareSnapshots = (snapshots: Array<Interfaces.Schema>, callback: (schema: Interfaces.Schema) => void): Array<GroupedSnapshotsType> => {
  snapshots.forEach((snapshot, index) => {
    const sn = _addUid(snapshot);
    snapshots[index] = {
      ...sn,
      properties: {
        ...sn.properties,
        totalRecords: calculateTotalRecords(snapshot),
      },
    };
  });

  snapshots = snapshots.sort((a, b) => (
    moment(a.properties?.createdDate).diff(moment(b.properties?.createdDate))
  ));
  const initialSchema = snapshots[0];
  const groupedSnapshots = groupSnapshots(snapshots);
  groupedSnapshots.forEach((runRecord, index) => {
    if (index !== 0) {
      runRecord.changes = calculateChanges(
        runRecord.snapshots[0],
        groupedSnapshots[index - 1].snapshots[0],
        initialSchema
      );
    }
  });
  callback(initialSchema);
  return groupedSnapshots;
};

const filterByTime = (snapshots: Array<GroupedSnapshotsType>, type: 'week' | 'month' | 'year') => {
  const filteredData: Array<GroupedSnapshotsType> = [];
  snapshots.forEach((snapshot) => {
    const tempData = snapshot.snapshots.filter((sn) => moment(sn.properties?.createdDate).isSame(new Date(), type));
    if (tempData.length > 0) {
      filteredData.push({
        ...snapshot,
        snapshots: tempData,
      });
    }
  });
  return filteredData;
};

const findFirstChangedField = (fields: Array<Interfaces.Schema>): any => {
  if (!fields) {
    return;
  }
  for (const field of fields) {
    if (field.isRemoved || field.isNew) {
      return field;
    }
    const _field = findFirstChangedField(field.fields);
    if (_field) {
      return _field;
    }
  }
};

const findSelectedFields = (schema: Interfaces.Schema, path: Array<string>, selectedTreeNodes: Array<string> = []): any => {
  if (path === undefined) {
    return [schema._uid,];
  }
  if (schema.fields === undefined) {
    return selectedTreeNodes;
  }
  if (schema.name === path[0]) {
    path.splice(0, 1);
    const field: Interfaces.Schema = schema.fields.find((f: Interfaces.Schema) => f.name === path[0]) as Interfaces.Schema;
    if (field === undefined) {
      return selectedTreeNodes;
    }
    if (field._uid) {
      selectedTreeNodes.push(field._uid);
    }
    return findSelectedFields(field, path, selectedTreeNodes);
  }
};

const extractSelectedFields = (schemaA: Interfaces.Schema, schemaB: Interfaces.Schema) => {
  let firstChangedFieldFromA: Interfaces.Schema = findFirstChangedField(schemaA.fields);
  let firstChangedFieldFromB: Interfaces.Schema = findFirstChangedField(schemaB.fields);

  if (!firstChangedFieldFromA && firstChangedFieldFromB) {
    firstChangedFieldFromA = firstChangedFieldFromB;
  }
  if (!firstChangedFieldFromB && firstChangedFieldFromA) {
    firstChangedFieldFromB = firstChangedFieldFromA;
  }
  if (!firstChangedFieldFromA && !firstChangedFieldFromA) {
    firstChangedFieldFromA = schemaA;
    firstChangedFieldFromB = schemaB;
  }
  const pathListFromA: Array<string> = firstChangedFieldFromA.properties?.path?.split('.')?.filter((_path: string) => _path !== '') ?? [];
  const pathListFromB: Array<string> = firstChangedFieldFromB.properties?.path?.split('.')?.filter((_path: string) => _path !== '') ?? [];

  return {
    schemaA: findSelectedFields(schemaA, [schemaA.name, ...pathListFromA,]),
    schemaB: findSelectedFields(schemaB, [schemaB.name, ...pathListFromB,]),
  };
};

export {
  getErrorMessage,
  calculateChanges,
  prepareSnapshots,
  highlightSchemas,
  filterByTime,
  findSelectedFields,
  findFirstChangedField,
  extractSelectedFields,
};
