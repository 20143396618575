import React from 'react';
import { Interfaces, } from '../../../config';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-tomorrow';
import {
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FileCopy, } from '@material-ui/icons';
import { useSnackbar, } from 'notistack';
import LoadingComponent from '../../Loading';
import { collectionServices, } from '../../../services';
import { AxiosResponse, } from 'axios';
import { helpers, } from '../../../utils';
const DBTModels = ({
  collection,
  selectedTable,
}: {
  collection: Interfaces.Collection;
  selectedTable?: Interfaces.CollectionTable | null;
}) => {
  const { enqueueSnackbar, } = useSnackbar();
  const [loading, setLoading,] = React.useState(true);
  const [model, setModel,] = React.useState<string>();

  React.useEffect(() => {
    setLoading(true);
    if (selectedTable) {
      const tmpCollection = JSON.parse(JSON.stringify({
        ...collection,
        database: {
          ...collection.database,
          tables: [selectedTable,],
        },
      }));
      helpers.removeAdditionalFieldsFromCollection(tmpCollection);
      collectionServices.generateDbtModels(tmpCollection)
        .then((response: AxiosResponse<{ data: string }>) => {
          setModel(response.data.data);
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error));
        })
        .finally(() => setLoading(false));
      return;
    }
    setLoading(false);
  }, [selectedTable,]);

  if (loading) {
    return <LoadingComponent />;
  }
  if (!loading && !selectedTable) {
    return (
      <div style={{ textAlign: 'center',
        paddingTop: 'calc(-750px + 100vh)', }}>
        <Typography>
          Please select a table to view the DBT models
        </Typography>
      </div>
    );
  }
  return (
    <div style={{ position: 'relative', }}>
      <AceEditor
        mode={'sql'}
        height={'calc(-400px + 100vh)'}
        width="auto"
        theme={'tomorrow'}
        fontSize={16}
        value={model}
        readOnly
      />
      <div
        style={{
          position: 'absolute',
          top: -10,
          right: 25,
        }}
      >
        <Tooltip title={'Copy to clipboard'} placement={'top'}>
          <IconButton
            style={{
              marginTop: 6,
              fontWeight: 1000,
            }}
            onClick={() => {
              model && navigator.clipboard.writeText(model)
                .then(() => {
                  enqueueSnackbar('Copied to clipboard', { variant: 'info', });
                });
            }}
          >
            <FileCopy  />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export { DBTModels, };

