import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';

const DeleteDialog = ({
  open,
  title,
  handleClose,
  action,
}: {
  open: boolean;
  title: string;
  handleClose: () => void;
  action: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogActions style={{
        justifyContent: 'center',
        padding: '0 20px 20px 20px',
      }}>
        <Button
          onClick={() => action()}
          color="secondary"
          variant="contained"
        >
        Delete
        </Button>
        <Button
          onClick={() => handleClose()}
          color="default"
          variant="contained"
        >
            Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteDialog, };
