import React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  HighlightOff,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@material-ui/icons';
import { Interfaces, } from '../../../../config';
import ActionsColumn from './ActionsColumn';
import StatusChip from './StatusChip';
import moment from 'moment';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-terminal';


const DeploymentTableRow = ({
  deployment,
  expanded,
  setExpand,
  handleDelete,
  handleDownload,
} : {
  deployment: Interfaces.Deployment,
  expanded: boolean,
  setExpand: () => void,
  // eslint-disable-next-line no-unused-vars
  handleDelete: (deployment: Interfaces.Deployment) => void
  // eslint-disable-next-line no-unused-vars
  handleDownload: (deploymentId: string) => void;
}) => {
  const logsData = deployment.data.join('\n');

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={setExpand}>
            {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row">
          <StatusChip status={deployment.status}/>
        </TableCell>
        <TableCell align="left">
          { deployment.startTime &&
          <Tooltip title={moment(deployment.startTime).format('DD MM YYYY hh:mm')} placement="top">
            <div
              style={{
                width: 'fit-content',
              }}
            >
              {moment(deployment.startTime).fromNow()}
            </div>
          </Tooltip>
          }
        </TableCell>
        <TableCell align="left">{deployment.targetDataSource?.name}</TableCell>
        <TableCell
          align="left"
        >
          <div
            style={{
              width: 270,
              maxWidth: 270,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {
              deployment.tests && deployment.tests?.map((test, id) => (
                test.status === 'SUCCESS'
                  ? (<CheckCircleOutline style={{ color: 'green', }} key={id} />)
                  : (<HighlightOff color={'secondary'} key={id}/>)))
            }
          </div>
        </TableCell>
        <TableCell align="left">
          <div>
            <ActionsColumn
              handleDelete={() => handleDelete(deployment)}
              handleDownload={() => handleDownload(deployment.id)}
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0,
          paddingTop: 0, }} colSpan={7}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <AceEditor
                readOnly={true}
                theme="terminal"
                showGutter={false}
                width="auto"
                fontSize={18}
                height="200px"
                value={logsData}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default DeploymentTableRow;
