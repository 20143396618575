import React, {
  useEffect,
  useState,
} from 'react';
import { Grid, } from '@material-ui/core';
import { DeploymentTable, } from './DeploymentTable/index';
import { DeploymentHeader, } from './DeploymentHeader/index';
import {
  collectionServices,
  deploymentServices,
} from '../../services';
import { Interfaces, } from '../../config';
import { AxiosResponse, } from 'axios';
import { addIndexedUID, } from './helpers';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../utils';
import LoadingComponent from '../Loading';
import { SchemaTree, } from './SchemaTreeDeployment';
import { useParams, } from 'react-router-dom';
import { useInterval, } from 'usehooks-ts';
import { useAuth, } from '../../context';


function Deployment() {
  const { id, } = useParams<{ id: string }>();
  const [collection, setCollection,] = useState<Interfaces.Collection | null>(null);
  const { enqueueSnackbar, } = useSnackbar();
  const [loading, setLoading,] = useState(true);
  const [deployments, setDeployments,] = useState<Array<Interfaces.Deployment>>([]);
  const { isAuthorized, } = useAuth();
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_DEP_READ'));
  const [readDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_WRITE'));
  const [readCOLAccess,] = React.useState(isAuthorized('ADAPTIVE_COL_READ'));
  const [executeAccess,] = React.useState(isAuthorized('ADAPTIVE_DEP_EXECUTE'));

  const fetchCollectionById = (id: string) => {
    collectionServices.fetchCollectionById(id)
      .then((response: AxiosResponse<{ data: Interfaces.Collection }>) => {
        setCollection(addIndexedUID(response.data.data));
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => setLoading(false));
  };

  const fetchDeploymentsByCollectionId = (collectionId: string) => {
    deploymentServices.fetchDeploymentsByCollectionId(collectionId)
      .then((response: AxiosResponse<{ data: Array<Interfaces.Deployment> }>) => {
        const sorted = response.data.data.sort((a, b) => b.startTime - a.startTime);
        setDeployments(sorted);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => setLoading(false));
  };

  const createDeployment = (targetDatasourceId: string, collectionId: string, testIncluded: boolean, operationLevel: string) => {
    deploymentServices.createDeployment({
      targetDatasourceId: targetDatasourceId,
      collectionId: collectionId,
      testIncluded: testIncluded,
      operationLevel: operationLevel,
    })
      .then((response: AxiosResponse<{data: Interfaces.Deployment}>) => {
        setDeployments([response.data.data, ...deployments,]);
        enqueueSnackbar('Successfully deployed', { variant: 'success', });
      })
      .catch(() => enqueueSnackbar('deployment fail', { variant: 'error', }));
  };

  useEffect(() => {
    if (id) {
      fetchCollectionById(id);
      fetchDeploymentsByCollectionId(id);
    } else {
      setLoading(false);
    }
  }, []);

  useInterval(() => {
    fetchDeploymentsByCollectionId(id);
  }, 5000);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Grid container spacing={1} style={{ flexWrap:'nowrap', }}>
      <Grid item style={{ height: 'calc(100vh - 110px)', }}>
        {collection && readAccess && readDSAccess && readCOLAccess && <SchemaTree collection={collection} />}
      </Grid>
      <Grid item xs>
        {
          collection && executeAccess && (
            <DeploymentHeader
              collection={collection}
              handleAdd={(targetDatasourceId, operationLevel) => {
                if (collection?.id) {
                  createDeployment(targetDatasourceId, collection.id, false, operationLevel);
                }
              }}
              handleAddWithTests={(targetDatasourceId, operationLevel) => {
                if (collection?.id) {
                  createDeployment(targetDatasourceId, collection.id, true, operationLevel);
                }
              }}
            />
          ) }
        { executeAccess && (
          <DeploymentTable
            deployments={deployments}
            updateDeploymentList={(deployments) => setDeployments(deployments)}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default Deployment;
