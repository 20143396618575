import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import {
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Tooltip,
} from '@material-ui/core';
import { Interfaces, } from '../../config';
import { gitConfigServices, } from '../../services';
import PublishIcon from '@material-ui/icons/Publish';
import { useSnackbar, } from 'notistack';
import GitIcons from '../AdminPanel/GitConfigs/GitIcons';
import GitIcon from '../../assets/icons/git.png';
import { helpers, } from '../../utils';

interface Props {
  catalog: Interfaces.InputCatalogMetadata;
}

function GitConfigMenu(props: Props) {
  const { catalog, } = props;
  const [anchorEl, setAnchorEl,] = useState<null | HTMLElement>(null);
  const { enqueueSnackbar, } = useSnackbar();

  const [loading, setLoading,] = useState(false);
  const [hasError, setHasError,] = useState<string | null>(null);
  const [load, setLoad,] = useState<string | null>(null);

  const [data, setData,] = useState<Array<Interfaces.ExternalRepo> | null>(null);

  useEffect(() => {
    setLoading(true);
    gitConfigServices.fetchGitConfigs()
      .then((response) => setData(response.data.data))
      .catch((error) => setHasError(helpers.getErrorMessage(error)))
      .finally(() => setLoading(false));
  }, []);

  const versionSnapshot = (catalogId: string, externalRepoId: string) => {
    setLoading(true);
    gitConfigServices.versionSnapshot(catalogId, externalRepoId)
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Added successfully', { variant: 'success', });
      })
      .catch((error: any) => {
        setLoading(false);
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  if (hasError) {
    return <>Error occurred: {hasError.toString()}</>;
  }
  if (data === null) {
    return (
      <Tooltip title="Git Repositories">
        <IconButton
          style={{
            padding: '3px 3px',
            top: '-3px',
          }}
          aria-label="edit"
          disabled
        >
          <img height={26} width={26} src={GitIcon} alt={'git icon'}/>
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Fragment>
      <Tooltip title="Git Repositories">
        <div style={{ display: 'flex',
          alignItems: 'center', }}>
          <div
            style={{
              marginRight: 4,
              minWidth: 'fit-content',
              fontWeight: 'bold',
            }}
          >
            Git push
          </div>
          <IconButton
            style={{
              padding: '3px 3px',
            }}
            aria-label="edit"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <img height={26} width={26} src={GitIcon} alt={'git icon'}/>
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <List dense={true} style={{ maxHeight: 150,
          width: 200, }}>
          {data.map((repo: Interfaces.ExternalRepo ) => (
            <ListItem key={repo.id} >
              <Link href={`${repo.url}/${repo.type.toLowerCase() === 'BITBUCKET' ? 'branch' : 'tree'}/${repo.branch}`}
                target="_blank"
                rel='noreferrer'
                underline="none"
                color="inherit"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                {<GitIcons type={repo?.type} height={16} style={{ marginRight: 5, }}/>}
                {repo.name}
              </Link>
              <ListItemSecondaryAction>
                {loading && load === repo.id ? (
                  <Tooltip title="Loading">
                    <IconButton
                      style={{
                        padding: '3px 3px',
                      }}
                    >
                      <CircularProgress
                        color="inherit"
                        style={{
                          width: 17,
                          height: 17,
                          fontWeight: 'bolder',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Publish">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      size='small'
                      onClick={()=> {
                        if (repo.id != null && catalog.id != null) {
                          versionSnapshot(catalog.id, repo.id);
                          setLoad(repo.id);
                        }
                      }}
                    >
                      <PublishIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {data.length == 0 && (
            <ListItem>
              <ListItemText
                primary="No Git Configs"
              />
            </ListItem>
          )
          }
        </List>
      </Menu>
    </Fragment>
  );
}

export { GitConfigMenu, };
