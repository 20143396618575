import React, { useState, } from 'react';
import { useLocation, } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AccountCircle,
  Visibility,
  VisibilityOff,
  Lock,
} from '@material-ui/icons';
import { useAuth, } from '../../context';
import {
  SuccessfulRegistration,
  ForgottenPassword,
} from '../Register';
import { registerServices, } from '../../services';
import { helpers, } from '../../utils';
import { useSnackbar, } from 'notistack';

function Login() {
  const { login, error, loading, health, deploymentType,  } = useAuth();
  const [credentials, setCredentials,] = useState({
    username: '',
    password: '',
  });
  const { enqueueSnackbar, } = useSnackbar();
  const [hasResentEmail, setHasResentEmail,] = React.useState(false);
  const [showPassword, setShowPassword,] = React.useState<boolean>(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [forgotPassword, setForgotPassword,] = React.useState(false);
  const location = useLocation();


  if (location.pathname.includes('/register-success')) {
    return <SuccessfulRegistration />;
  }

  if (forgotPassword) {
    return <ForgottenPassword backToLogin={()=> setForgotPassword(false)}/>;
  }

  return (
    <Box>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await login(credentials.username, credentials.password);
        }}
      >
        <TextField
          fullWidth
          label="Email"
          placeholder="Email"
          margin="normal"
          variant="outlined"
          size="medium"
          name="username"
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle/>
              </InputAdornment>
            ),
          }}
          value={credentials.username}
          onChange={(event) => setCredentials({
            ...credentials,
            username: event.target.value,
          })}
        />
        <TextField
          fullWidth
          type={showPassword ? 'text' : 'password'}
          label="Password"
          placeholder="Password"
          margin="normal"
          variant="outlined"
          size="medium"
          name="password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock/>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={credentials.password}
          onChange={(event) => setCredentials({
            ...credentials,
            password: event.target.value,
          })}
        />
        {error != null && (
          <div>
            {
              error.includes('User account is not activated') ? (
                <Typography
                  style={{
                    textAlignLast: 'center',
                  }}
                >
                    User account is not activated. {' '}
                  <p
                    style={{
                      color: hasResentEmail ? 'grey' : 'blue',
                      textDecoration: hasResentEmail ? 'none' : 'underline',
                      display: 'inline-flex',
                      cursor: hasResentEmail ? 'not-allowed' : 'pointer',
                      pointerEvents: hasResentEmail ? 'none' : 'auto',
                    }}
                    onClick={() => {
                      setHasResentEmail(true);
                      registerServices.resendVerification({
                        email: credentials.username,
                      })
                        .then(() => {
                          enqueueSnackbar('Successfully sent verification email', { variant: 'success', });
                        })
                        .catch((error) => {
                          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
                          setHasResentEmail(false);
                        });
                    }}
                  >
                      Resend verification email!
                  </p>
                </Typography>
              ) : (
                <span style={{ color: '#c62828', }}>{error}</span>
              )
            }
          </div>
        )}
        <Button
          fullWidth
          color="primary"
          type="submit"
          variant="contained"
          style={{
            marginTop: '10px',
            marginBottom: '10px',
          }}
          disabled={loading}
        >
          Log in
        </Button>
      </form>
      {
        deploymentType === 'cloud' && (
          <>
            <Typography
              style={{
                textAlignLast: 'center',
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'blue',
                fontSize: '15px',
              }}
              onClick={() => setForgotPassword(true)}
            >
            Forgot your password?
            </Typography>
          </>
        )
      }
      <div style={{ textAlignLast: 'center',
        marginTop: 10, }}>
        <span style={{ fontWeight: 'bold',
          color: 'gray',  }}>
          {'v'+ health.version || ''}
        </span>
      </div>
    </Box>
  );
}

export default Login;
