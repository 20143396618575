import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';


function createData(
  name: string,
  details: string
) {
  return {
    name,
    details,
  };
}

const rows = [
  createData('Name', 'Jeff Bezos'),
  createData('Account ID', 'JB3438948829083298239884377347'),
  createData('DN', 'sn=Ellingwood,ou=people,dc=digitalocean,dc=com'),
  createData('Object Class', 'Person'),
  createData('SN', 'Ellingwood'),
  createData('CN', 'Justin Ellingwood'),
];

function Account() {
  return (
    <Grid container>
      <Grid xs={2}/>
      <Grid xs={8}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Info</TableCell>
                <TableCell align="left">Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.details}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default Account;
