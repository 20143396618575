import React, { useState, } from 'react';
import {
  Button,
  Grid,
} from '@material-ui/core';
import { useStyles, } from '../styles';
import { Interfaces, } from '../../../../config';
import {
  DataSourceType,
  helpers,
} from '../helpers';
import {
  AvroDialog,
  DDLDialog,
  ExtractDialog,
  SampleDataDialog,
} from '../ActionDialogs';

const ActionButtons = ({
  catalog,
  selectedDataSource,
  selectedNode,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  selectedDataSource: DataSourceType;
  selectedNode: Interfaces.Schema;
}) => {
  const classes = useStyles();
  const [avro, setAvro,] = useState(false);
  const [extract, setExtract,] = useState(false);
  const [ddl, setDdl,] = useState(false);
  const [sampleData, setSampleData,] = useState(false);

  const getSchema = React.useMemo(() => {
    let tmpSchema: Interfaces.Schema = JSON.parse(JSON.stringify(catalog.schema));
    helpers.setNodeSelectionTree(tmpSchema, tmpSchema, false);
    helpers.setNodeSelectionTree(tmpSchema, selectedNode, true);
    helpers.setReverseSelection(tmpSchema, selectedNode);
    tmpSchema = helpers.extractSelectedOnlyFields(tmpSchema);
    return tmpSchema;
  }, [selectedNode, catalog,]);

  return (
    <Grid container item xs={7} justifyContent="flex-end">
      <AvroDialog
        catalog={{
          ...catalog,
          includeAllSchemas: false,
          schema: getSchema,
        }}
        isOpen={avro}
        handleClose={() => setAvro(false)}
      />
      <ExtractDialog
        catalog={{
          ...catalog,
          includeAllSchemas: false,
          schema: getSchema,
        }}
        isOpen={extract}
        handleClose={() => setExtract(false)}
      />
      <DDLDialog
        catalog={{
          ...catalog,
          includeAllSchemas: false,
          schema: getSchema,
        }}
        isOpen={ddl}
        handleClose={() => setDdl(false)}
      />
      <SampleDataDialog
        dataSourceName={catalog.dataSourceName}
        isOpen={sampleData}
        handleClose={() => setSampleData(false)}
        selectedNode={selectedNode}
      />
      <Button
        className={classes.actionButtons}
        variant={'outlined'}
        color={'primary'}
        onClick={() => setExtract(true)}
        disabled={!catalog.name || !catalog.dataSourceName}
      >
        Extract
      </Button>
      <Button
        className={classes.actionButtons}
        variant={'outlined'}
        color={'primary'}
        onClick={() => setAvro(true)}
        disabled={!catalog.name || !catalog.dataSourceName}
      >
        Avro
      </Button>
      {
        selectedDataSource.type === 'JDBC' && (
          <Button
            className={classes.actionButtons}
            variant={'outlined'}
            color={'primary'}
            onClick={() => setDdl(true)}
            disabled={!catalog.name || !catalog.dataSourceId}
          >
            DDL
          </Button>
        )
      }
      {
        selectedDataSource.type === 'JDBC' &&
        (selectedNode.sourceType === 'table' || selectedNode.sourceType === 'view') && (
          <Button
            className={classes.actionButtons}
            variant={'outlined'}
            color={'primary'}
            onClick={() => setSampleData(true)}
          >
            Preview
          </Button>
        )
      }
    </Grid>
  );
};

export { ActionButtons, };
