import React from 'react';
import {
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import {
  AdaptiveScaleText,
  RosettaIcon,
} from './styles';
import rosetta from '../../assets/icons/rosetta.gif';

const RosettaIInfoSection = () => {
  return (
    <Grid item md={8} xs={12}>
      <Card>
        <CardContent>
          <Grid container item spacing={2} xs={12} alignItems='center'>
            <Grid item md={7} xs={12}>
              <AdaptiveScaleText align="center">
                RosettaDB simplifies the process of migrating your schema to any target database. With RosettaDB, you can effortlessly replicate your database across multiple targets, guaranteeing that your data is always accessible wherever you require it.<br />
              </AdaptiveScaleText>
            </Grid>
            <Grid item md={5} xs={12}>
              <RosettaIcon>
                <img src={rosetta} alt='rosetta_logo' />
              </RosettaIcon>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export { RosettaIInfoSection, };
