import {
  makeStyles,
  styled,
} from '@material-ui/core/styles';
import {
  Button,
  CardContent,
  Typography,
  Box,
} from '@material-ui/core';

export const StyledButton = styled(Button)({
  width: '100%',
  padding: '0px',
  backgroundColor: '#3f52b5',
  transition: 'all 100ms ease !important',
  '&:hover': {
    backgroundColor: '#273787 !important',
    borderRadius: '4px',
    boxShadow: 'rgb(100 100 111 / 20%) 0px 1px 29px 0px',
  },
  '& .MuiButton-label': {
    justifyContent: 'start',
    '& p': {
      paddingLeft: '15px',
      color: '#FFFFFF',
      fontWeight: '600',
      textTransform: 'capitalize',
      fontSize: '18px',
    },
  },
  '& svg': {
    background: '#273787',
    padding: '15px 10px',
    width: '2.5em',
    height: '2.7em',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    '& path': {
      color: '#FFFFFF',
    },
  },
});

export const StyledCardContent = styled(CardContent)({
  padding: '30px 0 0 0',
  textAlign: 'center',
});

export const CardTitle = styled(Typography)({
  textTransform: 'uppercase',
  marginTop: '20px',
  fontFamily: 'Inter-SemiBold',
});

export const AdaptiveScaleText = styled(Typography)({
  fontSize: '20px',
  color: '#757575',
  '& span': {
    fontFamily: 'Inter-ExtraBold',
  },
  fontFamily: 'Inter-Light',
});

export const AdaptiveScaleDriver = styled(Box)({
  textAlign: 'center',
  '& img': {
    width: '80px',
  },
  '& h6': {
    marginTop: '5px',
    marginBottom: '12px',
    fontFamily: 'Inter-SemiBold',
  },
  '& button': {
    padding: '14px 20px',
  },
});

export const RosettaIcon = styled(Box)({
  textAlign: 'center',
  '& img': {
    width: '200px',
  },
  '& h6': {
    marginTop: '5px',
    marginBottom: '12px',
    fontFamily: 'Inter-SemiBold',
  },
  '& button': {
    padding: '14px 20px',
  },
});


export const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    padding: '15px',
  },
  cardbutton: {
    background: '#3f52b5',
    color: 'white',
  },
  actions: {
    display: 'flex',
    paddingTop: '50px',
  },
  dashboard: {
    paddingTop: '70px',
  },
  root: {
    flexGrow: 1,
    paddingBottom: '2.5%',
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 14,
  },
  text: {
    textAlign: 'center',
  },
  gbText: {
    bottom: 0,
    marginTop: '65px',
  },
  pos: {
    marginBottom: 12,
  },
  centerDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
  },
}));
