import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      marginBottom: 10,
    },
    headerName: {
      marginTop: 4,
    },
    inputContainer: {
    },
    formControl: {
      width: 350,
    },
    inputLabel: {
      marginLeft: 14,
      marginBottom: 6,
      marginTop: -7,
    },
  })
);

export { useStyles, };
