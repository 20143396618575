import React from 'react';
import {
  Box,
  Grid,
} from '@material-ui/core';
import { useStyles, } from './styles';
import { Interfaces, } from '../../../config';
import {
  faBorderNone,
  faColumns,
  faDatabase,
  faProjectDiagram,
  faTable,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';

const NodeMetadataDetails = ({ selectedNode, }: { selectedNode: Interfaces.Schema; }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Box className={classes.detailsWrapper}>
        <Grid container>
          <Grid item xs={5}>
            <div className={classes.detailsStyle}>
              <div>
                <FontAwesomeIcon
                  style={{
                    color: '#0b52a4',
                    marginRight: 6,
                  }}
                  icon={
                    selectedNode.sourceType === 'database'?
                      faDatabase : selectedNode.sourceType === 'schema' ?
                        faProjectDiagram : selectedNode.sourceType === 'table' ?
                          faTable : selectedNode.sourceType === 'view' ?
                            faBorderNone : faColumns
                  }
                />
                <b style={{ fontSize: 18, }}>
                  {selectedNode.name}
                </b>
                <span>
                &nbsp;&nbsp;{selectedNode.sourceType || selectedNode.type}
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.fieldsWrapper}>
          {
            selectedNode.fields && <Grid item xs={2}>
              Items
              <p>{selectedNode.fields?.length}</p>
            </Grid>
          }
          <Grid item xs={2}>
            Source Type
            <p>{selectedNode.sourceType || 'N\\A'}</p>
          </Grid>
          {
            selectedNode.properties?.autoIncrement !== undefined
            && !selectedNode.fields
            && (
              <Grid item xs={2}>
                Auto Increment
                <p>{selectedNode.properties?.autoIncrement ? 'YES' : 'NO'}</p>
              </Grid>
            )
          }
        </Grid>
      </Box>
    </Grid>
  );
};

export { NodeMetadataDetails, };
