import React, {
  useState,
  useEffect,
} from 'react';
import { Link, } from 'react-router-dom';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { Interfaces, } from '../../../../config';
import { useStyles, } from '../styles';
import { catalogsServices, } from '../../../../services';

const CatalogInfo = ({ catalog, }: {catalog: Interfaces.InputCatalogMetadata}) => {
  const classes = useStyles();
  const [dSLink, setDSLink,] = useState('');

  useEffect(() => {
    if (catalog.id) {
      catalogsServices.fetchCatalogWithDataSources(catalog.id).then(({ data, }) => {
        if (data.data?.dataSourceInfo) {
          const { data: { dataSourceInfo, }, } = data;
          setDSLink(`/data-source/edit/${dataSourceInfo.driverName}/${dataSourceInfo.name}/${dataSourceInfo?.type?.toLowerCase() === 'jdbc' ? 'jdbc' : 'nojdbc'}`);
        }
      });
    }
  }, []);
  
  return (
    <Grid
      container
      style={{
        background: 'white',
        padding: '10px 10px 0 10px',
        minHeight: '15%',
      }}
    >
      <Typography
        variant='h5'
        className={classes.title}
      >
        {catalog.name}
      </Typography>
      <div className={classes.row}>
        <p className={classes.textSize}>
        Datasource: <Link to={dSLink} className={classes.dsLink}><strong className={classes.containedBackground}>{catalog.dataSourceName}</strong></Link>
        </p>
        <p className={classes.textSize}>
        Is external: <strong className={classes.containedBackground}>
            {catalog.dataSourceDescription === 'external' ? 'YES' : 'NO'}
          </strong>
        </p>
      </div>
    </Grid>
  );
};

export { CatalogInfo, };
