import React, {
  useEffect,
  useState,
} from 'react';
import { Interfaces, } from '../../../../config';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { catalogsServices, } from '../../../../services';
import { extractTablesFromSchema, } from '../helpers';
import { AxiosResponse, } from 'axios';


const RecordLimit = ({
  alert,
  setAlert,
  handleValidation,
}: {
  alert: Interfaces.Alert;
  // eslint-disable-next-line no-unused-vars
  setAlert: (alert: Interfaces.Alert) => void;
  // eslint-disable-next-line no-unused-vars
  handleValidation: (isValid: boolean) => void;
}) => {
  const [tables, setTables,] = useState<Array<Interfaces.Schema>>([]);
  const operators = ['=', '<', '>', '<=', '=>',];

  useEffect(() => {
    if (alert.relationId !== '') {
      catalogsServices.fetchCatalogById(alert.relationId)
        .then((response: AxiosResponse<Interfaces.InputCatalogMetadata>) => {
          const _tables: Array<Interfaces.Schema> = [];
          extractTablesFromSchema(response.data.schema, _tables);
          setTables(_tables);
        });
    }
  }, [alert.relationId,]);

  useEffect(() => {
    const path = alert.inputRule.rule['path'];
    const operator = alert.inputRule.rule['operator'];
    const valueToCheck = alert.inputRule.rule['valueToCheck'];

    const isValid = !!(
      path && path !== ''
      && operator && operator !== ''
      && valueToCheck && valueToCheck !== ''
    );
    handleValidation(isValid);
  }, [alert.inputRule,]);

  return (
    <Box padding="12px 0">
      <FormControl
        style={{
          marginTop: '10px',
        }}
        fullWidth
        size="small"
      >
        <InputLabel
          style={{
            marginLeft: '14px',
            marginTop: '-6px',
          }}
        >
          Table
        </InputLabel>
        <Select
          id='table-select'
          variant={'outlined'}
          placeholder={'Table'}
          disabled={tables.length === 0}
          fullWidth
          label={'Table'}
          value={alert.inputRule.rule['path']}
          onChange={(event) => {
            setAlert({
              ...alert,
              inputRule: {
                ...alert.inputRule,
                rule: {
                  ...alert.inputRule.rule,
                  ['path']: event.target.value,
                },
              },
            });
          }}
        >
          {
            tables.map((schema: Interfaces.Schema, index: number) => (
              <MenuItem
                value={schema?.properties?.path}
                key={`${schema.name}-${index}`}
              >
                {schema.name}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl
        style={{
          marginTop: '10px',
        }}
        fullWidth
        size="small"
      >
        <InputLabel
          style={{
            marginLeft: '14px',
            marginTop: '-6px',
          }}
        >
          Operator
        </InputLabel>
        <Select
          id='schema-select'
          variant={'outlined'}
          placeholder={'Operator'}
          fullWidth
          label={'Opeartor'}
          value={alert.inputRule.rule['operator']}
          onChange={(event) => {
            setAlert({
              ...alert,
              inputRule: {
                ...alert.inputRule,
                rule: {
                  ...alert.inputRule.rule,
                  ['operator']: event.target.value,
                },
              },
            });
          }}
        >
          {
            operators.map((operator, index) => (
              <MenuItem
                value={operator}
                key={index}
              >
                {operator}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>

      <FormControl
        style={{
          marginTop: '10px',
        }}
        fullWidth
        size="small"
      >
        <TextField
          type={'number'}
          value={alert.inputRule.rule['valueToCheck']}
          fullWidth
          variant={'outlined'}
          placeholder={'Value to check'}
          onChange={(event) => {
            setAlert({
              ...alert,
              inputRule: {
                ...alert.inputRule,
                rule: {
                  ...alert.inputRule.rule,
                  ['valueToCheck']: event.target.value,
                },
              },
            });
          }}
        />
      </FormControl>
    </Box>
  );
};

export { RecordLimit, };
