import React from 'react';
import { Logo, } from '../../static';
import { Grid, } from '@material-ui/core';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => (
  {
    animation: {
      animation: `$myEffect 2000ms infinite ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
        transform: 'translateY(-100%)',
      },
      '70%': {
        opacity: 0.7,
        transform: 'translateY(30%)',
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(10%)',
      },
    },
  }
));

function Loader() {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" style={{ marginTop: '20%', }}>
      <div className={classes.animation}>
        <img alt="AdaptiveScaleLogo" width="100px" height="100px" src={Logo}/>
      </div>
    </Grid>
  );
}

export { Loader, };
