import React, { useState, } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Interfaces, } from '../../../config';
import { useStyles, } from './styles';
import { SchemaView, } from '../../SchemaView';
import { SnapshotInfo, } from './SnapshotInfo';
import {
  GroupedSnapshotsType,
  SchemaChange,
} from '../interfaces';
import {
  extractSelectedFields,
  highlightSchemas,
} from '../helpers';
import { ExpandMore, } from '@material-ui/icons';

const SchemaViewer = ({
  open,
  schema,
  schemaToCompare,
  snapshots,
  handleClose,
  handleCompare,
}:{
  open: boolean;
  schema: Interfaces.Schema;
  schemaToCompare?: {snapshotA: Interfaces.Schema, snapshotB: Interfaces.Schema, changes: SchemaChange};
  snapshots: Array<GroupedSnapshotsType>;
  handleClose: () => void;
  // eslint-disable-next-line no-unused-vars
  handleCompare: (index: number) => void;
}) => {
  const classes = useStyles();
  schemaToCompare && highlightSchemas([schemaToCompare.snapshotA, schemaToCompare.snapshotB,], schemaToCompare.changes);

  const [expandSelected, setExpandSelected,] = useState(true);
  const [expandCompare, setExpandCompare,] = useState(true);
  const [expandChanges, setExpandChanges,] = useState(false);
  const selectedFields = schemaToCompare ?
    extractSelectedFields(schemaToCompare.snapshotA, schemaToCompare.snapshotB) :
    null;

  return (
    <Drawer open={open} onClose={handleClose} anchor={'right'}>
      <Grid container className={classes.schemaViewerContainer}>
        {
          schemaToCompare && (
            <>
              <Grid item xs={6} className={classes.schemaViewerSide}>
                <SnapshotInfo schema={schemaToCompare.snapshotA} />
                <Accordion
                  expanded={expandChanges}
                  onChange={() => setExpandChanges(!expandChanges)}
                  className={classes.changesAccordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Change list from v{schemaToCompare.snapshotB.version} to v{schemaToCompare.snapshotA.version}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ marginLeft: 10, }}>
                      <Typography>Changed fields: {schemaToCompare.changes.changedFields.length}</Typography>
                      {
                        schemaToCompare.changes.changedFields.map((field, index) => (
                          <Typography variant={'subtitle2'} key={index}>{`\xa0\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0\xa0${field.name}`}</Typography>
                        ))
                      }
                      <br />
                      <Typography>Added fields: {schemaToCompare.changes.addedFields.length}</Typography>
                      {
                        schemaToCompare.changes.addedFields.map((field, index) => (
                          <Typography variant={'subtitle2'} key={index}>{`\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0${field.name}`}</Typography>
                        ))
                      }
                      <br />
                      <Typography>Removed fields: {schemaToCompare.changes.removedFields.length}</Typography>
                      {
                        schemaToCompare.changes.removedFields.map((field, index) => (
                          <Typography variant={'subtitle2'} key={index}>{`\xa0\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0\xa0${field.name}`}</Typography>
                        ))
                      }
                      <br />
                      {
                        schemaToCompare.changes.tagsChange !== 0
                        && (
                          schemaToCompare.changes.tagsChange > 0
                            ? (
                              <Typography>{schemaToCompare.changes.tagsChange} tag\s added</Typography>
                            ) : (
                              <Typography>{schemaToCompare.changes.tagsChange * -1} tag\s removed</Typography>
                            )
                        )
                      }
                      {schemaToCompare.changes.tagsChange !== 0 && <br />}
                      {
                        schemaToCompare.changes.labelsChange !== 0
                        && (
                          schemaToCompare.changes.labelsChange > 0
                            ? (
                              <Typography>{schemaToCompare.changes.labelsChange} label\s added</Typography>
                            ) : (
                              <Typography>{schemaToCompare.changes.labelsChange * -1} label\s removed</Typography>
                            )
                        )
                      }
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandCompare}
                  onChange={() => setExpandCompare(!expandCompare)}
                  className={classes.accordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Version {schemaToCompare.snapshotA.version} - Schema</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid className={classes.treeViewGrid}>
                      <SchemaView
                        schema={schemaToCompare.snapshotA}
                        expanded={selectedFields ? selectedFields.schemaA : []}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={6} className={classes.schemaViewerSide}>
                <SnapshotInfo schema={schemaToCompare.snapshotB} />
                <Grid container className={classes.targetVersionFormContainer}>
                  <FormControl
                    fullWidth
                    size="small"
                    className={classes.targetVersionForm}
                  >
                    <InputLabel>Select a version to compare with current schema</InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={(event) => handleCompare(event.target.value as number)}
                      label={'Select a version to compare with current schema'}
                    >
                      {
                        snapshots.map((snapshot, index) => (
                          <MenuItem key={index} value={index} disabled={snapshot.snapshots[0].version === schema.version}>Version {snapshot.snapshots[0].version}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Accordion
                  expanded={expandSelected}
                  onChange={() => setExpandSelected(!expandSelected)}
                  className={classes.accordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Version {schemaToCompare.snapshotB.version} - Schema</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid className={classes.treeViewGrid}>
                      <SchemaView
                        schema={schemaToCompare.snapshotB}
                        expanded={selectedFields ? selectedFields.schemaB : []}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </>
          )
        }
        {
          !schemaToCompare
          && (
            <Grid item xs={12} className={classes.schemaViewerSide}>
              <SnapshotInfo schema={schema} />
              <Grid container className={classes.targetVersionFormContainer}>
                <FormControl
                  fullWidth
                  size="small"
                  className={classes.targetVersionForm}
                >
                  <InputLabel>Select a version to compare with current schema</InputLabel>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={(event) => handleCompare(event.target.value as number)}
                    label={'Select a version to compare with current schema'}
                  >
                    {
                      snapshots.map((snapshot, index) => (
                        <MenuItem key={index} value={index} disabled={snapshot.snapshots[0].version === schema.version}>Version {snapshot.snapshots[0].version}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Accordion
                expanded={expandSelected}
                onChange={() => setExpandSelected(!expandSelected)}
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Version {schema.version} - Schema</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid className={classes.treeViewGrid}>
                    <SchemaView schema={schema} />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )
        }
      </Grid>
    </Drawer>
  );
};

export { SchemaViewer, };
