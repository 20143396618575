import React, { useState, } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Tab,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, } from '@material-ui/core/styles';
import AceEditor from 'react-ace';
import LoadingComponent from '../Loading';
import {
  TabContext,
  TabList,
} from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
}));

const ModalWithTabs = ({
  open,
  title,
  value,
  mode,
  loading,
  handleClose,
  scalaValue,
}: {
  open: boolean;
  title: string;
  value: string;
  mode: 'javascript' | 'sql';
  loading: boolean;
  handleClose: () => void;
  scalaValue: string;
}) => {
  const classes = useStyles();
  const [copyDisable, setCopyDisabled,] = useState(false);
  const [buttonText, setButtonText,] = useState('Copy');
  const [tab, setTab,] = useState(1);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <>
        <Card
          style={{
            width: '70%',
            height: 'inherit',
          }}
        >
          <CardHeader
            style={{
              borderBottom: '1px solid #80808045',
              boxShadow: '0 0 6px 0 #80808045',
            }}
            action={
              <IconButton aria-label="close" onClick={(event) => {
                event.stopPropagation();
                handleClose();
              }}>
                <CloseIcon />
              </IconButton>
            }
            title={title}
          />
          <TabContext value={tab as any}>
            <TabList
              TabIndicatorProps={{ style: { backgroundColor: '#3f51b5', }, }}
              onChange={(event, value: number) => {
                setTab(value);
              }}
              aria-label="basic tabs example">
              <Tab
                label="Spark Code"
                value={1}
              />
              <Tab
                label="Scala Code"
                value={2}
              />
            </TabList>
          </TabContext>
          { tab === 1 ? (
            <>
              <CardContent
                style={{
                  padding: 0,
                  height: '70vh',
                }}
              >
                <div className={classes.paper}>
                  {
                    loading && (
                      <LoadingComponent />
                    )
                  }
                  {
                    !loading && (
                      <AceEditor
                        height={'100%'}
                        width={'auto'}
                        mode={mode}
                        theme="tomorrow"
                        value={value}
                        fontSize={14}
                        readOnly
                        showPrintMargin={false}
                        showGutter={true}
                      />
                    )
                  }
                </div>
              </CardContent>
              <CardActions
                style={{ marginLeft: 'auto',
                  float: 'right', }}>
                <Button
                  style={{ margin: 7, }}
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={copyDisable}
                  onClick={() => {
                    navigator.clipboard.writeText(value)
                      .then(() => {
                        setButtonText('Copied!');
                        setCopyDisabled(true);
                      });
                  }}
                >
                  {buttonText}
                </Button>
              </CardActions>
            </>
          ) : (
            <>
              <CardContent
                style={{
                  padding: 0,
                  height: '70vh',
                }}
              >
                <div className={classes.paper}>
                  {
                    loading && (
                      <LoadingComponent />
                    )
                  }
                  {
                    !loading && (
                      <AceEditor
                        height={'100%'}
                        width={'auto'}
                        mode={mode}
                        theme="tomorrow"
                        value={scalaValue}
                        fontSize={14}
                        readOnly
                        showPrintMargin={false}
                        showGutter={true}
                      />
                    )
                  }
                </div>
              </CardContent>
              <CardActions
                style={{ marginLeft: 'auto',
                  float: 'right', }}>
                <Button
                  style={{ margin: 7, }}
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={copyDisable}
                  onClick={() => {
                    navigator.clipboard.writeText(value)
                      .then(() => {
                        setButtonText('Copied!');
                        setCopyDisabled(true);
                      });
                  }}
                >
                  {buttonText}
                </Button>
              </CardActions>
            </>
          )}
        </Card>
      </>
    </Modal>
  );
};

export { ModalWithTabs, };
