import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  Chip,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      listStyle: 'none',
      paddingRight: 30,
      paddingLeft: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    labelWrapper: {
      marginRight: 10,
    },
  })
);

export default function TagsComponent(props: any) {
  const classes = useStyles();
  const {
    metadata,
    onAddTag,
    onDeleteTag,
    title,
    secondaryTitle,
    isHidden,
    readOnly,
  } = props;
  const [tagName, setTagName,] = React.useState('');
  const renderTags = () => {
    return (
      <div>
        {metadata.matchingPatterns.length > 0 && (
          <Paper
            elevation={0}
            style={{ background: 'transparent', }}
            component="ul"
            className={classes.root}
          >
            {metadata.matchingPatterns.map(
              (data: any, index: number) => {
                return (
                  <li key={index}>
                    {!readOnly ?
                      <Chip
                        label={data}
                        onDelete={() => {
                          if (onDeleteTag && !readOnly) {
                            onDeleteTag(data);
                          }
                        }}
                        className={classes.chip}
                      />
                      :
                      <Chip
                        label={data}
                        className={classes.chip}
                      />
                    }
                  </li>
                );
              }
            )}
          </Paper>
        )}
      </div>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.labelWrapper} variant="subtitle1">
          {title}
        </Typography>
        {!readOnly && (
          <>
            <TextField
              disabled={readOnly}
              id="input-with-icon-grid"
              variant="outlined"
              size="small"
              style={{ maxWidth: isHidden ? 260 : 400,
                marginTop: 8, }}
              label={secondaryTitle}
              value={tagName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyboardReturnIcon style={{ cursor: readOnly ? '' : 'pointer', }} onClick={(e: any) => {
                      if (props.form === 'business-tag' && onAddTag) {
                        e.preventDefault();
                        if (!isEmpty(tagName) && metadata.matchingPatterns.indexOf(tagName) === -1) {
                          onAddTag(tagName);
                          setTagName('');
                        }
                      }
                      if (props.form === 'catalogs' && onAddTag) {
                        e.preventDefault();
                        if (!isEmpty(tagName) && metadata.tags.indexOf(tagName) === -1) {
                          onAddTag(tagName);
                          setTagName('');
                        }
                      }
                    }} />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e: any) => {
                if (props.form === 'business-tag' && onAddTag && e.key === 'Enter') {
                  e.preventDefault();
                  if (!isEmpty(tagName) && metadata.matchingPatterns.indexOf(tagName) === -1) {
                    onAddTag(tagName);
                    setTagName('');
                  }
                }
                if (props.form === 'catalogs' && onAddTag && e.key === 'Enter') {
                  e.preventDefault();
                  if (!isEmpty(tagName) && metadata.tags.indexOf(tagName) === -1) {
                    onAddTag(tagName);
                    setTagName('');
                  }
                }
              }}
              onChange={(event: any) => {
                setTagName(event.target.value);
              }}
            />
          </>
        )}
      </Grid>
      <Grid container alignItems={'flex-start'}>
        {props.form === 'catalogs'
          ? metadata.tags.length > 0 && (
            <Paper
              elevation={0}
              style={{ background: 'transparent', }}
              component="ul"
              className={classes.root}
            >
              {metadata.tags.map((data: any, index: number) => {
                return (
                  <li key={index}>
                    {!readOnly ?
                      <Chip
                        label={data}
                        onDelete={() => {
                          if (onDeleteTag && !readOnly) {
                            onDeleteTag(data);
                          }
                        }}
                        className={classes.chip}
                      />
                      :
                      <Chip
                        label={data}
                        className={classes.chip}
                      />
                    }
                  </li>
                );
              })}
            </Paper>
          ) : renderTags()}
      </Grid>
    </Grid>
  );
}
