import * as React from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@material-ui/core';
import {
  ArrowDropUp,
  ArrowDropDown,
  PlayArrow,
  Code,
} from '@material-ui/icons';

interface Props {
  handleRunClick: any;
  handleCodeClick: any;
  disabledRunBtn: boolean;
  disabledCodeBtn: boolean;
  runBtnText: string;
}
const RunAndCodeBtn = (props: Props) => {
  const [open, setOpen,] = React.useState(false);
  const anchorRef = React.useRef(null);

  const {
    handleRunClick,
    handleCodeClick,
    disabledRunBtn,
    disabledCodeBtn,
    runBtnText,
  } = props;

  const handleClose = (event: { target: any; }) => {
    if (anchorRef.current && (anchorRef?.current as any).contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  console.log(disabledRunBtn, disabledCodeBtn);

  return (
    <React.Fragment>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 999, }}
      >
        {({ TransitionProps, placement, }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Button
                  onClick={handleCodeClick}
                  color={'primary'}
                  variant={'outlined'}
                  style={{
                    marginBottom: 1,
                    width: 130,
                  }}
                  endIcon={<Code />}
                  disabled={disabledCodeBtn}
                >
                  Code
                </Button>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Button
          color={'primary'}
          onClick={handleRunClick}
          disabled={disabledRunBtn}
          endIcon={<PlayArrow />}
        >
          {runBtnText}
        </Button>
        <Button
          color={'primary'}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
      </ButtonGroup>
    </React.Fragment>
  );
};

export { RunAndCodeBtn, };
