import React, { useEffect, } from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import {
  Azure,
  BigQuery,
  DB2,
  File,
  Ftp,
  GoogleBucket,
  Jdbc,
  MSSQL,
  MySQL,
  Oracle,
  PostgreSQL,
  S3,
  Snowflake,
  Kinetica,
} from '../../../components';
import { useParams, } from 'react-router-dom';
import { HDFSSource, } from '../HDFSSource';
import { useDSCalls, } from '../../../hooks';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 1000,
    margin: '0 auto',
  },
});

interface Params {
  type: string,
  oldName: string,
  isJdbc: string;
}

const EditDataSource = () => {
  const classes = useStyles();
  const params = useParams<Params>();
  const { schemaTypes, fetchSchemaTypes, } = useDSCalls();
  const isJdbc = params.isJdbc && params.isJdbc === 'jdbc';

  useEffect(() => {
    fetchSchemaTypes();
  }, []);

  const renderComponent = () => {
    if (isJdbc) {
      switch (params.type) {
      case 'db2': {
        return <DB2 />;
      }
      case 'oracle': {
        return <Oracle />;
      }
      case 'snowflake': {
        return <Snowflake/>;
      }
      case 'bigquery': {
        return <BigQuery/>;
      }
      case 'mssql':
      case 'sqlserver': {
        return <MSSQL />;
      }
      case 'postgres':
      case 'postgresql': {
        return <PostgreSQL />;
      }
      case 'mysql': {
        return <MySQL />;
      }
      case 'kinetica': {
        return <Kinetica />;
      }
      default: {
        return <Jdbc/>;
      }
      }
    } else {
      switch (params.type) {
      case 'file': {
        return <File schemaTypes={schemaTypes}/>;
      }
      case 'ftp': {
        return <Ftp schemaTypes={schemaTypes}/>;
      }
      case 'gcs': {
        return <GoogleBucket schemaTypes={schemaTypes}/>;
      }
      case 's3': {
        return <S3 schemaTypes={schemaTypes}/>;
      }
      case 'azure_blob': {
        return <Azure schemaTypes={schemaTypes}/>;
      }
      case 'hdfs': {
        return <HDFSSource schemaTypes={schemaTypes}/>;
      }
      default: {
        return <div>No results found...</div>;
      }
      }
    }
  };

  return <div className={classes.root}>{renderComponent()}</div>;
};

export default EditDataSource;
