import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';


const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      textAlign: 'center',
    },
  })
);

interface Props {
  message: string;
  marginTop?: number;
}

function NoDataV2(props: Props) {
  const classes = useStyles();
  const { message, marginTop,  } = props;

  return (
    <Grid container>
      <Grid item xs={4}/>
      <Grid item xs={4} className={classes.loader} style={{ marginTop: marginTop ? marginTop : 100, }}>
        {
          message && (
            <Typography style={{
              color: '#949494',
              marginTop: 5,
            }}>{`${message}`}</Typography>
          )
        }
      </Grid>
      <Grid item xs={4}/>
    </Grid>
  );
}

export { NoDataV2, };
