import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import { makeStyles, } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    paddingLeft: '10px',
    paddingRight: '5px',
  },
  title: {
    flex: '1 1 45%',
  },
});

const AlertsTableToolbar = ({
  handleSearch,
}: {
  // eslint-disable-next-line no-unused-vars
  handleSearch: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}) => {
  const classes = useStyles();
  return (
    <div>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Connections
        </Typography>
        <Tooltip title="Search by name">
          <TextField
            className="align-items-end"
            style={{ paddingRight: '10px', }}
            name="search"
            label="Search"
            color="primary"
            onChange={(event) => {
              handleSearch(event);
            }}
          />
        </Tooltip>
      </Toolbar>
    </div>
  );
};

export default AlertsTableToolbar;
