import React from 'react';
import { useStyles, } from '../../styles';
import {
  Box,
  Grid,
} from '@material-ui/core';
import TagsComponent from '../../../../TagsComponent';
import { LabelComponent, } from '../../../../LabelComponent';
import { Interfaces, } from '../../../../../config';
import { helpers, } from '../../helpers';


const NodeMetadataTagsLabels = ({
  selectedNode,
  handleSelectNode,
  catalog,
  readOnly,
}: {
  selectedNode: Interfaces.Schema;
  // eslint-disable-next-line no-unused-vars
  handleSelectNode: (node: Interfaces.Schema | null) => void;
  catalog: Interfaces.InputCatalogMetadata;
  readOnly?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <p className={classes.sectionTitle} style={{ marginTop: '20px', }}>
        Metadata
      </p>
      <Box className={classes.detailsWrapper}>
        <Grid container>
          <Grid
            style={{
              background: 'white',
              padding: '15px 10px 15px 10px',
            }}
            item xs={5}
          >
            <Grid container>
              <TagsComponent
                readOnly={readOnly}
                isHidden={true}
                isHiddenTooltip={true}
                form="catalogs"
                metadata={selectedNode}
                onAddTag={(tag: string) => {
                  const tags = helpers.addTag(tag, catalog.schema, selectedNode);
                  handleSelectNode({
                    ...selectedNode,
                    tags: tags,
                  });
                }}
                onDeleteTag={(tag: string) => {
                  const tags = helpers.deleteTag(tag, catalog.schema, selectedNode);
                  handleSelectNode({
                    ...selectedNode,
                    tags: tags,
                  });
                }}
                title="Tags:"
                secondaryTitle="Add a tag"
              />
            </Grid>
          </Grid>
          <Grid
            style={{
              background: 'white',
              padding: '15px 10px 15px 10px',
            }}
            item xs={7}
          >
            <LabelComponent
              readOnly={readOnly}
              metadata={selectedNode}
              onAddLabel={(label: Interfaces.CMLabels) => {
                const labels = helpers.addLabel(label, catalog.schema, selectedNode);
                handleSelectNode({
                  ...selectedNode,
                  labels: labels,
                });
              }}
              onRemoveLabel={(label: Interfaces.CMLabels) => {
                const labels = helpers.deleteLabel(label, catalog.schema, selectedNode);
                handleSelectNode({
                  ...selectedNode,
                  labels: labels,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export { NodeMetadataTagsLabels, };
