import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CircularProgress, } from '@material-ui/core';
import { useAuth, } from '../../context';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    textMain: {
      flexGrow: 1,
      display: 'flex',
      fontSize: '17rem',
      textAlign: 'center',
      marginTop: '20%',

    },
    textSecondary: {
      textAlign: 'center',
    },
  })
);


function ApiOffline() {
  const classes = useStyles();
  const { loadingHealth, fetchApiStatus, } = useAuth();

  return (
    <Grid container>
      <Grid item xs={3}/>
      <Grid item xs={6}>
        <Typography variant="h1" className={classes.textMain} component="h2">
          OOPS!

        </Typography>
        <Typography className={classes.textSecondary} variant="h4" gutterBottom>
          IT&apos;S SEEMS THAT THE API IS OFFLINE
        </Typography>
      </Grid>
      <Grid item xs={3}/>
      <Grid item xs={5}/>
      <Grid item xs={2}>
        {loadingHealth && <CircularProgress/>}
        {!loadingHealth && <Button
          style={{ marginLeft: '30%', }}
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => fetchApiStatus()}
        >
          {loadingHealth && <CircularProgress/>} Check Again
        </Button>
        }
      </Grid>
      <Grid item xs={5}/>
    </Grid>
  );
}

export default ApiOffline;
