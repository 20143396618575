import React from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import {
  Button,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Close, } from '@material-ui/icons';
import { Alert, } from '@material-ui/lab';
import Loading from '../../Loading';
import { DataSourceTable, } from '../DataSourceTable';
import { NoDataComponent, } from '../../NoDataComponent';
import { useAuth, } from '../../../context';
import { useDSCalls, } from '../../../hooks/useDSCalls';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paddingTop: { paddingTop: '20px', },
});

function DataSourceDashboard(props: RouteComponentProps) {
  const classes = useStyles();
  const { isAuthorized, } = useAuth();
  const [canWrite,] = React.useState(isAuthorized('ADAPTIVE_DS_WRITE'));

  const navigationData = {
    component: 'dataSources',
    header: 'No Data Sources',
    text1: 'You have not created a data source yet',
    text2: 'Create a data source to get started',
    buttonText: 'Add data source',
    route: '/data-source/add',
  };

  const { dataSources, loading, error, deleteDataSource, } = useDSCalls();

  if (loading && !dataSources) {
    return <Loading/>;
  }

  if (!loading && dataSources && dataSources.length === 0) {
    return <NoDataComponent data={navigationData}/>;
  }


  return (
    <div className={classes.root}>
      <Grid className={classes.root}>
        <Grid container>
          {error && (
            <Grid item xs={4}>
              <Alert
                severity="error"
                style={{ marginLeft: '20px', }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                  >
                    <Close fontSize="inherit"/>
                  </IconButton>
                }
              >
                {error}
              </Alert>
            </Grid>
          )}
        </Grid>
        <Grid item>
          {canWrite &&
            <Button
              style={{ marginBottom: '15px', }}
              variant="contained"
              color="primary"
              onClick={() => {
                props.history.push('/data-source/add');
              }}
            >
              Add data source
            </Button>
          }
          <DataSourceTable dataSources={dataSources} loading={loading} deleteDataSource={deleteDataSource}/>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(DataSourceDashboard);
