import React from 'react';
import { Interfaces, } from '../../../config';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

interface Props {
  table: Interfaces.CollectionTable;
  // eslint-disable-next-line no-unused-vars
  handleChange: (table: Interfaces.CollectionTable) => void;
  handleTableSave: any;
}

const TableInfo = (props: Props) => {
  const {
    table,
    handleChange,
    handleTableSave,
  } = props;

  return (
    <Grid container>
      <Grid item xs={4}>
        <TextField
          required
          label={'Name'}
          error={!!table._errors}
          name={'table_name'}
          value={table.name}
          variant="outlined"
          size='small'
          fullWidth
          style={{
            paddingRight: 7,
          }}
          onChange={(e) => {
            handleChange({
              ...table,
              name: e.target.value,
            });
          }}
          onBlur={() => {
            handleTableSave(table);
          }}
        />
        {
          table._errors ? (
            <Typography variant={'caption'} style={{ color: '#d24949', }}>{`* ${table._errors[0]}`}</Typography>
          ) : (
            <div style={{ minHeight: 5, }} />
          )
        }
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={'Dataset / Schema'}
          name={'dataset'}
          value={table.schema}
          variant="outlined"
          size='small'
          fullWidth
          style={{
            paddingRight: 7,
          }}
          onChange={(e) => {
            if (e.target.value === '(no-schema)') {
              handleChange({
                ...table,
                schema: undefined,
              });
            } else {
              handleChange({
                ...table,
                schema: e.target.value,
              });
            }
          }}
          onBlur={() => {
            handleTableSave(table);
          }}
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          label={'Description'}
          name={'description'}
          value={table.description}
          variant="outlined"
          size='small'
          style={{
            paddingRight: 7,
          }}
          fullWidth
          onChange={(e) => {
            handleChange({
              ...table,
              description: e.target.value,
            });
          }}
          onBlur={() => {
            handleTableSave(table);
          }}
        />
      </Grid>
    </Grid>
  );
};

export { TableInfo, };
