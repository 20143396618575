import { makeStyles, } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  test: {
    '&& .cron_builder': {
      maxWidth: 'none',
      padding: '15px',
      '& .nav-tabs': {
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 5,
        padding: '13px 5px',
        background: '#3f51b5',
        borderRadius: '5px',
        fontWeight: '700',

        '& .nav-item': {
          flex: 'unset',
          '& .active': {
            background: 'white',
            borderRadius: '3px',
            color: '#3f51b5',
          },
        },
        '& .nav-link': {
          color: 'white',
          padding: '9px 30px',
          fontWeight: '700',

          '@media (max-width: 1300px)': {
            padding: '9px 20px',
          },

          '&:hover': {
            background: 'transparent',
          },
        },
      },
      '& .cron_builder_bordering': {
        borderRadius: '5px',
        '& .well-small': {
          display: 'flex',
        },
      },
      '& .cron-builder-bg': {
        background: '#3f51b5',
        marginTop: '1em',
        borderRadius: '5px',
        fontWeight: '700',
      },
    },
  },
  schedule: {
    marginBottom: '40px',
    textAlign: 'center',
    marginRight: '80px',
  },
  stepperGrid: {
    position: 'fixed',
    bottom: '20px',
    width: '100%',
  },
  container: {
    width: '100%',
    // height: 'calc(100vh - 200px)',
  },
  saveButton: {
    marginRight: 3,
    width: 200,
  },
  closeButton: {
    marginLeft: 3,
    width: 200,
  },
}));

export { useStyles, };
