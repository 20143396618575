import React, {
  useEffect,
  useState,
} from 'react';
import { DataSourceTableHead, } from './DataSourceTableHead';
import { DataSourceTableToolbar, } from './DataSourceTableToolbar';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router';
import {
  Constants,
  Interfaces,
} from '../../../config';
import { useLocalStorage, } from '../../Hooks';
import { useAuth, } from '../../../context';
import TableRowDS from './TableRowDS/TableRowDS';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: '10px',
  },
  table: {
    minWidth: 750,
  },
  dataSourceActionsCell: {
    minWidth:'154px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  icon: {
    maxWidth: '50px',
    maxHeight: '30px',
  },
});

interface DataSourceTableProps extends RouteComponentProps {
  dataSources: Array<Interfaces.DataSourceType> | null;
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  deleteDataSource: (dataSourceId: string, setLoader: React.Dispatch<React.SetStateAction<boolean>>) => Promise<void>;
}

function DataSourceTable(props: DataSourceTableProps) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage,] = useLocalStorage('datasources_table.rows_per_page', 5);

  const { dataSources, deleteDataSource, } = props;

  const [filters, setFilters,] = useState({
    checked: false,
    order: 'asc',
    orderBy: 'connectionName',
    page: 0,
  });

  const [filteredDataSources, setFilteredDataSources,] = useState<Array<Interfaces.DataSourceType>>([]);


  const { isAuthorized, deploymentType, } = useAuth();
  const [canWrite,] = useState(isAuthorized('ADAPTIVE_DS_WRITE'));
  const [canDelete,] = useState(isAuthorized('ADAPTIVE_ADMIN') || deploymentType === 'cloud');
  const [canExecute,] = React.useState(isAuthorized('ADAPTIVE_DS_EXECUTE'));

  useEffect(() => {
    dataSources && setFilteredDataSources(dataSources);
  }, [dataSources, rowsPerPage,]);

  const descendingComparator = (a: any, b: any, orderBy: any) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: any, orderBy: any) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (dataSources: any, comparator: any) => {
    const stabilizedThis = dataSources.map((el: any, index: number) => [
      el,
      index,
    ]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  };

  const handleSearch = (event: any) => {
    if (!dataSources) {
      return;
    }
    let filteredData = dataSources.filter(
      (
        value: Interfaces.BaseDataSource |
          Interfaces.JDBCDataSource |
          Interfaces.GoogleBucketDataSource |
          Interfaces.S3DataSource |
          Interfaces.AzureDataSource
      ) =>
        value.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredDataSources(filteredData);
    // TODO dispatch filter
    // this.props.filterConnections(fileredData);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = filters.orderBy === property && filters.order === 'asc';
    setFilters({
      ...filters,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setFilters({
      ...filters,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };


  const handleEdit = (
    dataSource:
      Interfaces.BaseDataSource |
      Interfaces.JDBCDataSource |
      Interfaces.GoogleBucketDataSource |
      Interfaces.S3DataSource |
      Interfaces.AzureDataSource
  ) => {
    const dsType = Constants.path.dataSourceTypeMap[dataSource.type];
    let uri = '';
    if (dsType.toLowerCase().includes('jdbc') && 'driverName' in dataSource) {
      uri = `/data-source/edit/${dataSource.driverName}/${dataSource.name}/jdbc`;
    } else {
      uri = `/data-source/edit/${dsType}/${dataSource.name}/nojdbc`;
    }
    props.history.push(uri);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <DataSourceTableToolbar handleSearch={handleSearch}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <DataSourceTableHead
              classes={classes}
              order={filters.order}
              orderBy={filters.orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {
                stableSort(
                  filteredDataSources,
                  getComparator(filters.order, filters.orderBy)
                )
                  .slice(filters.page * rowsPerPage, filters.page * rowsPerPage + rowsPerPage)
                  .map(
                    (
                      dataSource:
                        | Interfaces.BaseDataSource
                        | Interfaces.JDBCDataSource
                        | Interfaces.GoogleBucketDataSource
                        | Interfaces.S3DataSource
                        | Interfaces.AzureDataSource,
                      index: number
                    ) => {
                      return (
                        <TableRowDS
                          key={dataSource.id}
                          dataSource={dataSource}
                          index={index}
                          handleEdit={handleEdit}
                          canWrite={canWrite}
                          canDelete={canDelete}
                          canExecute={canExecute}
                          deleteDataSource={deleteDataSource}
                        />
                      );
                    }
                  )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25,]}
          component="div"
          count={filteredDataSources.length}
          rowsPerPage={rowsPerPage}
          page={filters.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default withRouter(DataSourceTable);
