import React from 'react';
import { TableCell, } from '@material-ui/core';


const CustomTableColumn = ({ value, }: { value: React.ReactNode }) => {
  return (
    <TableCell
      style={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      {value}
    </TableCell>
  );
};
export { CustomTableColumn, };
