import React, {
  useMemo,
  useState,
} from 'react';
import { Interfaces, } from '../../../config';
import { CatalogCard, } from './CatalogCard';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useAuth, } from '../../../context';

const CatalogSelector = ({
  catalogs,
  handleSelect,
}: {
  catalogs: Array<Interfaces.InputCatalogMetadata>;
  // eslint-disable-next-line no-unused-vars
  handleSelect: (catalogId: string) => void
}) => {
  const [filteredCatalogs, setFilteredCatalogs,] = useState<Array<Interfaces.InputCatalogMetadata>>(catalogs);
  const [keyword, setKeyword,] = useState<string>('');
  const { isAuthorized, } = useAuth();
  const [readCatAcces,] = useState(isAuthorized('ADAPTIVE_CAT_READ'));
  const [readAccess,] = useState(isAuthorized('ADAPTIVE_EV_READ'));

  const filterCatalogs = () => {
    return catalogs.filter((catalog) => catalog.name.toLowerCase().includes(keyword.toLowerCase()));
  };

  useMemo(() => {
    if (keyword !== '') {
      setFilteredCatalogs(filterCatalogs());
    } else {
      setFilteredCatalogs(catalogs);
    }
  }, [keyword,]);

  return (
    <Grid container>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <TextField
          variant={'outlined'}
          placeholder={'Filter catalogs'}
          fullWidth
          onChange={(e) => setKeyword(e.target.value)}
        />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={6} style={{ marginTop: 10, }}>
        {
          (readCatAcces && readAccess) ? filteredCatalogs.map((catalog) => (
            catalog.dataSourceDescription !== 'external' && (
              <CatalogCard
                key={catalog.id}
                catalog={catalog}
                handleSelect={(catalog) => catalog.id && catalog.lastRunId && handleSelect(catalog.id)}
              />
            )
          )) : <Typography variant="h5" style={{ padding: '10px', }}>Not authorized to view catalogs</Typography>
        }
      </Grid>
      <Grid item xs={3} />

    </Grid>
  );
};

export { CatalogSelector, };
