import React from 'react';
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import LoadingComponent from '../../../Loading';
import { HelpOutline, } from '@material-ui/icons';
import { SchemaView, } from '../../../SchemaView';
import { Interfaces, } from '../../../../config';
import { helpers, } from '../helpers';

const SchemaViewer = ({
  catalog,
  setCatalog,
  loadingSchema,
  schemaError,
  isBucket,
  selectedNode,
  selectNode,
  readOnly,
  handleSelectAllFields,
  scrollToNode,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  // eslint-disable-next-line no-unused-vars
  setCatalog: (catalog: Interfaces.InputCatalogMetadata) => void;
  handleSelectAllFields?: () => void;
  loadingSchema: boolean;
  schemaError: boolean;
  isBucket: boolean;
  selectedNode: Interfaces.Schema | null;
  // eslint-disable-next-line no-unused-vars
  selectNode: (node: Interfaces.Schema) => void;
  readOnly?: boolean;
  scrollToNode?: Interfaces.Schema;
}) => {

  if (loadingSchema) {
    return (
      <div style={{ textAlign: 'center', }}>
        <LoadingComponent
          size={40}
          marginTop={isBucket ? 130 : 200}
          message={'Loading schema'}
          secondaryMessage={
            isBucket ? 'Depending on the number of files in the bucket, loading schema from GCS, S3, or AZURE might take a while' : undefined
          }
        />
        {
          isBucket && (
            <Tooltip
              title={
                <>
                  2 files (1 csv, 1 avro) - 2.17s<br/>
                  10 files (5 csv, 5 avro) - 9.74s<br/>
                  50 files (25 csv, 25 avro) - 1.1min<br/>
                  100 files (50 csv, 50 avro) - 1.9 min<br/>
                  500 files (250 csv, 250 avro) - 10.1 min<br/>
                </>
              }
            >
              <IconButton>
                <HelpOutline color={'primary'}/>
              </IconButton>
            </Tooltip>
          )
        }
      </div>
    );
  }

  if (schemaError || !catalog.schema) {
    return (
      <Grid justifyContent={'center'} style={{ marginTop: 120, }}>
        <Typography align={'center'} variant={'h5'}>Failed to fetch schema</Typography>
        <Typography align={'center'} variant={'h6'}>Try reloading the page</Typography>
      </Grid>
    );
  }

  return (
    <SchemaView
      schema={catalog.schema}
      showCheckBox={!readOnly}
      checkBoxReadOnly={catalog?.includeAllSchemas || readOnly}
      checkBoxDefaultState={readOnly ? true : catalog?.includeAllSchemas ? true : undefined}
      onTreeNodeClick={(treeNode: Interfaces.Schema) => selectNode(treeNode)}
      onSelectChange={(treeNode: Interfaces.Schema, state: boolean) => {
        if (treeNode._path === '' && handleSelectAllFields) {
          handleSelectAllFields();
          return;
        }
        helpers.setNodeSelectionTree(catalog.schema, treeNode, state);
        if (state) {
          helpers.setReverseSelection(catalog.schema, treeNode);
        }
        setCatalog({
          ...catalog,
          schema: catalog.schema,
        });
      }}
      selectedTreeNode={selectedNode?._uid}
      scrollToNode={scrollToNode}
    />
  );
};

export { SchemaViewer, };
