import React from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { ActionButtons, } from '../ActionButtons';
import { useStyles, } from '../../styles';
import { Interfaces, } from '../../../../../config';
import {
  DataSourceType,
  helpers,
} from '../../helpers';
import { TotalRecords, } from '../TotalRecords';
import {
  faBorderNone,
  faColumns,
  faDatabase,
  faLink,
  faProjectDiagram,
  faTable,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { GitConfigMenu, } from '../../../GitConfigMenu';
import {
  VpnKey,
  LocalOffer,
} from '@material-ui/icons';
import { Icon, } from '../../../../Icon';
import { catalogsServices, } from '../../../../../services';
import { AxiosResponse, } from 'axios';
import { useSnackbar, } from 'notistack';

const NodeMetadataDetails = ({
  catalog,
  selectedDataSource,
  selectedNode,
  handleSelectNode,
  totalRecords,
  loadingTotalRecords,
  fetchTotalRecords,
  readOnly,
  setScrollToNode,
  showGrants,
  setShowGrants,
  setCatalog,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  selectedDataSource: DataSourceType;
  selectedNode: Interfaces.Schema;
  // eslint-disable-next-line no-unused-vars
  handleSelectNode: (node: Interfaces.Schema | null) => void;
  totalRecords: any;
  loadingTotalRecords: boolean;
  // eslint-disable-next-line no-unused-vars
  fetchTotalRecords: (selectedNode?: Interfaces.Schema) => void;
  readOnly?: boolean;
  // eslint-disable-next-line no-unused-vars
  setScrollToNode?: (node: Interfaces.Schema) => void;
  showGrants: boolean;
  setShowGrants: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setCatalog: (catalog: Interfaces.InputCatalogMetadata) => void;
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading,] = React.useState(false);
  const { enqueueSnackbar, } = useSnackbar();

  return (
    <Grid item xs={12}>
      <Box className={classes.sectionContainer}>
        <div
          style={{
            display: 'flex',
            width: '100%',
          }}
        >
          <div className={classes.breadCrumbs}>
            <p
              className={classes.breadCrumbsLink}
              onClick={() => handleSelectNode(null)}
            >
              <Icon
                icon={faLink}
                style={{
                  marginRight: 4,
                  color: '#602B78',
                }}
              />
              {selectedDataSource.name}
            </p>
            <p>
              {` / `}
            </p>
            <p
              className={classes.breadCrumbsDatabase}
              onClick={() => handleSelectNode(catalog.schema)}
            >
              <Icon
                icon={faDatabase}
                style={{
                  marginRight: 4,
                  color: '#1E5CAE',
                }}
              />
              {catalog.schema.name}
            </p>
            {
              selectedNode.properties?.path && (
                <p>
                  {
                    selectedNode.properties?.path?.slice(0, selectedNode.properties?.path?.length - 1)?.split('.')?.map((path: string, index: number) => (
                      path !== '' && (
                        <>
                          /
                          <FontAwesomeIcon
                            icon={index === 0 ? faProjectDiagram : index === 1 ? faTable : faColumns}
                            style={{
                              marginRight: 4,
                              marginLeft: 4,
                            }}
                            color={index === 0 ? '#29913A' : index === 1 ? '#D67E0A' : '#1E5CAE'}
                          />
                          <span style={{ marginRight: 4, }}>
                            {path}
                          </span>
                        </>
                      )
                    ))
                  }
                </p>
              )
            }
          </div>
          {
            catalog.lastRunId && (
              <div
                style={{
                  display: 'flex',
                  float: 'right',
                  alignItems: 'center',
                }}
              >
                <GitConfigMenu catalog={catalog}/>
              </div>
            )
          }
        </div>
      </Box>
      <Box className={classes.detailsWrapper}>
        <Grid container>
          <Grid item xs={5}>
            <div className={classes.detailsStyle}>
              <div>
                <FontAwesomeIcon
                  style={{
                    color: '#0b52a4',
                    marginRight: 6,
                  }}
                  icon={
                    selectedNode.sourceType === 'database'?
                      faDatabase : selectedNode.sourceType === 'schema' ?
                        faProjectDiagram : selectedNode.sourceType === 'table' ?
                          faTable : selectedNode.sourceType === 'view' ?
                            faBorderNone : faColumns
                  }
                />
                <b style={{ fontSize: 18, }}>
                  {selectedNode.name}
                </b>
                <span>
                &nbsp;&nbsp;{selectedNode.sourceType || selectedNode.type}
                </span>
                {
                  (
                    selectedNode.sourceType === 'database'
                    || selectedNode.sourceType === 'schema'
                    || selectedNode.sourceType === 'table'
                  ) && (
                    <Tooltip
                      title={'Apply auto tagging'}
                      placement={'top'}
                    >
                      <IconButton
                        color={'primary'}
                        disabled={isLoading}
                        style={{
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          setIsLoading(true);
                          catalogsServices.applyAutoTagging({
                            ...catalog,
                            schema: helpers.cleanseSchema(JSON.parse(JSON.stringify(selectedNode))),
                          })
                            .then((response: AxiosResponse<Interfaces.InputCatalogMetadata>) => {
                              let newSchema = helpers.addMissingFieldsToSchema(response.data.schema, '', false);
                              helpers.mapNewSchemaToExistingSchema(selectedNode, newSchema);
                              setCatalog({
                                ...catalog,
                                schema: helpers.mapAutoTags(catalog.schema),
                              });
                              enqueueSnackbar('Auto tagging applied', { variant: 'success', });
                            })
                            .catch((error) => {
                              enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
                            })
                            .finally(() => {
                              setIsLoading(false);
                            });
                        }}
                      >
                        <LocalOffer />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </div>
              {!readOnly && (
                selectedNode.sourceType !== 'database' && selectedNode.fields && selectedNode.fields.length > 0 && (
                  <Tooltip title={'If checked will include the current fields, all the upcoming fields and the future changes to the existing fields'}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={selectedNode.properties?.includeAllFields || catalog.includeAllSchemas}
                          disabled={catalog.includeAllSchemas}
                          onChange={(event) => {
                            if (event.target.checked) {
                              helpers.setReverseSelection(catalog.schema, selectedNode);
                            }
                            helpers.includeAllChildren(
                              catalog.schema,
                              selectedNode,
                              event.target.checked
                            );
                            handleSelectNode({
                              ...selectedNode,
                              properties: {
                                ...selectedNode.properties,
                                includeAllFields: event.target.checked,
                              },
                            });
                          }}
                          name="includeAllFields"
                        />
                      }
                      label={
                        <p
                          style={{
                            marginTop: 14,
                            fontSize: 14,
                          }}
                        >
                          Include all Children
                        </p>
                      }
                    />
                  </Tooltip>
                )
              )}
            </div>
          </Grid>
          {
            selectedNode.type.toLowerCase() === 'record' && (
              <ActionButtons
                catalog={catalog}
                selectedDataSource={selectedDataSource}
                selectedNode={selectedNode}
              />
            )
          }
        </Grid>
        {!readOnly && (
          <>
            <p className={classes.description}>
              Description
            </p>
            <TextField
              disabled={readOnly}
              value={selectedNode.properties?.description}
              InputProps={{
                classes: {
                  input: classes.descriptionInput,
                },
              }}
              variant={'outlined'}
              multiline
              fullWidth
              size={'small'}
              onChange={(event) => {
                helpers.updateCatalogField(
                  `schema.${selectedNode?._path}`,
                  {
                    ...selectedNode,
                    properties: {
                      ...selectedNode.properties,
                      description: event.target.value,
                    },
                  },
                  catalog
                );
                handleSelectNode({
                  ...selectedNode,
                  properties: {
                    ...selectedNode.properties,
                    description: event.target.value,
                  },
                });
              }}
            />
          </>
        )}
        <Grid container className={classes.fieldsWrapper}>
          {
            selectedNode.fields && <Grid item xs={2}>
              Items
              <p>{selectedNode.fields?.length}</p>
            </Grid>
          }
          <Grid item xs={2}>
            Source Type
            <p>{selectedNode.sourceType || 'N\\A'}</p>
          </Grid>
          {
            selectedNode.sourceType !== 'database' &&
            <Grid item xs={2}>
              Type
              <p>{selectedNode.type || 'N\\A'}</p>
            </Grid>
          }
          {
            selectedDataSource.type === 'JDBC'
            && !selectedNode.fields
            && (
              <Grid item xs={2}>
                Auto Increment
                <p>{selectedNode.properties?.autoIncrement ? 'YES' : 'NO'}</p>
              </Grid>
            )
          }
          {
            selectedDataSource?.type === 'JDBC'
            && (selectedNode.sourceType === 'table' || selectedNode.sourceType === 'schema' || selectedNode.sourceType === 'database')
            && (
              <Grid item xs={3} style={{ marginTop: -14, }}>
                <TotalRecords
                  totalRecords={totalRecords}
                  fetchTotalRecords={() => {
                    fetchTotalRecords(selectedNode);
                  }}
                  totalTables={selectedNode.sourceType === 'database' ? helpers.countTotalTables(selectedNode) : undefined}
                  loadingTotalRecords={loadingTotalRecords}
                  tableName={selectedNode.sourceType === 'database' ? '' : selectedNode?.properties?.path}
                />
              </Grid>
            )
          }
          {
            selectedNode.properties?.userGrants &&
            <Grid item xs={2}>
              Grants
              <Link
                className={classes.linkStyle}
                onClick={() => {
                  setShowGrants(!showGrants);
                }}
              >
                {showGrants ? 'Hide Grants' : 'Show Grants'}
              </Link>
            </Grid>
          }
          {
            selectedNode.properties?.foreignKeys?.length > 0 && (
              <Grid item xs={3}>
                Foreign keys
                <p
                  style={{
                    color: '#0b52a4',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const path =
                      `${selectedNode.properties?.foreignKeys[0].primaryTableSchema}.`
                      + `${selectedNode.properties.foreignKeys[0].primaryTableName}.`
                      + `${selectedNode.properties.foreignKeys[0].primaryColumnName}.`;
                    const node = helpers.findElement(catalog.schema.fields, path);
                    if (node) {
                      handleSelectNode(node);
                      setScrollToNode && setScrollToNode(node);
                    }
                  }}
                >
                  <VpnKey className={classes.foreignKeyIcon} />
                  {
                    `${selectedNode.properties.foreignKeys[0].primaryTableName}.${selectedNode.properties.foreignKeys[0].primaryColumnName}`
                  }
                </p>
              </Grid>
            )
          }
        </Grid>
      </Box>
    </Grid>
  );
};

export { NodeMetadataDetails, };
