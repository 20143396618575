import React from 'react';
import { CustomTable, } from '../../TableComponent';
import {
  Paper,
  Tooltip,
} from '@material-ui/core';
import {
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { orderingType, } from '../../TableComponent/types';
import { Interfaces, } from '../../../config';
import { adminServices, } from '../../../services';
import { AxiosResponse, } from 'axios';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../../utils';

const UsersTable = () => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar, } = useSnackbar();

  const queryParams = new URLSearchParams(location.search);
  const _page = queryParams.get('page');
  const _perPage = queryParams.get('size');
  const _orderBy = queryParams.get('sort');
  const _keyword = queryParams.get('keyword');

  const [page, setPage,] =
    React.useState(parseInt(_page ?? '0'));
  const [perPage, setPerPage,] =
    React.useState(parseInt(_perPage ?? '10'));
  const [order, setOrder,] =
    React.useState<orderingType>((_orderBy?.split(',')[1] as any) ?? 'desc');
  const [orderBy, setOrderBy,] =
    React.useState<keyof Interfaces.IUser>(_orderBy?.split(',')[0] as any);
  const [keyword, setKeyword,] =
    React.useState(_keyword ?? '');
  const [totalElements, setTotalElements,] = React.useState(0);

  const [users, setUsers,] = React.useState<Array<any>>([]);
  const [loading, setLoading,] = React.useState(true);

  React.useEffect(() => {
    page !== undefined && queryParams.set('page', page.toString());
    perPage !== undefined && queryParams.set('size', perPage.toString());
    orderBy !== undefined && queryParams.set('sort', `${orderBy as string},${order.toUpperCase()}`);
    history.replace({ search: queryParams.toString(), });
  }, [page, perPage, order, orderBy,]);

  React.useEffect(() => {
    if (keyword.length === 0) {
      queryParams.delete('keyword');
      queryParams.set('page', '0');
      setPage(0);
      history.replace({ search: queryParams.toString(), });
      return;
    }
    if (keyword.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        queryParams.set('keyword', keyword);
        queryParams.set('page', '0');
        setPage(0);
        history.replace({ search: queryParams.toString(), });
      }, 400);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [keyword,]);

  React.useEffect(() => {
    setLoading(true);
    adminServices.fetchUsers(queryParams.toString())
      .then((response: AxiosResponse<{ data: Interfaces.IUsersResponse }>) => {
        setUsers(response.data.data.content);
        setTotalElements(response.data.data.totalElements);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error));
      })
      .finally(() => setLoading(false));
  }, [_page, _perPage, _orderBy, _keyword,]);

  return (
    <Paper>
      <CustomTable<Interfaces.IUser>
        id={'users_table'}
        name={'Users'}
        rows={users}
        columns={[
          {
            id: 'username',
            label: 'Username',
            render: function render(user) {
              return (
                <Link to={`/profile/${user.id}`}>
                  {user.username}
                </Link>
              );
            },
          },
          {
            id: 'is_active',
            label: 'Is active',
            render: function render(user) {
              return <span>{user.is_active ? 'YES' : 'NO'}</span>;
            },
          },
          {
            id: 'roles',
            label: 'Roles',
            render: function render(user) {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginRight: 4, }}>
                    {user.roles[0]?.name}
                  </div>
                  <Tooltip
                    title={
                      <>
                        {user.roles.map((role) => (
                          <p key={role.id}>{role.name}</p>
                        ))}
                      </>
                    }
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        marginLeft: 10,
                      }}
                    >
                      +{user.roles.length - 1}
                    </span>
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
        loading={loading}
        indexCell
        customPagination={{
          page: page,
          setPage: setPage,
          perPage: perPage,
          setPerPage: setPerPage,
          count: totalElements,
          order: order,
          setOrder: setOrder,
          orderBy: orderBy,
          setOrderBy: function (orderBy) {
            setOrderBy(orderBy);
            setOrder(order === 'asc' ? 'desc' : 'asc');
          },
          keyword: keyword,
          setKeyword: setKeyword,
        }}
        containerStyle={{
          maxHeight: '480px',
        }}
      />
    </Paper>
  );
};

export { UsersTable, };
