import React, {
  useEffect,
  useState,
} from 'react';
import { Interfaces, } from '../../../../config';
import { useStyles, } from '../styles';
import {
  DataSourceType,
  helpers,
} from '../helpers';
import { SchemaViewer, } from '../CatalogForm/SchemaViewer';
import { useParams, } from 'react-router-dom';
import {
  catalogsServices,
  datasourceServices,
} from '../../../../services';
import { AxiosResponse, } from 'axios';
import { useSnackbar, } from 'notistack';
import LoadingComponent from '../../../Loading';
import { CatalogMetadata, } from '../Metadata';
import { CatalogInfo, } from './CatalogInfo';
import { Grid, } from '@material-ui/core';
import { DBDiagram, } from '../ERDiagram';
import { HeaderTabsView, } from './MainHeaderView';

const ViewCatalog = () => {
  const classes = useStyles();
  const { catalogId, external, } = useParams<{ catalogId: string, external?: string }>();
  const [activeStep, setActiveStep,] = useState(1);


  const { enqueueSnackbar, } = useSnackbar();
  const [loading, setLoading,] = useState(false);
  const [catalog, setCatalog,] = useState<Interfaces.InputCatalogMetadata>({} as Interfaces.InputCatalogMetadata);
  const [selectedNode, setSelectedNode,] = useState<Interfaces.Schema | null>(null);
  const [dataSourceInfo, setDataSourceInfo,] = useState<DataSourceType>({} as DataSourceType);

  const [totalRecords, setTotalRecords,] = useState<any>(null);
  const [loadingTotalRecords, setLoadingTotalRecords,] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (catalogId) {
      if (external) {
        catalogsServices.fetchThirdPartyCatalogByIdOrName(catalogId)
          .then((response: AxiosResponse<Interfaces.InputCatalogMetadata>) => {
            const _schema = helpers.addMissingFieldsToSchema(
              response.data.schema,
              '',
              (!(catalog && !catalog.includeAllSchemas))
            );
            setCatalog({
              ...response.data,
              schema: _schema,
            });
          })
          .catch((error) => {
            enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
          })
          .finally(() => setLoading(false));
      } else {
        catalogsServices.fetchCatalogById(catalogId)
          .then((response: AxiosResponse<Interfaces.InputCatalogMetadata>) => {
            const _schema = helpers.addMissingFieldsToSchema(
              response.data.schema,
              '',
              (!(catalog && !catalog.includeAllSchemas))
            );
            setCatalog({
              ...response.data,
              schema: _schema,
            });
            datasourceServices.fetchDataSource(response.data.dataSourceId)
              .then((response2: AxiosResponse<DataSourceType>) => {
                setDataSourceInfo(response2.data);
              });
          })
          .catch((error) => {
            enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
          })
          .finally(() => setLoading(false));
      }
    }
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Grid item xs={12}>
        <HeaderTabsView
          catalog={catalog}
          activeStep={activeStep}
          setActiveStep={(step) => setActiveStep(step)}
          schemaError={false}
          loadingSchema={false}
          inEditMode={!!catalogId}
        />
      </Grid>
      <div
        style={{
          display: 'flex',
          marginTop: 10,
          height: 'calc(100vh - 164px)',
        }}
      >
        {
          activeStep == 1 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  background: '#fff',
                  borderRadius: 10,
                  minHeight: '100%',
                  maxHeight: '100%',
                  padding: 4,
                  marginRight: 20,
                  flex: 1,
                  minWidth: 380,
                }}
              >
                <CatalogInfo catalog={catalog} />
                <div className={classes.treeViewGrid}>
                  <SchemaViewer
                    catalog={catalog}
                    setCatalog={(catalog) => setCatalog(catalog)}
                    loadingSchema={false}
                    schemaError={false}
                    isBucket={false}
                    selectedNode={selectedNode}
                    selectNode={(node) => setSelectedNode(node)}
                    readOnly={true}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: 3,
                  height: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflowY: 'auto',
                  }}
                >
                  <CatalogMetadata
                    catalog={catalog}
                    setCatalog={setCatalog}
                    selectedDataSource={dataSourceInfo}
                    dataSourceSchema={catalog.schema}
                    loadingSchema={false}
                    schemaError={false}
                    selectedNode={selectedNode}
                    handleSelectNode={(node) => setSelectedNode(node)}
                    totalRecords={totalRecords}
                    loadingTotalRecords={loadingTotalRecords}
                    fetchTotalRecords={(selectedNode?: Interfaces.Schema) => {
                      setLoadingTotalRecords(true);
                      let tmpSchema: Interfaces.Schema = JSON.parse(JSON.stringify(catalog.schema));
                      if (selectedNode) {
                        helpers.setNodeSelectionTree(tmpSchema, tmpSchema, false);
                        helpers.setNodeSelectionTree(tmpSchema, selectedNode, true);
                        helpers.setReverseSelection(tmpSchema, selectedNode);
                        tmpSchema = helpers.extractSelectedOnlyFields(tmpSchema);
                      } else {
                        if (!catalog.includeAllSchemas) {
                          tmpSchema = helpers.extractSelectedOnlyFields(tmpSchema);
                        }
                      }
                      const tmpCatalog = {
                        ...catalog,
                        schema: helpers.cleanseSchema(tmpSchema),
                      };
                      catalogsServices.fetchTotalRecords(tmpCatalog, catalog.dataSourceName)
                        .then((response: AxiosResponse<{ data: any }>) => {
                          const _totalRecords = response.data.data;
                          helpers.mapTotalRecordsToSchema(catalog.schema, _totalRecords);
                          setTotalRecords(Object.keys(_totalRecords).map((key) => ({
                            name: key,
                            totalRecords: _totalRecords[key].totalRecords,
                            sizeInBytes: _totalRecords[key].sizeInBytes,
                          })));
                        })
                        .catch((error) => enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', }))
                        .finally(() => {
                          setLoadingTotalRecords(false);
                        });
                    }}
                    readOnly={true}
                    external={catalog.dataSourceDescription === 'external'}
                  />
                </div>
              </div>
            </>
          )
        }
        {
          activeStep == 2 && (
            <DBDiagram catalog={catalog} />
          )
        }
      </div>
    </>
  );
};

export { ViewCatalog, };
