import React, { useState, } from 'react';
import {
  withRouter,
  NavLink,
} from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, } from '@material-ui/core/styles';
import {
  Box,
  CssBaseline,
  Link,
  Menu,
  MenuItem,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  AccountCircle,
  InfoOutlined,
} from '@material-ui/icons';
import AdaptiveScale from '../../static/logo.png';
import Rosetta from '../../assets/icons/RosettaDB_Logo.png';
import { Constants, } from '../../config';
import { NotificationsList, } from './Notifications';
import { SideBarElements, } from './SideBarElements';
import {
  useAuth,
  useSidebar,
} from '../../context';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 1px 29px 0px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin',], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    borderRight: 'none',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    borderRight: 'none',
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  title: {
    cursor: 'pointer',
    fontSize: '16pt',
    marginLeft: 20,
    color: 'black',
  },
  location: {
    fontSize: '15pt',
    fontWeight: 100,
    marginLeft: 13,
    marginRight: 10,
    color: 'black',
  },
  footerDiv: {
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    textAlign: 'center',
    paddingBottom: 10,
    width: 'inherit',
  },
  versionStyle: {
    marginLeft: 10,
    fontSize: 15,
    fontWeight: 100,
  },
  item: {
    textDecoration: 'none',
    color: theme.palette.grey[600],
  },
  selectedItem: {
    color: theme.palette.primary.main,
  },
  logo: {
    marginLeft: 10,
    width: 30,
    height: 30,
  },
  rosettaLogo: {
    marginLeft: 10,
    marginTop: -5,
    width: 140,
    height: 22,
    cursor: 'pointer',
  },
}));

const getPageTitle = (location: string) => {
  const routes: any = {
    '/': '',
    '/search': 'Metadata Search',
    '/catalogs': 'Catalogs',
    '/tags': 'Business Tags',
    '/schedules': 'Schedules',
    '/schema-evolution': 'Schema Evolution',
    '/data-sources': 'Data Sources',
    '/catalog/add': 'Select Connection',
    '/tag/add': 'Add Tag',
    '/data-source/jdbc': 'JDBC Connection',
    '/data-source/file': 'File Connection',
    '/data-source/azure': 'Azure Connection',
    '/data-source/ftp': 'FTP Connection',
    '/data-source/google-bucket': 'Google Bucket Connection',
    '/data-source/s3': 'S3 Connection',
    '/data-source/add': 'Select Data Source Type',
    '/login': 'Authentication',
    '/account': 'Account Details',
    '/about': 'About',
    '/data-transfer': 'Data Transfer',
    '/select-catalog': 'Select Catalog',
    '/admin': 'Admin Panel',
    '/git-config/add': 'Add Git Config',
    '/collections': 'Collections',
    '/collection/add': 'Add Collection',
    '/alerts': 'Alerts',
    '/alerts/add': 'Add Alert',
    '/alert/histories': 'Alert Histories',
  };

  switch (true) {
  case location.includes('/catalog/add/'): {
    return 'Add Catalog';
  }
  case location.includes('/search/details'): {
    return <Typography variant="h6" style={{ color: 'black',
      fontWeight: 'bold', }}>Metadata Search/{<Link style={{ cursor: 'pointer', }} variant='inherit' onClick={() => history.back()}> Results </Link>}/Details</Typography>;
  }
  case location.includes('/search/'): {
    return 'Metadata Search/Results';
  }
  case location.includes('/catalog/edit/'): {
    return 'Edit Catalog';
  }
  case location.includes('/schema-evolution/'): {
    return 'Schema Evolution';
  }
  case location.includes('/data-sources/sql/'): {
    return `Datasource: ${location.split('/').pop()}`;
  }
  case location.includes('/sql'): {
    return 'SQL Explorer';
  }
  case location.includes('/tag/edit/'): {
    return 'Edit Tag';
  }
  case location.includes('/data-source/edit/'): {
    return 'Edit Data Source';
  }
  case location.includes('/git-config/edit/'): {
    return 'Edit Git Config';
  }
  case location.includes('/alerts/add/'): {
    return 'Edit Alert';
  }
  case location.includes('/alerts/report/'): {
    return 'Alert Detail Report';
  }
  default: {
    return routes[location];
  }
  }
};

function Sidebar(props: any) {
  const classes = useStyles();
  const { isOpen, setIsOpen, } = useSidebar();
  const [anchorEl, setAnchorEl,] = useState<null | HTMLElement>(null);
  const { isAuthorized, logout, health, deploymentType, } = useAuth();
  const [readAlerts,] = React.useState(isAuthorized('ADAPTIVE_ALERT_READ'));

  return (
    <div className="sidebar">
      <CssBaseline/>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={() => setIsOpen(!isOpen)}
            edge="start"
            style={{ marginLeft: -19, }}
          >
            <MenuIcon />
          </IconButton>
          {
            deploymentType === 'cloud' ? (
              <img
                src={Rosetta}
                alt={'Adaptive Scale Logo'}
                onClick={() => {
                  props.history.push('/');
                }}
                className={classes.rosettaLogo}
              />
            ) : (
              <>
                <Typography
                  className={classes.title}
                  noWrap
                  onClick={() => {
                    props.history.push('/');
                  }}
                >
                  AdaptiveScale
                </Typography>
                <img
                  src={AdaptiveScale}
                  alt={'Adaptive Scale Logo'}
                  className={classes.logo}
                />
              </>
            )
          }
          <Typography className={classes.location}  variant="h6" style={{ color: '#6b6b6b', }}>
            {getPageTitle(props.history.location.pathname)}
          </Typography>
          <Box sx={{ flexGrow: 1, }}/>
          <Box sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}>
            <div>
              {readAlerts && (
                <NotificationsList readAlerts={readAlerts} />
              )}
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="primary"
              >
                <AccountCircle fontSize="large"/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorPosition={{
                  top: 63,
                  left: 2400,
                }}
                anchorReference="anchorPosition"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {
                  deploymentType === 'local' && (
                    <MenuItem
                      onClick={() => {
                        props.history.push('/about');
                        setAnchorEl(null);
                      }}
                    >
                      About
                    </MenuItem>
                  )
                }
                <MenuItem
                  onClick={() => {
                    props.history.push('/admin');
                    setAnchorEl(null);
                  }}
                >
                  Admin Panel
                </MenuItem>
                {
                  health.isAuthenticationEnabled && (
                    <MenuItem
                      onClick={() => {
                        logout();
                        props.history.push('/');
                      }}
                    >
                      Logout
                    </MenuItem>
                  )
                }
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
        <div className={classes.toolbar}/>
        <List style={{ paddingTop: 0, }}>
          {
            SideBarElements.filter((el) => isAuthorized(el.permission)).map((element) => (
              <NavLink key={element.text} to={element.path} activeClassName={classes.selectedItem} className={classes.item}>
                <ListItem button>
                  <ListItemIcon style={{ color: 'inherit', }}>
                    <element.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={element.text}
                  />
                </ListItem>
              </NavLink>
            ))
          }
        </List>
        {!isOpen ? (
          <div className={classes.footerDiv}>
            <Link href={Constants.rosettaDocumentationURL} target="_blank" underline="none">
              <InfoOutlined color="action"/>
            </Link>
          </div>
        ) : (
          <div className={classes.footerDiv}>
            <Typography style={{ fontWeight: 600, }}>AdaptiveScale
              <Link href={'/about'} className={classes.versionStyle}>
                {health.version || ''}
              </Link>
            </Typography>
            { health.license?.licenseType === 'Trial' &&
              (
                <Typography
                  style={{
                    fontWeight: 200,
                    fontSize: '13px',
                    color: '#5b5b5b',
                  }}
                >
                  {health.license?.licenseType}
                </Typography>
              )
            }
            <Link href={Constants.rosettaDocumentationURL} target="_blank" underline="none">
              Documentation
            </Link>
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default withRouter(Sidebar);
