import {
  Box,
  Paper,
  Tab,
} from '@material-ui/core';
import {
  TabContext,
  TabList,
  TabPanel,
} from '@material-ui/lab';
import React from 'react';
import TryNowText from './TryNowText/TryNowText';
import { Register, } from '../Register';
import { Login, } from '../Login';
import Rosetta from '../../assets/icons/RosettaDB_Logo.png';

const TryNow = () => {
  const [value, setValue,] = React.useState('2');
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box style={{
      minHeight: '100vh',
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      background: 'radial-gradient(circle, rgba(232,232,232,0.15730042016806722) 6%, rgba(218,218,218,0.33657212885154064) 41%, rgba(227,200,168,0.1741071428571429) 52%, rgba(255,145,13,0.14329481792717091) 82%)',
    }}>
      <Box display='flex' flexDirection='column' justifyContent='space-between' minHeight='100vh'>
        <Box>
          <Box textAlign='center' marginBottom={3} marginTop={3}>
            <img src={Rosetta} alt={'rosetta-logo'} width={300}/>
          </Box>
          <Paper elevation={2} style={{ padding: '10px',
            marginBottom: '20px', }}>
            <Box display='flex' width={800} height='100%' minHeight={500} >
              <Box flex='1' padding={2}>
                <TryNowText/>
              </Box>
              <Box flex='1' padding={2}>
                <TabContext value={value}>
                  <TabList onChange={handleChange} aria-label="simple tabs example" indicatorColor="primary" textColor="primary" centered>
                    <Tab label="Sign Up" value="2"/>
                    <Tab label="Login" value="1" />
                  </TabList>
                  <TabPanel value="2">
                    <Register />
                  </TabPanel>
                  <TabPanel value="1">
                    <Login />
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box textAlign='center'>
          <p>&copy; 2023 AdaptiveScale Inc. Rosettadb is a service of AdaptiveScale Inc.</p>
        </Box>
      </Box>

    </Box>
  );
};

export default TryNow;
