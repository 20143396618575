import React from 'react';
import { Grid, } from '@material-ui/core';
import { Interfaces, } from '../../../../../config';
import { DataSourceType, } from '../../helpers';
import { NodeMetadataDetails, } from './NodeMetadataDetails';
import { NodeMetadataTagsLabels, } from './NodeMetadataTagsLabels';
import { TotalRecordsChart, } from '../TotalRecordsChart';
import { NodeMetadataFields, } from './NodeMetadataFields';
import { Grants, } from './Grants';

const NodeMetadata = ({
  catalog,
  selectedDataSource,
  selectedNode,
  handleSelectNode,
  totalRecords,
  loadingTotalRecords,
  fetchTotalRecords,
  readOnly,
  setScrollToNode,
  setCatalog,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  selectedDataSource: DataSourceType;
  selectedNode: Interfaces.Schema;
  // eslint-disable-next-line no-unused-vars
  handleSelectNode: (node: Interfaces.Schema | null) => void;
  totalRecords: any;
  loadingTotalRecords: boolean;
  fetchTotalRecords: () => void;
  readOnly?: boolean;
  // eslint-disable-next-line no-unused-vars
  setScrollToNode?: (node: Interfaces.Schema) => void;
  // eslint-disable-next-line no-unused-vars
  setCatalog: (catalog: Interfaces.InputCatalogMetadata) => void;
}) => {
  const [showGrants, setShowGrants,] = React.useState<boolean>(false);

  return (
    <Grid container>
      <NodeMetadataDetails
        catalog={catalog}
        selectedDataSource={selectedDataSource}
        selectedNode={selectedNode}
        handleSelectNode={(node) => handleSelectNode(node)}
        totalRecords={totalRecords}
        loadingTotalRecords={loadingTotalRecords}
        fetchTotalRecords={fetchTotalRecords}
        readOnly={readOnly}
        setScrollToNode={setScrollToNode}
        showGrants={showGrants}
        setShowGrants={setShowGrants}
        setCatalog={setCatalog}
      />
      { showGrants && <Grants selectedNode={selectedNode} />}
      {
        selectedNode.sourceType !== 'database' && (
          <>
            <NodeMetadataTagsLabels
              catalog={catalog}
              selectedNode={selectedNode}
              handleSelectNode={(node) => handleSelectNode(node)}
              readOnly={readOnly}
            />
            <NodeMetadataFields
              catalog={catalog}
              selectedNode={selectedNode}
              handleSelectNode={(node) => handleSelectNode(node)}
              setScrollToNode={setScrollToNode}
            />
          </>
        )
      }
      {
        selectedDataSource.type === 'JDBC' && (
          selectedNode.sourceType === 'database'
          || selectedNode.sourceType === 'schema'
          || selectedNode.sourceType === 'table'
        ) && (
          <TotalRecordsChart
            totalRecords={totalRecords}
            fetchTotalRecords={fetchTotalRecords}
            loadingTotalRecords={loadingTotalRecords}
            tableName={selectedNode.sourceType === 'database' ? '' : selectedNode.properties.path}
          />
        )
      }
    </Grid>
  );
};

export { NodeMetadata, };
