import React, { useState, } from 'react';
import { Interfaces, } from '../../../config';
import {
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TableDesignerColumnTest, } from './TableDesignerColumnTest';
import { ColumnTypes, } from './ColumnTypes';
import { emptyTest, } from '../helpers';
import { v4, } from 'uuid';

interface Props {
  column: Interfaces.CollectionTableColumn;
  // eslint-disable-next-line no-unused-vars
  handleChange: (newColumn: Interfaces.CollectionTableColumn) => void;
  // eslint-disable-next-line no-unused-vars
  columnTypes?: Array<Interfaces.DBMSType> | null,
  handleSave: () => void;
  dbmsType: string,
  error: null | string;
}

const AddNewColumn = (props: Props) => {
  const {
    column,
    handleChange,
    columnTypes,
    dbmsType,
    handleSave,
    error,
  } = props;

  const [disabledColumnProperties, setDisabledColumnProperties,] = useState<Array<string>>([]);

  let booleanCount = 0;

  return (
    <Grid
      container
      style={{
        padding: '20px 0',
        margin: '40px 0px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      }}
    >
      <Grid item xs={2} style={{ position: 'relative', }}>
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={10}>
            <TextField
              name={'columnName'}
              label={'Name'}
              error={!!error}
              value={column.name}
              required
              variant="outlined"
              size='small'
              style={{
                paddingRight: 7,
              }}
              onChange={(e) => {
                handleChange({
                  ...column,
                  name: e.target.value,
                });
              }}
            />
            <Typography variant={'caption'} style={{ color: '#d24949', }}>
              {error && `* ${error}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container>
          <Grid item xs={12} >
            <Autocomplete
              options={columnTypes || []}
              getOptionLabel={(option) => option.type}
              id="controlled-demo"
              value={{ type: column.typeName || '', }}
              onChange={(e, value) => {
                handleChange({
                  ...column,
                  typeName: value?.type || '',
                });
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Type"
                  variant="outlined"
                  size='small'
                  required
                  style={{
                    paddingRight: 7,
                  }}
                  disabled={columnTypes === null}
                />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center', }}>
        <div style={{
          position: 'relative',
          height: '90%',
          display: 'flex',
        }}>
          {
            (!ColumnTypes[dbmsType] || !ColumnTypes[dbmsType][column.typeName.toLowerCase()])
            && column.typeName !== ''
            && (
              <span
                style={{
                  alignSelf: 'center',
                }}
              >
                Not supported type
              </span>
            )
          }
          {
            column.typeName !== '' && ColumnTypes[dbmsType] && ColumnTypes[dbmsType][column.typeName.toLowerCase()] && ColumnTypes[dbmsType][column.typeName.toLowerCase()].properties.map((columnProperty, index) => {
              if (index === 0) {
                booleanCount = 0;
              }
              if (columnProperty.isBoolean) {
                booleanCount += 1;
              }
              return (
                <Grid
                  key={columnProperty.name}
                  item
                  xs={3}
                  style={
                    columnProperty.isBoolean ? {
                      position: 'absolute',
                      right: (booleanCount - 1) * 65,
                    } : {}
                  }
                >
                  {
                    columnProperty.isBoolean ? (
                      <Tooltip title={columnProperty.displayName}>
                        <div style={{ maxHeight: 40, }}>
                          <Typography variant={'subtitle2'} style={{ fontSize: 10, }}>
                            {columnProperty.abbr}
                          </Typography>
                          <Checkbox
                            style={{ marginTop: -10, }}
                            disabled={disabledColumnProperties.includes(columnProperty.name)}
                            checked={!!column[columnProperty.name] && !disabledColumnProperties.includes(columnProperty.name)}
                            onChange={() => {
                              const _tmpColumn = { ...column, };
                              if (columnProperty.disables) {
                                if (!_tmpColumn[columnProperty.name]) {
                                  setDisabledColumnProperties([...disabledColumnProperties, ...columnProperty.disables,]);
                                  columnProperty.disables.map((prop) => _tmpColumn[prop] = false);
                                } else {
                                  setDisabledColumnProperties(disabledColumnProperties.filter((prop) => !columnProperty.disables?.includes(prop)));
                                }
                              }
                              handleChange({
                                ..._tmpColumn,
                                [columnProperty.name]: !_tmpColumn[columnProperty.name],
                              });
                            }}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox', }}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <TextField
                        value={column[columnProperty.name]}
                        type={columnProperty.isNumeric ? 'number' : 'text'}
                        onChange={(e) => {
                          handleChange({
                            ...column,
                            [columnProperty.name]: e.target.value,
                          });
                        }}
                        variant={'outlined'} size={'small'}
                        style={{ paddingRight: 10, }}
                        label={columnProperty.displayName}
                      />
                    )
                  }
                </Grid>
              );
            })
          }
        </div>
      </Grid>
      <Grid
        item
        xs={3}
      >
        <Button
          variant={'outlined'}
          color='primary'
          disabled={column.name === '' || column.typeName === ''}
          onClick={() => {
            if (column.tests && column.tests.assertion) {
              handleChange({
                ...column,
                tests: {
                  assertion: [...column.tests.assertion, {
                    ...emptyTest,
                    _uuid: v4(),
                  },],
                },
              });
            } else {
              handleChange({
                ...column,
                tests: {
                  assertion: [
                    {
                      ...emptyTest,
                      _uuid: v4(),
                    },
                  ],
                },
              });
            }
          }}
          style={{
            marginRight: 10,
            marginTop: 10,
            maxHeight: 30,
          }}
        >
          Add test
        </Button>
        <Button
          style={{
            marginTop: 10,
            maxHeight: 30,
            paddingBottom: 10,
            fontSize: 'x-large',
            justifyContent: 'center',
          }}
          onClick={() => {
            column.name !== '' && column.typeName !== '' && handleSave();
          }}
          variant={'contained'}
          color={'primary'}
          disableElevation
        >
          +
        </Button>
      </Grid>
      {
        column.tests && column.tests.assertion && column.tests.assertion.map((test) => (
          <TableDesignerColumnTest
            key={test._uuid}
            test={test}
            handleChange={(test) => {
              const tmpTestIndex =
                column.tests && column.tests.assertion
                  ? column.tests.assertion.findIndex((_test) => _test._uuid === test._uuid)
                  : -1;
              if (tmpTestIndex !== -1) {
                const tests = column.tests && column.tests.assertion ? [...column.tests.assertion,] : [];
                tests[tmpTestIndex] = test;
                handleChange({
                  ...column,
                  tests: { assertion: tests, },
                });
              }
            }}
            removeTest={(uuid) => {
              column.tests && handleChange({
                ...column,
                tests: {
                  assertion: column.tests?.assertion.filter((_test) => _test._uuid !== uuid),
                },
              });
            }}
          />
        ))
      }
    </Grid>
  );
};

export { AddNewColumn, };

