import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  GraphType,
  GraphConfigType,
  ChangeHistoryGraphType,
  PathBuilderType,
} from '../interfaces';
import {
  Circle,
  Curve,
  CollapseIcon,
  VerticalLine,
  HorizontalLine,
  ToolTipInfo,
  BasicTooltipInfo,
} from './Elements';
import useDraggableScroll from 'use-draggable-scroll';
import { useStyles, } from './styles';

const BuildPath = ({ graphConfig, data, }: PathBuilderType) => {
  const {
    initialX,
    initialY,
    sectionHeight,
    sectionWidth,
  } = graphConfig;

  let len = 0;
  let prevLen = 0;

  return (
    <g>
      {
        data.map((record, index) => {
          prevLen = len;
          len += record.expanded ? record.snapshots.length : 2;
          return record.snapshots.map((tt, idx) => {
            if (idx === 0 && index === 0) {
              return;
            }
            if (idx === 0) {
              return (
                <g key={index * idx}>
                  <Curve
                    x={initialX + (sectionWidth * prevLen)}
                    y={initialY + (sectionHeight * index)}
                    h={sectionHeight}
                    w={sectionWidth}
                  />
                  <HorizontalLine
                    x={initialX + (sectionWidth * prevLen)}
                    y={initialY + (sectionHeight * index)}
                    w={sectionWidth}
                  />
                  <VerticalLine
                    x={initialX + (sectionWidth * prevLen)}
                    y={initialY + (sectionHeight * index)}
                    h={sectionHeight}
                    w={sectionWidth}
                    dashed={3}
                  />
                </g>
              );
            } else {
              return (
                <g key={index * idx}>
                  {
                    record.expanded ? (
                      <HorizontalLine
                        x={initialX + (sectionWidth * (len - idx)) + 10}
                        y={initialY + (sectionHeight * (index + 1))}
                        w={sectionWidth}
                      />
                    ) : (
                      <HorizontalLine
                        x={initialX + (sectionWidth * (prevLen + 1)) + 10}
                        y={initialY + (sectionHeight * (index + 1))}
                        w={sectionWidth}
                      />
                    )
                  }
                </g>
              );
            }
          });
        })
      }
    </g>
  );
};

const RenderSnapshots = ({ graphConfig, data, expandSnapshots, collapseRecords, handleRecordSelect, handleCompareSelect, } : GraphType) => {
  const {
    initialX,
    initialY,
    sectionHeight,
    sectionWidth,
    circleRadius,
  } = graphConfig;

  let len = 0;
  let prevLen = 0;
  let collapsedLimit = 4;

  return (
    <g>
      {
        data.map((runRecords, index) => {
          prevLen = len;
          len += runRecords.expanded ? runRecords.snapshots.length : 2;
          return runRecords.snapshots.map((record, idx) => {
            if (idx === 0 && index === 0) {
              return (
                <g key={index * idx}>
                  <Tooltip
                    arrow
                    placement={'top'}
                    title={<BasicTooltipInfo record={record} />}
                  >
                    <g onClick={() => handleRecordSelect(record)}>
                      <text
                        x={initialX + sectionWidth - 8}
                        y={initialY + sectionHeight - 30}
                        fill={'#545353'}
                      >
                        v{record.version}
                      </text>
                      <Circle
                        x={initialX + sectionWidth}
                        y={initialY + sectionHeight}
                        r={circleRadius}
                        className={`marker${index % 7}`}
                      />
                    </g>
                  </Tooltip>
                  {
                    runRecords.snapshots.length > 2 && runRecords.expanded && (
                      <>
                        <CollapseIcon
                          x={initialX + (sectionWidth * (prevLen + 2))}
                          y={initialY + (sectionHeight * (index + 1))}
                          collapseRecords={() => collapseRecords && collapseRecords(index)}
                        />
                        <CollapseIcon
                          x={initialX + (sectionWidth * len)}
                          y={initialY + (sectionHeight * (index + 1))}
                          collapseRecords={() => collapseRecords && collapseRecords(index)}
                        />
                      </>
                    )
                  }
                </g>
              );
            }
            if (idx === 0) {
              return (
                <g key={index * idx}>
                  <Tooltip
                    arrow
                    placement={'top'}
                    title={
                      runRecords.changes
                        ? <ToolTipInfo
                          totalFields={record.fields.length}
                          changes={runRecords.changes}
                          index={index}
                        />
                        : ''
                    }
                  >
                    <g onClick={() => handleCompareSelect(record, data[index - 1].snapshots[0])}>
                      <Circle
                        x={initialX + (sectionWidth * (prevLen + 1))}
                        y={initialY + (sectionHeight * index)}
                        r={circleRadius}
                        className={`marker-end`}
                      />
                    </g>
                  </Tooltip>
                  <Tooltip
                    arrow
                    placement={'top'}
                    title={<BasicTooltipInfo record={record} />}
                  >
                    <g onClick={() => handleRecordSelect(record)}>
                      <Circle
                        x={initialX + (sectionWidth * (prevLen + 1))}
                        y={initialY + (sectionHeight * (index + 1))}
                        r={circleRadius}
                        className={`marker${index % 7}`}
                      />
                      <text
                        x={initialX + (sectionWidth * (prevLen + 1)) - 14}
                        y={initialY + (sectionHeight * (index + 1)) - 30}
                        fill={'#545353'}
                      >
                        v{record.version}
                      </text>
                    </g>
                  </Tooltip>
                  {
                    runRecords.snapshots.length > 2 && runRecords.expanded && (
                      <>
                        <CollapseIcon
                          x={initialX + (sectionWidth * (prevLen + 2))}
                          y={initialY + (sectionHeight * (index + 1))}
                          collapseRecords={() => collapseRecords && collapseRecords(index)}
                        />
                        <CollapseIcon
                          x={initialX + (sectionWidth * len)}
                          y={initialY + (sectionHeight * (index + 1))}
                          collapseRecords={() => collapseRecords && collapseRecords(index)}
                        />
                      </>
                    )
                  }
                </g>
              );
            } else {
              return (
                <g key={index * idx}>
                  {
                    index === data.length - 1
                    && idx === runRecords.snapshots.length - 1
                    && (runRecords.expanded || runRecords.snapshots.length < 3)
                    && (
                      <text
                        x={initialX + (sectionWidth * len) - 14}
                        y={initialY + (sectionHeight * (index + 1)) - 30}
                        fill={'#545353'}
                      >
                        latest
                      </text>
                    )
                  }
                  {
                    runRecords.expanded ? (
                      <Tooltip
                        arrow
                        placement={'top'}
                        title={<BasicTooltipInfo record={record} />}
                      >
                        <g
                          onClick={() => handleRecordSelect(record)}
                        >
                          <Circle
                            x={initialX + (sectionWidth * (prevLen + idx + 1))}
                            y={initialY + (sectionHeight * (index + 1))}
                            r={circleRadius}
                            className={`marker${index % 7}`}
                          />
                        </g>
                      </Tooltip>
                    ) : idx < collapsedLimit && (
                      <Tooltip
                        title={
                          runRecords.expanded
                            ? ''
                            : (
                              <>
                                <p>{runRecords.snapshots.length - 1} more runs for v{record.version}</p>
                                <p>Click to expand all</p>
                              </>
                            )
                        }
                      >
                        <g onClick={() => {
                          expandSnapshots && expandSnapshots(index);
                        }}>
                          <Circle
                            x={initialX + (sectionWidth * len) + (idx * 5)}
                            y={initialY + (sectionHeight * (index + 1))}
                            r={circleRadius}
                            className={`marker${index % 7}`}
                          />
                        </g>
                      </Tooltip>
                    )
                  }
                </g>
              );
            }
          });
        })
      }
    </g>
  );
};

const ChangeHistoryGraph = ({
  groupedSnapshots,
  handleRecordSelect,
  handleCompareSelect,
  handleExpand,
} : ChangeHistoryGraphType ) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const { onMouseDown, } = useDraggableScroll(ref);
  const [scrollElements, setScrollElements,] = useState<{
    el1: HTMLElement | null,
    el2: HTMLElement | null,
    el3: HTMLElement | null,
  }>({
    el1: null,
    el2: null,
    el3: null,
  });

  const length = groupedSnapshots.length > 13 ? groupedSnapshots.length : 13;
  const config: GraphConfigType = {
    initialX: -2,
    initialY: (length + 1.5) * 70,
    sectionHeight: -70,
    sectionWidth: 140,
    circleRadius: 11,
  };

  const getExpanded = () => {
    let x = 0;
    groupedSnapshots.forEach((rr) => {
      if (rr.expanded) {
        x += rr.snapshots.length;
      } else {
        x += 2;
      }
    });
    return x;
  };

  useEffect(() => {
    setScrollElements({
      el1: document.getElementById('runrecordsgraph'),
      el2: document.getElementById('changehistorygraph'),
      el3: document.getElementById('timelinegraph'),
    });
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.changeHistoryContainer}>
        <Typography variant={'subtitle2'} className={classes.changeHistoryLabel}>
          Change history
        </Typography>
        <div
          id={'changehistorygraph'}
          ref={ref}
          className={classes.changeHistorySvg}
          onMouseDown={onMouseDown}
          onScroll={() => {
            if (scrollElements.el1 && scrollElements.el2 && scrollElements.el3) {
              scrollElements.el1.scrollLeft = scrollElements.el2.scrollLeft;
              scrollElements.el3.scrollLeft = scrollElements.el2.scrollLeft;
            }
          }}
        >
          {
            groupedSnapshots.length > 0 ? (
              <div>
                <svg
                  id="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  className="change-history-graph"
                  style={{
                    minWidth: `${(config.sectionWidth * getExpanded() + 64) * 0.0625}rem`,
                    maxWidth: '100vw',
                    height: `${config.sectionHeight * length * 0.07 * -1}rem`,
                    maxHeight: `${config.sectionHeight * length * 0.07 * -1}rem`,
                    overflowX: 'scroll',
                  }}
                >
                  <BuildPath
                    data={groupedSnapshots}
                    graphConfig={config}
                  />
                  <RenderSnapshots
                    data={groupedSnapshots}
                    graphConfig={config}
                    expandSnapshots={(index: number) => {
                      const tempR = [...groupedSnapshots,];
                      tempR[index].expanded = true;
                      handleExpand(tempR);
                    }}
                    handleRecordSelect={handleRecordSelect}
                    collapseRecords={(index: number) => {
                      const tempR = [...groupedSnapshots,];
                      tempR[index].expanded = false;
                      handleExpand(tempR);
                    }}
                    handleCompareSelect={handleCompareSelect}
                  />
                </svg>
              </div>
            ) : (
              <Typography
                variant={'h4'}
                className={classes.noDataMessage}
              >
                No recorded runs during the specified period
              </Typography>
            )
          }
        </div>
      </Grid>
    </Grid>
  );
};

export { ChangeHistoryGraph, };
