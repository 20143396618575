import {
  Constants,
  Interfaces,
} from '../config';
import { v4 as uuidv4, } from 'uuid';
import _ from 'lodash';
import { helpersServices, } from '../services';
import * as interfaces from '../config/interfaces';

export async function handleErrors(response: any) {
  if (!response.ok) {
    if (response.body) {
      const text = await response.text();
      let message = 'Unknown error';
      if (response.status === 401) {
        message = 'Authentication failed';
      }
      try {
        const json = JSON.parse(text);
        message = json.msg || json.message;
      } catch (err) {
        console.debug('errr', err);
      }
      throw Error(message);

    } else {
      throw Error(response.statusText);
    }
  }
  return response;
}

export const setApiIsOnline = () => ({
  type: Constants.actions.helpers.SET_API_IS_ONLINE,
});

export const setApiISOffline = () => ({
  type: Constants.actions.helpers.SET_API_IS_OFFLINE,
});

export const checkApiStatus = () => {
  return async (dispatch: any) => {
    try {
      await fetch(Constants.api.endpoints.status);
      dispatch(setApiIsOnline());
    } catch {
      dispatch(setApiISOffline());
    }
  };
};

export const changeActiveStep = (activeStep: number) => ({
  type: Constants.actions.catalog.CHANGE_STEP,
  payload: {
    activeStep,
  },
});

export const showTimeline = (showTimeline: boolean) => {
  return {
    type: Constants.actions.helpers.SHOW_TIMELINE,
    showTimeline,
  };
};

export function addUid(data: any) {
  data['_uid'] = uuidv4();
  if (data.fields) {
    for (let index in data.fields) {
      addUid(data.fields[index]);
    }
  }
  return data;
}

export const fetchApiState = () => {
  const beginFetchSupportedDrivers = () => ({
    type: Constants.actions.dataSource.BEGIN_FETCH_SUPPORTED_DRIVERS,
  });

  const fetchSupportedDriversSuccess = (data: Interfaces.JDBCDriver[]) => ({
    type: Constants.actions.dataSource.SET_SUPPORTED_DRIVERS,
    payload: { data, },
  });

  const fetchCatalogsBegin = () => ({
    type: Constants.actions.catalog.FETCH_CATALOGS_BEGIN,
  });

  const fetchCatalogsSuccess = (data: interfaces.InputCatalogMetadata) => {
    return {
      type: Constants.actions.catalog.FETCH_CATALOGS_SUCCESS,
      payload: { data, },
    };
  };

  const fetchSchemaTypesSuccess = (data: Array<string>) => ({
    type: Constants.actions.helpers.FETCH_SCHEMA_TYPES_SUCCESS,
    payload: { data, },
  });

  const setDataSources = (data: Interfaces.BaseDataSource[]) => ({
    type: Constants.actions.dataSource.SET_DATA_SOURCES,
    payload: { data, },
  });

  return async (dispatch: any) => {
    dispatch(beginFetchSupportedDrivers());
    dispatch(fetchCatalogsBegin());

    helpersServices.fetchSupportedDrivers()
      .then((response) => {
        dispatch(fetchSupportedDriversSuccess(response.data));
      });

    helpersServices.fetchDataSources()
      .then((response) => {
        dispatch(setDataSources(response.data));
      });

    helpersServices.fetchCatalogs()
      .then((response) => {
        dispatch(fetchCatalogsSuccess(response.data));
      });

    helpersServices.fetchSchemaTypes()
      .then((response) => {
        dispatch(fetchSchemaTypesSuccess(response.data));
      });
  };
};

export const addMissingFieldsToSchema = (data: Interfaces.Schema, path: string, isSelected: boolean) => {
  data._path = path;
  data._uid = uuidv4();
  data._selected = isSelected;
  const separator = data._path !== '' ? '.' : '';
  if (data.fields) {
    if (data.fields) {
      for (let index in data.fields) {
        addMissingFieldsToSchema(data.fields[index], path + separator + 'fields[' + index + ']', isSelected);
      }
    }
  }
  return data;
};

export const findPath = (schema: Interfaces.Schema, path: Array<string>, selectedTreeNodes: Array<string> = []): any => {
  if (path === undefined) {
    return {
      selectedTreeNodes: [schema._uid,],
      selectedSchema: schema,
    };
  }
  if (schema.fields === undefined) {
    return {
      selectedTreeNodes,
      selectedSchema: schema,
    };
  }
  if (schema.name === path[0]) {
    path.splice(0, 1);
    const field: Interfaces.Schema = schema.fields.find((f: Interfaces.Schema) => f.name === path[0]) as Interfaces.Schema;
    if (field === undefined) {
      return {
        selectedTreeNodes,
        selectedSchema: schema,
      };
    }
    if (field._uid) {
      selectedTreeNodes.push(field._uid);
    }
    return findPath(field, path, selectedTreeNodes);
  }
};

export const cleanseSchema: any = (schema: Interfaces.Schema) => {
  const hasFields = Object.keys(schema).indexOf('fields') > -1;
  delete schema._path;
  delete schema._uid;
  delete schema._selected;
  delete schema.__typename;
  if (hasFields) {
    return {
      ...schema,
      fields: schema.fields.map((field) => cleanseSchema(field)),
    };
  }
  return {
    ...schema,
  };
};

export const mapNewSchemaToExistingSchema = (newSchema: Interfaces.Schema, existingSchema: Interfaces.Schema) => {
  // if(Object.keys(existingSchema).indexOf('_selected')){
  console.debug('schema compare', newSchema, existingSchema);
  // _.set(newSchema, '_selected', _.get(existingSchema,'_selected', true));
  _.set(newSchema, '_selected', true);
  _.set(newSchema, 'labels', _.get(existingSchema, 'labels', []));
  _.set(newSchema, 'tags', _.get(existingSchema, 'tags', []));
  _.set(newSchema, 'properties.description', _.get(existingSchema, 'properties.description', ''));
  // }
  if (Object.keys(newSchema).indexOf('fields') > -1) {
    newSchema.fields.forEach((newField) => {
      if (Object.keys(existingSchema).indexOf('fields') > -1) {
        existingSchema.fields.forEach((existingField) => {
          console.debug('comparing', newField, existingField);
          if (newField.name === existingField.name) {
            mapNewSchemaToExistingSchema(newField, existingField);
          }
        });
      }
    });
  }
  // _.set(newCatalog, '_selected', _.get(existingSchema,'_selected', true));
  // _.set(newCatalog, 'autoSelectAllFields', _.get(existingCatalog,'autoSelectAllFields', true));
  // _.set(newCatalog, 'includeAllSchemas', _.get(existingCatalog,'includeAllSchemas', true));
};

export const getAvroSchema: any = (schema: any) => {
  const hasFields = Object.keys(schema).indexOf('fields') > -1;
  delete schema.properties;
  delete schema.tagRules;
  delete schema.labels;
  delete schema.sourceType;
  delete schema.tags;

  if (hasFields) {
    return {
      ...schema,
      fields: schema.fields.map((field: any) => getAvroSchema(field)),
    };
  }
  return {
    ...schema,
  };
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.message || error.message;
};

export const setChildDirectories = (directory: any, path: string, data: any) => {
  if (directory.path === path) {
    directory.directories = data;
    return;
  }
  Array.isArray(directory.directories) && directory.directories.map((directory: any) => setChildDirectories(directory, path, data));
};

export const sha256 = async (message: string) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

export const removeAdditionalFieldsFromCollection = (collection: Interfaces.Collection) => {
  collection.database.tables.map((table) => {
    delete table._new;
    delete table._errors;
    delete table._uuid;
    table.columns.forEach((column) => {
      delete column._errors;
      delete column._uuid;
      column.tests?.assertion?.forEach((test) => {
        delete test._uuid;
      });
    });
  });
};

export const setNodeSelectionTree = (tree: Interfaces.Schema, treeNode: Interfaces.Schema, state: boolean) => {
  if (treeNode._path + '._selected' !== '._selected') {
    _.set(tree, treeNode._path + '._selected', state);
  } else {
    tree._selected = state;
  }
  if (treeNode?.fields?.length > 0) {
    treeNode.fields.forEach((field) => setNodeSelectionTree(tree, field, state));
  }
};

export const setReverseSelection = (tree: Interfaces.Schema, treeNode: Interfaces.Schema) => {
  if (treeNode._path) {
    const _path = treeNode._path.split('.');
    if (_path.length >= 1) {
      let newPath = _path[0];
      _path?.forEach((section, index) => {
        _.set(tree, `${newPath}._selected`, true);
        newPath = `${newPath}.${_path[index + 1]}`;
      });
      tree._selected = true;
    }
  }
};

export const removeUnselectedFields = (schema: Interfaces.Schema, field: Interfaces.Schema, index = 0) => {
  if (index === 0) {
    setNodeSelectionTree(schema, field, true);
    setReverseSelection(schema, field);
  }
  schema?.fields?.forEach((_field) => {
    if (_field) {
      if (!_field._selected) {
        schema.fields = schema.fields.filter((__field) => __field._selected);
      }
      removeUnselectedFields(_field, field, -1);
    }
  });
};
