import React from 'react';
import Style from './style';
import logo from './logo';
import { withRouter, } from 'react-router';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps, } from 'react-router-dom';

interface ItemDetails {
  img: string;
  name: string;
  route: string;
}

interface Props extends RouteComponentProps {
  itemDetails: ItemDetails;
}

function CardView(props: Props) {
  const classes = Style();
  const { itemDetails, } = props;

  return (
    <Grid container>
      <Grid xs={12} md={12} item className={classes.control}>
        <Card className={classes.card} onClick={() => props.history.push(itemDetails.route)}>
          <CardActionArea>
            <Box className={classes.boxContent}>
              <img
                alt={'Data source icon'}
                className={
                  (itemDetails.img === 'file' ? (
                    classes.fileMedia
                  ) : (
                    classes.media
                  ))
                }
                src={(logo as any).images[`${itemDetails.img}`]}
              />
            </Box>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                {itemDetails.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}

export default withRouter(CardView);
