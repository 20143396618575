import React, {
  Fragment,
  useState,
} from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert, } from '@material-ui/icons';
import { useHistory, } from 'react-router-dom';
import { Interfaces, } from '../../../../config';

interface Props {
  dataSource: Interfaces.DataSourceType;
}

function DataSourceMenu(props: Props) {
  const { dataSource, } = props;
  const [anchorEl, setAnchorEl,] = useState<null | HTMLElement>(null);
  const history = useHistory();

  return (
    <Fragment>
      <IconButton
        style={{
          padding: '3px 3px',
          marginRight: '10px',
        }}
        aria-label="edit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          value={dataSource.name}
          style={{
            fontSize: '0.9rem',
            paddingRight: 40,
            paddingTop: 3,
            paddingBottom: 3,
          }}
          onClick={() => history.push(`/data-sources/${dataSource.name}/sql`)}
          disabled={dataSource.type !== 'JDBC'}
        >
          SQL
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

export { DataSourceMenu, };
