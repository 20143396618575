import React from 'react';
import './ChangeHistoryGraph.scss';
import { Tooltip, } from '@material-ui/core';
import {
  CurveType,
  CircleType,
  VerticalLineType,
  HorizontalLineType,
  BarType,
  SchemaChange,
  Point,
} from '../interfaces';
import { Interfaces, } from '../../../config';
import './TimeLineGraph.scss';
import moment from 'moment/moment';

const Curve = ({ x, y, w, h, }: CurveType) => {
  const points = {
    M: {
      x: x,
      y: y,
    },
    C: {
      x: x + ((110/w) * 100),
      y: y,
    },
    B: {
      x: x + ((50/w) * 100),
      y: y + h,
    },
    D: {
      x: x + w,
      y: y + h,
    },
  };
  return (
    <path
      d={`
          M ${points.M.x} ${points.M.y}
          C ${points.C.x} ${points.C.y},
          ${points.B.x} ${points.B.y},
          ${points.D.x} ${points.D.y}
      `}
      stroke={'#b6b4b4'}
      strokeWidth={2}
      fill={'transparent'}
      className={'line'}
    />
  );
};

const HorizontalLine = ({ x, y, w, }: HorizontalLineType) => {
  return (
    <line
      x1={x}
      x2={x + w}
      y1={y}
      y2={y}
      stroke={'#b6b4b4'}
      strokeWidth={2}
      className={'line'}
    />
  );
};

const VerticalLine = ({ x, y, w, h, color, dashed, }: VerticalLineType) => {
  return (
    <line
      x1={x + w}
      x2={x + w}
      y1={y - 15}
      y2={y + h + 14}
      stroke={color ? color : '#FC7A28FF'}
      strokeWidth={2}
      strokeDasharray={dashed ? dashed : 0}
      className={'line'}
    />
  );
};

const Circle = ({ x, y, r, className, }: CircleType) => (
  <circle
    cx={x}
    cy={y}
    r={r + 7}
    className={className}
  />
);

const CollapseIcon = ({
  x,
  y,
  collapseRecords,
} : {
  x: number;
  y: number;
  collapseRecords: () => void;
}) => (
  <Tooltip arrow placement={'bottom'} title={'Collapse'}>
    <g onClick={() => collapseRecords && collapseRecords()}>
      <VerticalLine w={4} h={10} x={x - 4} y={y + 17} color={'#b6b4b4'}/>
      <circle
        cx={x}
        cy={y + 50}
        r={12}
        className={'collapse-icon'}
      />
      <text
        x={x - 8}
        y={y + 54}
        className={'collapse-icon-text'}
      >
        {'><'}
      </text>
    </g>
  </Tooltip>
);

const Bar = ({ x, y, h, w, className, } : BarType) => {
  return (
    <rect
      x={x}
      y={y}
      width={w}
      height={h}
      className={className}
    />
  );
};

const BasicTooltipInfo = ({ record, }: { record: Interfaces.Schema }) => {
  return (
    <>
      <p>RunID: {record.properties?.runId}</p>
      <p>Run Time: {moment(record.properties?.createdDate).format('DD MMM YYYY hh:mm')}</p>
      <p>Click to see the details</p>
    </>
  );
};


const ToolTipInfo = ({
  totalFields,
  changes,
  index,
} : {
  totalFields: number;
  changes: SchemaChange;
  index: number;
}) => {
  return (
    <>
      <p>Total fields: {totalFields}</p>
      <p>Changes:</p>
      {
        changes.addedFields.length > 0 && (
          <p>{`\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0Added fields: ${changes.addedFields.length}`}</p>
        )
      }
      {
        changes.changedFields.length > 0 && (
          <p>{`\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0Changed fields: ${changes.changedFields.length}`}</p>
        )
      }
      {
        changes.removedFields.length > 0 && (
          <p>{`\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0Removed fields: ${changes.removedFields.length}`}</p>
        )
      }
      {
        changes.tagsChange > 0 && (
          <p>{`\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0Added tags: ${changes.tagsChange}`}</p>
        )
      }
      {
        changes.tagsChange < 0 && (
          <p>{`\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0Removed tags: ${changes.tagsChange * -1}`}</p>
        )
      }
      {
        changes.labelsChange > 0 && (
          <p>{`\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0Added labels: ${changes.labelsChange}`}</p>
        )
      }
      {
        changes.labelsChange < 0 && (
          <p>{`\xa0\xa0\xa0\xa0-\xa0\xa0\xa0\xa0Removed labels: ${changes.labelsChange * -1}`}</p>
        )
      }
      <br />
      <p>{'Click to see detailed view of what changed'}</p>
      <p>{`from v1.${index - 1} to v1.${index}`}</p>
    </>
  );
};

const TimeLineFilterIcon = ({
  point: { x, y, },
  w,
  textXPos,
  text,
  tooltipText,
  selected,
  onClick,
} : {
  point: Point;
  w?: number;
  textXPos?: number;
  text: 'M' | 'W' | 'Y' | 'All';
  tooltipText: string;
  selected: boolean
  onClick: () => void;
}) => {
  return (
    <Tooltip placement={'top'} title={<p>{tooltipText}</p>}>
      <g onClick={onClick}>
        <rect
          x={x - 2}
          y={y - 9}
          width={w ? w : 20}
          height={20}
          className={`timelineIcon ${selected ? 'selectedIcon' : ''}`}
        />
        <text
          x={textXPos ? textXPos : x + 1}
          y={y + 1}
          className={`timeLineIconText ${selected ? 'selectedText' : ''}`}
        >
          {text}
        </text>
      </g>
    </Tooltip>
  );
};

export {
  Curve,
  HorizontalLine,
  VerticalLine,
  Circle,
  Bar,
  CollapseIcon,
  BasicTooltipInfo,
  ToolTipInfo,
  TimeLineFilterIcon,
};
