import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { AxiosResponse, } from 'axios';
import { NoDataV2, } from '../NoDataV2';
import LoadingComponent from '../Loading';
import {
  catalogsServices,
  runDetailsServices,
} from '../../services';
import {
  calculateChanges,
  filterByTime,
  getErrorMessage,
  prepareSnapshots,
} from './helpers';
import { Interfaces, } from '../../config';
import { Header, } from './Header';
import {
  GroupedSnapshotsType,
  SchemaChange,
} from './interfaces';
import { SchemaViewer, } from './Schema';
import moment from 'moment/moment';
import {
  ChangeHistoryGraph,
  TimeLineGraph,
  TotalRecordsGraph,
} from './Graphs';
import { CatalogSelector, } from './CatalogSelector';
import { useAuth, } from '../../context';
import { helpers, } from '../../utils';
import { useSnackbar, } from 'notistack';

interface Params {
  catalogId: string;
}

const SchemaEvolution = () => {
  const history = useHistory();
  const { enqueueSnackbar, } = useSnackbar();
  const { catalogId, } = useParams<Params>();
  const [catalogs, setCatalogs,] = useState<Interfaces.InputCatalogMetadata[]>([]);
  const [loadingCatalogs, setLoadingCatalogs,] = useState(true);
  const [snapshots, setSnapshots,] = useState<Array<GroupedSnapshotsType>>([]);
  const [loading, setLoading,] = useState(false);
  const [error, setError,] = useState<string | null>(null);
  const [originalSchema, setOriginalSchema,] = useState<Interfaces.Schema | null>(null);
  const [selectedSchema, setSelectedSchema,] = useState<Interfaces.Schema | null>(null);
  const [snapshotsToCompare, setSnapshotsToCompare,] =
    useState<{snapshotA: Interfaces.Schema, snapshotB: Interfaces.Schema, changes: SchemaChange} | undefined>(undefined);
  const [filteredData, setFilteredData,] = useState<Array<GroupedSnapshotsType>>([]);
  const [filterType, setFilterType,] = useState<'week' | 'month' | 'year' | 'all'>('month');

  const { isAuthorized, } = useAuth();
  const [readAccess,] = useState(isAuthorized('ADAPTIVE_EV_READ'));

  const fetchCatalogs = () => {
    setLoadingCatalogs(true);
    catalogsServices.fetchCatalogsWithDataSources()
      .then((response: AxiosResponse<Array<Interfaces.InputCatalogMetadata>>) => {
        setCatalogs(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingCatalogs(false);
      });
  };

  useEffect(() => {
    fetchCatalogs();
  }, []);


  useEffect(() => {
    setError(null);
    setSnapshots([]);
    if (catalogId) {
      const catalog = catalogs.find((catalog) => catalog.id === catalogId);
      if (!catalog) {
        setError(`Catalog with id='${catalogId}' not found`);
        setLoading(false);
        return;
      }
      if (!catalog.lastRunId) {
        setError(`Catalog '${catalog.name} has not been run yet!`);
        setLoading(false);
        return;
      }
      setLoading(true);
      runDetailsServices.fetchRunTimeline(catalogId)
        .then((snapshotsRes: AxiosResponse<Array<Interfaces.Schema>>) => {
          const res = prepareSnapshots(snapshotsRes.data, (_schema) => setOriginalSchema(_schema));
          setSnapshots(res);
          setFilterType('month');
          setFilteredData(filterByTime(res, 'month'));
        })
        .catch((error) => setError(getErrorMessage(error)))
        .finally(() => setLoading(false));
    }
  }, [catalogId,]);




  useMemo(() => {
    if (filterType !== 'all') {
      setFilteredData(filterByTime(snapshots, filterType));
    } else {
      setFilteredData(snapshots);
    }
  }, [filterType,]);

  if (loadingCatalogs) {
    return <LoadingComponent />;
  }

  if (!readAccess) {
    return <Typography variant="h4">Not authorized to view schema evolution</Typography>;
  }

  return (
    <Grid container>
      {
        catalogId ? (
          <Grid item xs={12}>
            <Header
              selectedCatalog={catalogId}
              catalogs={catalogs}
              handleSelect={(catalogId) => history.push(`/schema-evolution/${catalogId}`)}
            />
            <Divider style={{ marginBottom: 10, }}/>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <CatalogSelector
              catalogs={catalogs}
              handleSelect={(catalogId) => history.push(`/schema-evolution/${catalogId}`)}
            />
          </Grid>
        )
      }
      {
        loading
        && <LoadingComponent size={60} marginTop={250} message={'Preparing snapshots'} />
      }
      {
        error
        && <NoDataV2 message={error}/>
      }
      {
        !loading
        && !error
        && snapshots.length > 0
        && (
          <>
            <Grid item xs={12} style={{ maxWidth: '100vw', }}>
              <ChangeHistoryGraph
                groupedSnapshots={filteredData}
                handleRecordSelect={(record) => setSelectedSchema(record)}
                handleExpand={(snapshots) => setSnapshots(snapshots)}
                handleCompareSelect={(snapshotA, snapshotB) => {
                  if (originalSchema) {
                    const tempA = JSON.parse(JSON.stringify({ ...snapshotA, })) as Interfaces.Schema;
                    const tempB = JSON.parse(JSON.stringify({ ...snapshotB, })) as Interfaces.Schema;

                    const changes = calculateChanges(tempA, tempB, originalSchema);
                    setSnapshotsToCompare({
                      snapshotA: tempA,
                      snapshotB: tempB,
                      changes,
                    });
                    setSelectedSchema(tempB);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ maxWidth: '100vw', }}>
              <TimeLineGraph
                groupedSnapshots={filteredData}
                selectedIcon={filterType}
                handleFilterSelect={(filter) => setFilterType(filter)}
              />
            </Grid>
            <Grid item xs={12} style={{ maxWidth: '100vw', }}>
              <TotalRecordsGraph
                groupedSnapshots={filteredData}
              />
            </Grid>
            {
              selectedSchema
              && (
                <SchemaViewer
                  open={!!selectedSchema}
                  snapshots={filteredData}
                  schema={selectedSchema}
                  schemaToCompare={snapshotsToCompare}
                  handleClose={() => {
                    setSelectedSchema(null);
                    setSnapshotsToCompare(undefined);
                  }}
                  handleCompare={(index: number) => {
                    if (originalSchema) {
                      let snapshotA = JSON.parse(JSON.stringify({ ...selectedSchema, })) as Interfaces.Schema;
                      let snapshotB = JSON.parse(JSON.stringify({ ...filteredData[index].snapshots[0], })) as Interfaces.Schema;
                      if (moment(snapshotB.properties?.createdDate).isAfter(snapshotA.properties?.createdDate)) {
                        let c = snapshotA;
                        snapshotA = snapshotB;
                        snapshotB = c;
                      }
                      const changes = calculateChanges(snapshotA, snapshotB, originalSchema);
                      setSnapshotsToCompare({
                        snapshotA: snapshotA,
                        snapshotB: snapshotB,
                        changes,
                      });
                    }
                  }}
                />
              )
            }
          </>
        )
      }
    </Grid>
  );
};

export { SchemaEvolution, };
