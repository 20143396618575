import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '10px',
    },
    img: {
      width: '20%',
      marginBottom: '2rem',
      marginTop: '35px',
    },
    input: {
      display: 'none',
    },
    inline: {
      margin: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paddingBox: {
      paddingBottom: '15px',
    },
    button: {
      margin: theme.spacing(1),
    },
    warning: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  })
);

export default useStyles;
