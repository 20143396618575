import { makeStyles, } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => (
  {
    marginBottom: {
      marginBottom: 15,
    },
    containerWidth: {
      flexGrow: 1,
    },
    saveButton: {
      marginTop: 5,
      float: 'right',
    },
  })
);
