import React, {
  useEffect,
  useState,
} from 'react';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../helpers';
import { dataServices, } from '../../../../services';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AxiosResponse, } from 'axios';
import LoadingComponent from '../../../Loading';
import { Interfaces, } from '../../../../config';
import _ from 'lodash';

const SampleDataDialog = ({
  dataSourceName,
  isOpen,
  handleClose,
  selectedNode,
}: {
  dataSourceName: string;
  isOpen: boolean;
  handleClose: () => void;
  selectedNode: Interfaces.Schema;
}) => {
  const { enqueueSnackbar, } = useSnackbar();
  const [rows, setRows,] = useState<Array<any>>([]);
  const [loading, setLoading,] = useState(true);

  useEffect(() => {
    if (isOpen) {
      const _tmpNode = helpers.cleanseSchema(_.cloneDeep(selectedNode));
      dataServices.fetchSampleData(dataSourceName, _tmpNode)
        .then((response: AxiosResponse<{ data: any }>) => {
          setRows(response.data.data);
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {
      setLoading(true);
      setRows([]);
    };
  }, [isOpen,]);

  return (
    <Modal
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        display: 'flex',
      }}
      open={isOpen}
      onClose={() => handleClose()}
    >
      <Card
        style={{
          width: '85%',
          height: '80%',
        }}
      >
        <CardHeader
          style={{
            borderBottom: '1px solid #80808045',
            boxShadow: '0 0 6px 0 #80808045',
          }}
          action={
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          }
          title='Preview'
        />
        <CardContent
          style={{
            padding: 0,
            height: '80%',
            overflow: 'auto',
          }}
        >
          <TableContainer component={Paper}>
            {
              loading && (
                <LoadingComponent />
              )
            }
            {
              !loading && rows.length === 0 && (
                <div>No data found</div>
              )
            }
            {
              !loading && rows.length > 0 && (
                <Table aria-label="customized table">
                  <TableHead key="table_head_1">
                    <TableRow
                      key="header-1"
                      style={{
                        overflow: 'auto',
                        backgroundColor: '#e5e3e3',
                      }}
                    >
                      {Object.keys(rows[0]).map((key) => (
                        <TableCell
                          align="left"
                          style={{
                            marginRight: '5px',
                            fontWeight: 'bold',
                          }}
                          key={key}
                        >
                          {key}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row: any, index: any) => (
                      index > 0 &&
                      <TableRow key={index}>
                        {Object.keys(rows[0]).map((value) => (
                          <TableCell align="left" key={`${index}_${value}`}>
                            {row[value]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )
            }
          </TableContainer>
        </CardContent>
      </Card>
    </Modal>
  );
};

export { SampleDataDialog, };
