import React from 'react';
import { useStyles, } from './styles';
import {
  Box,
  Grid,
} from '@material-ui/core';
import TagsComponent from '../../TagsComponent';
import { LabelComponent, } from '../../LabelComponent';
import { Interfaces, } from '../../../config';


const NodeMetadataTagsLabels = ({ selectedNode, }: { selectedNode: Interfaces.Schema; }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} style={{ marginTop: '20px', }}>
      <Box className={classes.detailsWrapper}>
        <Grid container>
          {
            selectedNode.tags.length > 0 && (
              <Grid
                style={{
                  background: 'white',
                  padding: '15px 10px 15px 10px',
                }}
                item xs={5}
              >
                <Grid container>
                  <TagsComponent
                    readOnly={true}
                    isHidden={true}
                    isHiddenTooltip={true}
                    form="catalogs"
                    metadata={selectedNode}
                    title="Tags:"
                    secondaryTitle="Add a tag"
                  />
                </Grid>
              </Grid>
            )
          }
          {
            selectedNode.labels.length > 0 && (
              <Grid
                style={{
                  background: 'white',
                  padding: '15px 10px 15px 10px',
                }}
                item xs={7}
              >
                <LabelComponent
                  readOnly={true}
                  metadata={selectedNode}
                />
              </Grid>
            )
          }
        </Grid>
      </Box>
    </Grid>
  );
};

export { NodeMetadataTagsLabels, };
