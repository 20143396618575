import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

const treeItemStyle = {
  borderRadius: 3,
  minWidth: 240,
  height: '26px',
  '&:hover': {
    background: '#dbe3fa',
  },
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingRight: '10px',
};

export const checkBox = {
  padding: 0,
  color: '#595959',
  fontSize: '11pt',
};
export const checkBoxDisabled = {
  padding: 0,
  fontSize: '11pt',
};

export const useStyles = makeStyles(
  createStyles({
    '@global': {
      '.MuiTreeItem-label:hover': { backgroundColor: 'transparent', },
      '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label':
        {
          backgroundColor: 'transparent',
        },
      '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label':
        {
          backgroundColor: 'transparent',
        },
    },
    root: {
      background: 'white',
      width: '100%',
      marginBottom: 20,
    },
    minimalTreeView: {
      background: 'white',
      width: '100%',
    },
    treeItemStyle: {
      ...treeItemStyle,
    },
    selectedTreeItemStyle: {
      ...treeItemStyle,
      background: '#e0e9fd',
    },
    minimalTreeItemStyle: {
      margin: 1,
      width: '100%',
    },
    greenTreeItemStyle: {
      ...treeItemStyle,
      background: 'rgb(0 128 0 / 9%)',
    },
    orangeTreeItemStyle: {
      ...treeItemStyle,
      background: 'rgb(244 142 54 / 8%)',
    },
    redTreeItemStyle: {
      ...treeItemStyle,
      background: 'rgb(181 2 2 / 9%)',
    },
    schemaTypeIcon: {
      marginRight: 6,
      marginLeft: 9,
      color: '#0b52a4', // ikonaa
    },
    minimalSchemaTypeIcon: {
      marginRight: 6,
      color: '#0b52a4',
    },
    itemText: {
      fontSize: '11pt',
      color: '#757474',
      marginTop: '1px',
      flex: 1,
      position: 'relative',
    },
    itemTextInner: {
      position: 'absolute',
      top: -12,
      left: 0,
      backgroundColor: 'transparent',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    fieldsCount: {
      fontWeight: 'bold',
      float: 'right',
      fontSize: '10pt',
      backgroundColor: 'grey',
      color: 'white',
      width: 18,
      height: 18,
      textAlign: 'center',
      borderRadius: '4px',
    },
    fieldsCountText: {
      marginTop: 0,
    },
    tagAndLabelIcon: {
      float: 'right',
      color: 'grey',
      paddingRight: '10px',
      fontSize: 30,
    },
    tagAndLabelIconAuto: {
      float: 'right',
      color: 'blue',
      paddingRight: '10px',
      fontSize: 30,
    },
    foreignKeyIcon: {
      float: 'right',
      color: '#0b52a4',
      paddingRight: '10px',
      fontSize: 26,
    },
  })
);
