import React from 'react';
import { useStyles, } from './styles';
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { AccountCircle, } from '@material-ui/icons';
import { useAuth, } from '../../context';
import { registerServices, } from '../../services';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../utils';
import { CheckEmail, } from './CheckEmail';
import { useHistory, } from 'react-router-dom';

const ResendVerification = () => {
  const classes = useStyles();
  const { health, } = useAuth();
  const history = useHistory();
  const [email, setEmail,] = React.useState('');
  const [loading, setLoading,] = React.useState(false);
  const [successfulRegistration, setSuccessfulRegistration,] = React.useState(false);
  const { enqueueSnackbar, } = useSnackbar();

  const resendVerification = async () => {
    setLoading(true);
    registerServices.resendVerification({
      email: email,
    })
      .then(() => {
        setLoading(false);
        setSuccessfulRegistration(true);
        enqueueSnackbar('Successfully registered', { variant: 'success', });
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };
  
  if (successfulRegistration) {
    return (
      <CheckEmail
        title="Successfully resent verification"
        subtitle="Check your email for confirmation"
      />
    );
  }
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.mainGrid}
    >
      <Grid item xs={5} sm={6} md={5} lg={3} className={classes.formGrid}>
        <div
          className={classes.logoDiv}
        >
          <img src='/logo.png' className={classes.logo} alt="logo"/>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          resendVerification();
        }}>
          <Typography
            className={classes.logoName}
            variant={'h4'}
          >
            Email Verification
          </Typography>
          <TextField
            fullWidth
            placeholder="Email adrress"
            margin="normal"
            variant="outlined"
            size="medium"
            name="email"
            type="text"
            onChange={({ target, }) => setEmail(target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle/>
                </InputAdornment>
              ),
            }}
          />
          <Button
            fullWidth
            color="primary"
            type="submit"
            variant="contained"
            className={classes.button}
            disabled={loading}
          >
            Send
          </Button>
        </form>
        <Typography
          style={{
            textAlignLast: 'center',
          }}
        >
          Want to go back? {' '}
          <p
            style={{
              color: 'blue',
              textDecoration: 'underline',
              display: 'inline-flex',
              cursor: 'pointer',

            }}
            onClick={() => history.push('/')}
          >
            Log in
          </p>
        </Typography>
        <div style={{ textAlignLast: 'center',
          marginTop: 10, }}>
          <span style={{ fontWeight: 'bold',
            color: 'gray',  }}>
            {'v'+ health.version || ''}
          </span>
        </div>
      </Grid>
    </Grid>
  );
};

export { ResendVerification, };
