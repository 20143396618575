import React from 'react';
import { Card, } from './Card';
import {
  Badge,
  Box,
  IconButton,
  Menu,
  Typography,
} from '@material-ui/core';
import { Link, } from 'react-router-dom';
import { alertsServices, } from '../../../services';
import { AxiosResponse, } from 'axios';
import { Interfaces, } from '../../../config';
import { useInterval, } from 'usehooks-ts';
import { NotificationsNone, } from '@material-ui/icons';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ClearAllOutlinedIcon from '@material-ui/icons/ClearAllOutlined';
import { useStyles, } from './Styles';

interface Props {
  readAlerts: boolean;
}
const NotificationsList = ({ readAlerts, }: Props) => {
  const classes = useStyles();
  const [alertHistories, setAlertHistories,] = React.useState<Array<Interfaces.AlertHistory>>([]);
  const [readNotifications, setReadNotifications,] =
    React.useState<Array<string>>(JSON.parse(localStorage.getItem('alert.histories') || '[]'));
  const [removedNotifications, setRemovedNotifications,] =
  React.useState<Array<string>>(JSON.parse(localStorage.getItem('alert.histories.deleted') || '[]'));
  const [notificationsEl, setNotificationsEl,] = React.useState<null | HTMLElement>(null);

  const fetchAlertHistories = () => {
    if (readAlerts) {
      alertsServices.fetchAlertHistories(100)
        .then((response: AxiosResponse<{ data: Array<Interfaces.AlertHistory> }>) => {
          const _alertHistories: Array<Interfaces.AlertHistory> = [];
          response.data.data.forEach((history) => {
            if (!removedNotifications.includes(history.id)) {
              _alertHistories.push(history);
            }
          });
          setAlertHistories(_alertHistories);
          if (response.data.data.length === 0) {
            setReadNotifications([]);
            localStorage.setItem('alert.histories', '[]');
          }
        });
    }
  };

  React.useEffect(() => {
    fetchAlertHistories();
  }, []);

  useInterval(() => {
    fetchAlertHistories();
  }, 15000);

  const alertRead = (alertHistoryId: string) => {
    if (!readNotifications.includes(alertHistoryId)) {
      localStorage.setItem('alert.histories', JSON.stringify([...readNotifications, alertHistoryId,]));
      setReadNotifications([...readNotifications, alertHistoryId,]);
    }
  };

  const alertUnread = (alertHistoryId: string) => {
    if (readNotifications.includes(alertHistoryId)) {
      const newArray = [...readNotifications,];
      const index = newArray.indexOf(alertHistoryId);
      newArray.splice(index, 1);
      localStorage.setItem('alert.histories', JSON.stringify(newArray));
      setReadNotifications(newArray);
    }
  };

  return (
    <>
      <IconButton
        aria-label="show alerts"
        color="primary"
        aria-controls="notifications-menu"
        aria-haspopup="true"
        onClick={(event) => setNotificationsEl(event.currentTarget)}
      >
        <NotificationsNone style={{ fontSize: '22pt', }} color={'primary'} />
        {
          (alertHistories.length - readNotifications.length ) > 0 &&
          <Badge badgeContent={alertHistories.length - readNotifications.length} color="secondary"
            style={{ marginBottom: '12px', }}
          />
        }
      </IconButton>
      <Menu
        className={classes.root}
        id="notifications-menu"
        anchorEl={notificationsEl}
        anchorPosition={{
          top: 63,
          left: 1900,
        }}
        anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(notificationsEl)}
        onClose={() => setNotificationsEl(null)}
      >
        <Box className={classes.info}>
          <Typography variant='h5' className={classes.header}>Notifications</Typography>
          <Link to={`/alert/histories`} className={classes.link} onClick={() => setNotificationsEl(null)}>
            <OpenInNewIcon fontSize='small' style={{ marginRight: '2px', }} />
            See all
          </Link>
        </Box>
        {alertHistories.length > 0 && (
          <Box
            className={classes.links}>
            <span className={classes.link} onClick={() => {
              const alertHistoriesId = alertHistories.map((history) => history.id);
              const newArray = [...removedNotifications, ...alertHistoriesId,];
              localStorage.setItem('alert.histories.deleted', JSON.stringify(newArray));
              setAlertHistories([]);
              setRemovedNotifications(newArray);
              setReadNotifications([]);
            }}>
              <ClearAllOutlinedIcon fontSize='small' style={{ marginRight: '2px', }}/>
              Clear all
            </span>
          </Box>
        )}

        {alertHistories.length < 1 ? (
          <Typography variant='subtitle1' className={classes.noAlerts} >{`You've read all your notifications`}</Typography>
        ) : (
          alertHistories.map((alertHistory) => (
            <Card
              key={alertHistory.id}
              read={readNotifications.includes(alertHistory.id)}
              alertHistory={alertHistory}
              alertRead={alertRead}
              alertUnread={alertUnread}
              setNotificationsEl={setNotificationsEl}
            />
          ))
        )}
      </Menu>

    </>
  );
};

export { NotificationsList, };
