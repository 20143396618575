import React, { useState, } from 'react';
import {
  Button,
  Grid,
} from '@material-ui/core';
import { useStyles, } from '../styles';
import { Interfaces, } from '../../../../config';
import { DataSourceType, } from '../helpers';
import {
  AvroDialog,
  DDLDialog,
  ExtractDialog,
} from '../ActionDialogs';
import { GitConfigMenu, } from '../../GitConfigMenu';

const MainActionButtons = ({
  catalog,
  selectedDataSource,
  schemaError,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  selectedDataSource: DataSourceType | null;
  schemaError: boolean;
}) => {
  const classes = useStyles();
  const [avro, setAvro,] = useState(false);
  const [extract, setExtract,] = useState(false);
  const [ddl, setDdl,] = useState(false);

  return (
    <Grid container justifyContent={'flex-end'}>
      <AvroDialog catalog={catalog} isOpen={avro} handleClose={() => setAvro(false)} />
      <ExtractDialog catalog={catalog} isOpen={extract} handleClose={() => setExtract(false)} />
      <DDLDialog catalog={catalog} isOpen={ddl} handleClose={() => setDdl(false)} />
      <Button
        className={classes.actionButtons}
        variant={'outlined'}
        color={'primary'}
        onClick={() => setExtract(true)}
        disabled={!catalog.name || !catalog.dataSourceName || schemaError}
      >
        Extract
      </Button>
      <Button
        className={classes.actionButtons}
        variant={'outlined'}
        color={'primary'}
        onClick={() => setAvro(true)}
        disabled={!catalog.name || !catalog.dataSourceName || schemaError}
      >
        Avro
      </Button>
      {
        selectedDataSource?.type === 'JDBC'
        && <Button
          className={classes.actionButtons}
          variant={'outlined'}
          color={'primary'}
          onClick={() => setDdl(true)}
          disabled={
            !catalog.name || !catalog.dataSourceId || selectedDataSource?.type !== 'JDBC' || schemaError
          }
        >
          DDL
        </Button>
      }
      {
        catalog.lastRunId && (
          <div
            style={{
              margin: '5px 10px 4px 20px',
            }}
          >
            <GitConfigMenu catalog={catalog}/>
          </div>
        )
      }
    </Grid>
  );
};

export { MainActionButtons, };
