import React from 'react';
import {
  Toolbar,
  Typography,
  Tooltip,
  TextField,
  Grid,
} from '@material-ui/core';
import styles from '../Styles';


const EnhancedTableToolbar = (props: any) => {

  const classes = styles();
  const { handleSearch, } = props;

  return (
    <div>
      <Toolbar
        className={classes.rootToolbarTags}
      >
        <Grid container>
          <Grid item xs={2}>
            <Typography
              className={classes.titleTags}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Business Tags
            </Typography>
          </Grid>
        </Grid>
        <Tooltip title="Search by name">
          <TextField
            className={classes.searchPadding}
            name="search"
            label="Search"
            color="primary"
            onChange={(event) => {
              handleSearch(event);
            }}
          />
        </Tooltip>
      </Toolbar>
    </div>
  );
};

export { EnhancedTableToolbar, };
