import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      marginLeft: 14,
      marginTop: -12,
    },
    select: {
      '& .MuiSelect-root': {
        width: '250px',
        padding: '10px 30px 10px 10px',
      },
    },
    secondaryType: {
      display: 'contents',
    },
    gitIcon: {
      width: '25px',
      height: '25px',
    },
    customInput: {
      height: 20,
    },
  })
);
