import React, { useEffect, } from 'react';
import { useStyles, } from './styles';
import {
  Grid,
  TextField,
} from '@material-ui/core';
import { Interfaces, } from '../../../../config';

const SendMail = ({
  alert,
  setAlert,
  handleValidation,
}: {
  alert: Interfaces.Alert;
  // eslint-disable-next-line no-unused-vars
  setAlert: (alert: Interfaces.Alert) => void;
  // eslint-disable-next-line no-unused-vars
  handleValidation: (value: boolean) => void;
}) => {
  const classes = useStyles();

  useEffect(() => {
    const to = alert.outputRule.rule['to'];
    const subject = alert.outputRule.rule['subject'];
    const body = alert.outputRule.rule['text'];

    const isValid = !!(
      to && to !== ''
      && subject && subject !== ''
      && body && body !== ''
    );
    handleValidation(isValid);
  }, [alert.outputRule,]);

  return (
    <div className={classes.containerWidth}>
      <form>
        <Grid container direction={'row'}>
          <Grid
            item
            xs={12}
            className={classes.marginBottom}
          >
            <TextField
              id="selectSenderMail"
              label="To"
              value={alert.outputRule.rule['to']}
              size={'small'}
              onChange={(event) => {
                setAlert({
                  ...alert,
                  outputRule: {
                    ...alert.outputRule,
                    rule: {
                      ...alert.outputRule.rule,
                      ['to']: event.target.value,
                    },
                  },
                });
              }}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.marginBottom}
          >
            <TextField
              id="subject"
              label="Subject"
              size='small'
              variant="outlined"
              color="primary"
              fullWidth
              value={alert.outputRule.rule['subject']}
              onChange={(event) => {
                setAlert({
                  ...alert,
                  outputRule: {
                    ...alert.outputRule,
                    rule: {
                      ...alert.outputRule.rule,
                      ['subject']: event.target.value,
                    },
                  },
                });
              }}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              id="body"
              size='small'
              label="Body"
              variant="outlined"
              color="primary"
              multiline
              value={alert.outputRule.rule['text']}
              minRows={8}
              fullWidth
              onChange={(event) => {
                setAlert({
                  ...alert,
                  outputRule: {
                    ...alert.outputRule,
                    rule: {
                      ...alert.outputRule.rule,
                      ['text']: event.target.value,
                    },
                  },
                });
              }}
              required
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export { SendMail, };
