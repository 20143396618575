import React from 'react';
import { useStyles, } from './styles';
import { useAuth, } from '../../context';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AccountCircle,
  BusinessOutlined,
  Lock,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { useSnackbar, } from 'notistack';
import { registerServices, } from '../../services';
import { CheckEmail, } from './CheckEmail';
import { helpers, } from '../../utils';
import CheckIcon from '@material-ui/icons/Check';

const Register = () => {
  const classes = useStyles();
  const {  health, deploymentType, } = useAuth();
  const [registerCredentials, setCredentials,] = React.useState({
    fullName: '',
    companyName: '',
    username: '',
    password: '',
  });
  const { enqueueSnackbar, } = useSnackbar();
  const [loading, setLoading,] = React.useState(false);
  const [successfulRegistration, setSuccessfulRegistration,] = React.useState(false);
  const [checkPasswordLength, setCheckPasswordLength,] = React.useState(false);
  const [checkPasswordUpperCase, setCheckPasswordUpperCase,] = React.useState(false);
  const [checkPasswordLowerCase, setCheckPasswordLowerCase,] = React.useState(false);
  const [checkPasswordNumber, setCheckPasswordNumber,] = React.useState(false);
  const [viewPasswordValidation, setViewPasswordValidation,] = React.useState(false);
  const [hasErorrPassword, setHasErorrPassword,] = React.useState(false);
  const [showPassword, setShowPassword,] = React.useState<boolean>(false);
  const [termsOfUse, setTermsOfUse,] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  let passwordIsValid = false;

  if (!passwordIsValid && checkPasswordLength && checkPasswordUpperCase && checkPasswordLowerCase && checkPasswordNumber) {
    passwordIsValid = true;
  }

  const isValidPassword = (password: string) => {
    if (hasErorrPassword) {
      setHasErorrPassword(false);
    }
    if (!viewPasswordValidation && password.length > 0) {
      setViewPasswordValidation(true);
    }
    if (!checkPasswordLength && password.length >= 12) {
      setCheckPasswordLength(true);
    }
    if (checkPasswordLength && password.length < 12) {
      setCheckPasswordLength(false);
    }
    if (!checkPasswordUpperCase && password.match(/[A-Z]/)) {
      setCheckPasswordUpperCase(true);
    }
    if (checkPasswordUpperCase && !password.match(/[A-Z]/)) {
      setCheckPasswordUpperCase(false);
    }
    if (!checkPasswordLowerCase && password.match(/[a-z]/)) {
      setCheckPasswordLowerCase(true);
    }
    if (checkPasswordLowerCase && !password.match(/[a-z]/)) {
      setCheckPasswordLowerCase(false);
    }
    if (!checkPasswordNumber && password.match(/[0-9]/)) {
      setCheckPasswordNumber(true);
    }
    if (checkPasswordNumber && !password.match(/[0-9]/)) {
      setCheckPasswordNumber(false);
    }
  };

  if (successfulRegistration) {
    return (
      <CheckEmail
        title="Successfully created account"
        subtitle="Check your email for confirmation"
        isSuccessfulRegistration={true}
      />
    );
  }

  const register = async () => {
    if (!passwordIsValid) {
      setHasErorrPassword(true);
      return;
    }
    setLoading(true);
    let password = registerCredentials.password;
    if (deploymentType === 'cloud') {
      password = await helpers.sha256(registerCredentials.password);
    }
    registerServices.register({
      ...registerCredentials,
      password,
    })
      .then(() => {
        setLoading(false);
        setSuccessfulRegistration(true);
        enqueueSnackbar('Successfully registered', { variant: 'success', });
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
    >
      <form onSubmit={(e) => {
        e.preventDefault();
        register();
      }}>
        <TextField
          fullWidth
          required
          label="Full Name"
          placeholder="Full Name"
          margin="normal"
          variant="outlined"
          size="medium"
          name="fullName"
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle/>
              </InputAdornment>
            ),
          }}
          value={registerCredentials.fullName}
          onChange={(event) => setCredentials({
            ...registerCredentials,
            fullName: event.target.value,
          })}
        />
        <TextField
          fullWidth
          label="Company (optional)"
          placeholder="Company"
          margin="normal"
          variant="outlined"
          size="medium"
          name="username"
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BusinessOutlined />
              </InputAdornment>
            ),
          }}
          value={registerCredentials.companyName}
          onChange={(event) => setCredentials({
            ...registerCredentials,
            companyName: event.target.value,
          })}
        />
        <TextField
          fullWidth
          label="Email"
          placeholder="Email"
          margin="normal"
          variant="outlined"
          size="medium"
          name="username"
          type="email"
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle/>
              </InputAdornment>
            ),
          }}
          value={registerCredentials.username}
          onChange={(event) => setCredentials({
            ...registerCredentials,
            username: event.target.value,
          })}
        />
        <TextField
          fullWidth
          type={showPassword ? 'text' : 'password'}
          required
          error={hasErorrPassword}
          label="Password"
          placeholder="Password"
          margin="normal"
          variant="outlined"
          size="medium"
          name="password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock/>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={registerCredentials.password}
          onChange={(event) => {
            setCredentials({
              ...registerCredentials,
              password: event.target.value,
            });
            isValidPassword(event.target.value);
          }}
        />
        { viewPasswordValidation && (
          <div className={classes.passwordErrorDiv}>
            <Typography variant="subtitle1">Your Password must contain:</Typography>
            <Typography variant="body1" color="inherit" className={!checkPasswordLength ? classes.flexCenterStart1 : classes.flexCenterStart2}>
              { checkPasswordLength ? <CheckIcon fontSize='small' color='inherit'/> : <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div> }
            &nbsp;&nbsp;At least 12 characters
            </Typography>
            <Typography variant="body1" color="inherit" className={!checkPasswordLowerCase  ? classes.flexCenterStart1 : classes.flexCenterStart2}>
              { checkPasswordLowerCase ? <CheckIcon fontSize='small' color='inherit'/> : <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div> }
            &nbsp;&nbsp;Lower case letters (a-z)
            </Typography>
            <Typography variant="body1" color="inherit" className={!checkPasswordUpperCase ? classes.flexCenterStart1 : classes.flexCenterStart2}>
              { checkPasswordUpperCase ? <CheckIcon fontSize='small' color='inherit'/> : <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div> }
            &nbsp;&nbsp;Upper case letters (A-Z)
            </Typography>
            <Typography variant="body1" color="inherit" className={!checkPasswordNumber ? classes.flexCenterStart1 : classes.flexCenterStart2}>
              { checkPasswordNumber ? <CheckIcon fontSize='small' color='inherit'/> : <div>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</div> }
            &nbsp;&nbsp;Numbers (0-9)
            </Typography>
          </div>
        )}
        <FormControlLabel
          className={classes.marginRightCheckbox}
          value="start"
          control={
            <Checkbox
              onClick={() => setTermsOfUse(!termsOfUse)}
              checked={termsOfUse}
              color="primary"
            />
          }
          label={
            <div
              className={classes.termsOfUse}
            >
              By signing up for a Free Account, I agree to the {' '}
              <a
                href={'http://rosettadb.io/privacy-policy'}
                target={'_blank'}
                rel="noreferrer"
              >
                Terms of Use
              </a>
            </div>
          }
          labelPlacement="start"
        />
        <Button
          fullWidth
          color="primary"
          type="submit"
          variant="contained"
          className={classes.button}
          disabled={loading || !termsOfUse}
        >
            Register
        </Button>
      </form>
      <div className={classes.versionDiv}>
        <span className={classes.version}>
          {'v'+ health.version || ''}
        </span>
      </div>
    </Box>
  );
};

export { Register, };
