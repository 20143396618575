import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import Style from './style';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Save as SaveIcon,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import logo from '../CardView/logo';
import { Interfaces, } from '../../../config';
import { useDSCalls, } from '../../../hooks';
import LoadingComponent from '../../Loading';

type Params = {
  oldName: string;
};

const Oracle = () => {
  const classes = Style();
  const history = useHistory();
  const [state, setState,] = useState<Interfaces.JDBCDataSource>({
    id: '',
    name: '',
    type: 'JDBC',
    uri: 'jdbc:oracle:thin:@//${host}:${port}',
    attributes: null,
    databaseName: '',
    databaseHost: '',
    databasePort: '1521',
    databaseTls: false,
    description: '',
    driverClassName: 'oracle.jdbc.driver.OracleDriver',
    driverFilePath: '',
    driverFileType: '',
    driverName: 'oracle',
    password: '',
    username: '',
  });
  const [showPassword, setShowPassword,] = React.useState<boolean>(false);
  const { oldName, } = useParams<Params>();
  const [isInEditMode, setIsInEditMode,] = useState(false);
  const { loading: loadingDS,
    editDataSource,
    addDataSource,
    testDataSource,
    dataSourceToEdit: dataSourceToEditHook,
    loadingEdit,
    errorEdit,
    loadingTest,
    errorTest,
    uploadDriverAPI,
    fetchDataSource,
    testNewDataSource,
    testExistingDataSource,
    setTestDataSource, } = useDSCalls();

  const dataSourceToEdit = dataSourceToEditHook as Interfaces.JDBCDataSource | null;
  const [loadingDriver, setLoadingDriver,] = useState(false);

  useEffect(() => {
    setTestDataSource(null);
    const editMode = history.location.pathname.includes('/edit');
    setIsInEditMode(editMode);
    if (editMode && dataSourceToEdit === null) {
      fetchDataSource(oldName);
    }
    if (editMode && dataSourceToEdit !== null) {
      setState({
        ...state,
        id: dataSourceToEdit?.id,
        uri: dataSourceToEdit?.uri,
        name: dataSourceToEdit?.name,
        description: dataSourceToEdit?.description,
        type: dataSourceToEdit?.type,
        driverName: dataSourceToEdit?.driverName,
        driverClassName: dataSourceToEdit?.driverClassName,
        driverFilePath: dataSourceToEdit?.driverFilePath,
        driverFileType: dataSourceToEdit?.driverFileType,
        databaseHost: dataSourceToEdit?.databaseHost,
        databaseName: dataSourceToEdit?.databaseName,
        databasePort: dataSourceToEdit?.databasePort,
        databaseTls: dataSourceToEdit?.databaseTls,
        username: dataSourceToEdit?.username,
        password: null,
      });
    }
  }, [dataSourceToEdit,]);

  const createUri = (dbName: string, host: string, port: string) => {
    const uri = `jdbc:oracle:thin:@//${host}:${port}`;
    return `${uri}/${dbName}`;
  };

  const setUri = (name: string, value: string) => {
    switch (name) {
    case 'driverName':
      return createUri(state.databaseName, state.databaseHost, state.databasePort);
    case 'databaseName':
      return createUri(value, state.databaseHost, state.databasePort);
    case 'databaseHost':
      return createUri(state.databaseName, value, state.databasePort);
    case 'databasePort':
      return createUri(state.databaseName, state.databaseHost, value);
    case 'uri':
      return value;
    default:
      return state.uri;
    }
  };

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
      uri: setUri(name, value),
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onTestConnection = () => {
    isInEditMode ? testExistingDataSource(state) : testNewDataSource(state);
  };

  const driverUpload = (files: any) => {
    setLoadingDriver(true);
    if (files.length > 0) {
      uploadDriverAPI(files[0], state.driverClassName, (driverFilePath) => {
        setState({
          ...state,
          driverFilePath,
        });
        setLoadingDriver(false);
      });
      return;
    }
    setLoadingDriver(false);
  };

  const postSubmitAction = () => {history.push('/data-sources');};

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (history.location.pathname.includes('/edit')) {
      editDataSource(state, oldName, postSubmitAction);
      return;
    }
    addDataSource(state, postSubmitAction);
  };

  const handleTestSuccess = () => {
    if (loadingTest) {
      return <CircularProgress
        color="inherit"
        style={{
          width: 11,
          height: 11,
        }}
      />;
    }
    if (testDataSource && !errorTest) {
      return <FiberManualRecordIcon style={{ fill: 'green', }} />;
    } else if (
      !testDataSource &&
      !errorTest
    ) {
      return <FiberManualRecordIcon style={{ fill: 'grey', }} />;
    } else if (
      errorTest
    ) {
      return <FiberManualRecordIcon style={{ fill: 'red', }} />;
    }
  };

  if (loadingEdit) {
    return <LoadingComponent message={'Loading data source'}/>;
  }

  if (errorEdit) {
    return (
      <p>{errorEdit}</p>
    );
  }

  if (isInEditMode && !dataSourceToEdit) {
    return (
      <p>No data source was selected for edit mode</p>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' >
        <img alt="oracle" src={logo.images.oracle} className={classes.img} />
      </Grid>
      <Grid container justifyContent='center' spacing={3}>
        <form name="addJdbcForm" onSubmit={handleSubmit} style={{ width: '78%', }}>
          <Grid item>
            <TextField
              className={classes.paddingBox}
              id="name"
              label="Connection Name"
              variant="outlined"
              name="name"
              onChange={handleChange()}
              fullWidth
              size="small"
              value={state.name}
              required
            />
            <TextField
              className={classes.paddingBox}
              id="description"
              label="Description"
              multiline
              rows={4}
              variant="outlined"
              name="description"
              onChange={handleChange()}
              fullWidth
              value={state.description}
              size="small"
            />
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <TextField
                  className={classes.paddingBox}
                  id="database.host"
                  label="Hostname"
                  variant="outlined"
                  name="databaseHost"
                  onChange={handleChange()}
                  fullWidth
                  size="small"
                  value={state.databaseHost}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  className={classes.paddingBox}
                  id="database.port"
                  label="Port"
                  variant="outlined"
                  name="databasePort"
                  onChange={handleChange()}
                  fullWidth
                  size="small"
                  value={state.databasePort}
                  required
                />
              </Grid>
            </Grid>
            <TextField
              className={classes.paddingBox}
              id="database.name"
              label="Database name"
              variant="outlined"
              name="databaseName"
              onChange={handleChange()}
              fullWidth
              size="small"
              value={state.databaseName}
              required
            />
            <TextField
              className={classes.paddingBox}
              id="credentials.username"
              label="Username"
              variant="outlined"
              name="username"
              onChange={handleChange()}
              fullWidth
              size="small"
              value={state.username}
            />
            <FormControl
              variant="outlined"
              className={classes.paddingBox}
              fullWidth
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                {state.password === null ? '(Password protected)' : 'Password'}
              </InputLabel>
              <OutlinedInput
                type={showPassword ? 'text' : 'password'}
                value={state.password}
                placeholder={state.password === null ? '(Password protected)' : 'Password'}
                onFocus={() => setState({
                  ...state,
                  password: '',
                })}
                onChange={handleChange()}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                name="password"
                labelWidth={70}
              />
            </FormControl>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <TextField
                  className={classes.paddingBox}
                  id="outlined-basic"
                  label="Oracle Driver (Class Name)"
                  variant="outlined"
                  name="driverClassName"
                  onChange={handleChange()}
                  fullWidth
                  size="small"
                  value={state.driverClassName}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <input
                  accept=".jar"
                  className={classes.input}
                  id="input-file"
                  onChange={(e: any) => {
                    driverUpload(e.target.files);
                    e.target.value = '';
                  }}
                  type="file"
                  disabled={!state.driverClassName || state.driverClassName === ''}
                />
                <label htmlFor="input-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={
                      loadingDriver ?
                        <CircularProgress
                          color="inherit"
                          style={{
                            width: 16,
                            height: 11,
                          }} />
                        : <CloudUploadIcon />
                    }
                    size="large"
                    fullWidth
                    disabled={!state.driverClassName || state.driverClassName === ''}
                  >
                    Upload
                  </Button>
                </label>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <TextField
                  className={classes.paddingBox}
                  id="driver.uri"
                  placeholder="jdbc:oracle://{host}[:{port}][;databaseName={database}]"
                  label="Oracle Connection String"
                  variant="outlined"
                  name="uri"
                  onChange={handleChange()}
                  fullWidth
                  size="small"
                  value={state.uri}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={handleTestSuccess()}
                  size="large"
                  fullWidth
                  onClick={() => {
                    onTestConnection();
                  }}
                >
                  Test
                </Button>
              </Grid>
            </Grid>
            {errorTest && (
              <Typography variant="subtitle2" style={{ color: 'red', }}>
                {errorTest}
              </Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={loadingDriver || loadingDriver}
              startIcon={loadingDS || loadingDriver ?
                <CircularProgress
                  color="inherit"
                  style={{
                    width: 16,
                    height: 11,
                  }} />
                :
                <SaveIcon />}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="default"
              size="large"
              className={classes.button}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export { Oracle, };
