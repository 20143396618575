import React from 'react';
import OverflowTip from './OverflowTip';
import {
  Typography,
  createStyles,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { Icon, } from '../../../Icon';
import { datasourceServices, } from '../../../../services';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../../../utils';
import { AxiosResponse, } from 'axios';
import { ReusableModal, } from '../../../Catalogs/AddCatalog/ActionDialogs/ReusableModal';
import { Interfaces, } from '../../../../config';
import {
  cleanseSchema,
  removeUnselectedFields,
} from '../../../../utils/helpers';

const styles = makeStyles((theme) => createStyles({
  schemaItem: {
    padding: 2,
  },
  schemaItemText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    marginLeft: 5,
  },
  schemaItemIcon: {
    float: 'right',
    color: theme.palette.primary.main,
    marginRight: 4,
    fontSize: 24,
    '&:hover': {
      color: '#83abe7',
    },
  },
  tableItem: {
    padding: 1,
    display: 'flex',
    alignItems: 'center',
  },
  tableItem2: {
    padding: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableItemText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    marginLeft: 5,
    width: 140,
  },
  tableIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  columnItem: {
    padding: 1,
    display: 'flex',
    alignItems: 'center',
  },
  columnItemText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginLeft: 5,
    width: 125,
  },
  columnIcon: {
    fontSize: 20,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  tableDDLIcon: {
    color: 'white',
    background: 'darkgray',
    marginRight: 4,
    fontSize: 10,
    fontWeight: 'bolder',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingTop: '4px',
    paddingBottom: '4px',
    borderRadius: '5px',
    float: 'right',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
}));

const SchemaTreeItem = ({ label, }: {label: string, }) => {
  const classes = styles();
  return (
    <div className={classes.schemaItem}>
      <Icon type="schema" color="#29913A" size="md" />
      <Typography variant={'caption'} className={classes.schemaItemText}>{label}</Typography>
    </div>
  );
};

const TableTreeItem = ({
  label,
  dataSourceId,
  originalSchema,
  field,
}: {
  label: string;
  dataSourceId: string;
  originalSchema: Interfaces.Schema;
  field: Interfaces.Schema;
} ) => {
  const classes = styles();
  const [hover, setHover,] = React.useState(false);
  const { enqueueSnackbar, } = useSnackbar();
  const [open, setOpen,] = React.useState(false);
  const [loading, setLoading,] = React.useState(false);
  const [data, setData,] = React.useState('');

  return (
    <Grid container className={classes.tableItem2} onMouseEnter={() => setHover(true)} onMouseLeave={(() => setHover(false))}>
      <div className={classes.tableItem}>
        <Icon type="table" color="#D67E0A" size="md" />
        <Typography className={classes.tableItemText} variant={'caption'}>
          <OverflowTip>
            {label}
          </OverflowTip>
        </Typography>
      </div>
      { hover &&
        <div
          className={classes.tableDDLIcon}
          onClick={(event) => {
            event.stopPropagation();
            setOpen(true);
            setLoading(true);
            const tmpSchema = JSON.parse(JSON.stringify(originalSchema));
            removeUnselectedFields(tmpSchema, field);
            cleanseSchema(tmpSchema);
            datasourceServices.getTableDDL(dataSourceId, tmpSchema)
              .then((response: AxiosResponse<{ message: string }>) => {
                setData(response.data.message);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
              });
          }}
        >
         DDL
        </div>
      }
      { open &&
        <ReusableModal
          open={open}
          title={'DDL'}
          value={data}
          loading={loading}
          mode={'sql'}
          handleClose={() => setOpen(false)}
        />
      }
    </Grid>
  );
};

const ColumnTreeItem = ({ label, }: {label: string} ) => {
  const classes = styles();
  return (
    <div className={classes.columnItem}>
      <Icon type="column" color="#1E5CAE" size="md" />
      <Typography
        variant={'caption'}
        className={classes.columnItemText}
      >
        <OverflowTip>
          {label}
        </OverflowTip>
      </Typography>
    </div>
  );
};

const ViewTreeItem = ({ label, }: {label: string} ) => {
  const classes = styles();
  return (
    <div className={classes.tableItem}>
      <Icon type="view" color="#1E5CAE" size="md" />
      <Typography className={classes.tableItemText} variant={'caption'}>
        <OverflowTip>
          {label}
        </OverflowTip>
      </Typography>
    </div>
  );
};

export const TreeItems = {
  Schema: SchemaTreeItem,
  Table: TableTreeItem,
  Column: ColumnTreeItem,
  View: ViewTreeItem,
};
