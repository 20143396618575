import React, { useState, } from 'react';
import { Interfaces, } from '../../../config';
import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TableDesignerColumnTest, } from './TableDesignerColumnTest';
import {
  Delete,
  DragIndicator,
} from '@material-ui/icons';
import { ColumnTypes, } from './ColumnTypes';
import { emptyTest, } from '../helpers';
import { v4, } from 'uuid';

interface Props {
  index: number;
  totalElements: number;
  // eslint-disable-next-line no-unused-vars
  handleChange: (newColumn: Interfaces.CollectionTableColumn) => void;
  // eslint-disable-next-line no-unused-vars
  handleColumnOrderChange: (direction: boolean) => void;
  column: Interfaces.CollectionTableColumn;
  // eslint-disable-next-line no-unused-vars
  removeColumn: () => void;
  columnTypes?: Array<Interfaces.DBMSType> | null;
  dbmsType: string;
  handleSave: () => void;
}

const useStyles = makeStyles(() => createStyles({
  dragDrop: {
    position: 'absolute',
    left: 0,
    top: '7px',
    cursor: 'pointer',
    zIndex: 999,
  },
}));

let booleanCount = 0;

const TableDesignerColumn = (props: Props) => {
  const {
    column,
    handleChange,
    dbmsType,
    index,
    totalElements,
    handleColumnOrderChange,
    columnTypes,
    handleSave,
  } = props;
  const [actions, setActions,] = useState(false);
  const [disabledColumnProperties, setDisabledColumnProperties,] = useState<Array<string>>([]);
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        marginBottom: 20,
        background: '#fff',
      }}
      onMouseEnter={() => setActions(true)}
      onMouseLeave={() => setActions(false)}
      onBlur={() => handleSave()}
    >
      <Grid item xs={2} style={{ position: 'relative', }}>
        <Grid container>
          <Grid item xs={2}>
            {
              totalElements > 1 && (
                <>
                  {
                    index !== totalElements && (
                      <Tooltip title={'Drag and Drop'}>
                        <DragIndicator
                          className={classes.dragDrop}
                          onClick={() => {
                            handleColumnOrderChange(false);
                            setActions(false);
                          }}
                        />
                      </Tooltip>
                    )
                  }
                </>
              )
            }
          </Grid>
          <Grid item xs={10}>
            <TextField
              name={'columnName'}
              label={'Name'}
              variant={'outlined'}
              error={!!column._errors && column._errors.length > 0}
              value={column.name}
              required
              size='small'
              style={{
                paddingRight: 20,
              }}
              onChange={(e) => {
                handleChange({
                  ...column,
                  name: e.target.value,
                });
              }}
              onBlur={() => {
                handleSave();
              }}
            />
            <Typography variant={'caption'} style={{ color: '#d24949', }}>
              {column._errors && column._errors.length > 0 && `* ${column._errors[0]}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          options={columnTypes || []}
          getOptionLabel={(option) => option.type}
          id="controlled-demo"
          value={{ type: column.typeName || '', }}
          onChange={(e, value) => {
            handleChange({
              ...column,
              typeName: value?.type || '',
            });
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Type"
              variant="outlined"
              size='small'
              required
              style={{
                paddingRight: 10,
              }}
              disabled={columnTypes === null}
              onBlur={() => {
                handleSave();
              }}
            />
          }
        />
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center', }}>
        <div style={{
          position: 'relative',
          height: '90%',
          display: 'flex',
        }}>
          {!ColumnTypes[dbmsType] || !ColumnTypes[dbmsType][column.typeName.toLowerCase()] &&
            <span
              style={{
                alignSelf: 'center',
              }}
            >
              Not supported type
            </span>}
          {
            column.typeName !== '' && ColumnTypes[dbmsType] && ColumnTypes[dbmsType][column.typeName.toLowerCase()] && ColumnTypes[dbmsType][column.typeName.toLowerCase()].properties.map((columnProperty, index) => {
              if (index === 0) {
                booleanCount = 0;
              }
              if (columnProperty.isBoolean) {
                booleanCount += 1;
              }
              return (
                <Grid
                  key={columnProperty.name}
                  item
                  xs={3}
                  style={
                    columnProperty.isBoolean ? {
                      position: 'absolute',
                      right: (booleanCount - 1) * 65,
                    } : {}
                  }
                >
                  {
                    columnProperty.isBoolean ? (
                      <Tooltip title={columnProperty.displayName}>
                        <div
                          style={{
                            maxHeight: 40,
                          }}
                        >
                          <Typography variant={'subtitle2'} style={{ fontSize: 10, }}>
                            {columnProperty.abbr}
                          </Typography>
                          <Checkbox
                            style={{ marginTop: -10, }}
                            disabled={disabledColumnProperties.includes(columnProperty.name)}
                            checked={!!column[columnProperty.name] && !disabledColumnProperties.includes(columnProperty.name)}
                            onChange={() => {
                              const _tmpColumn = { ...column, };
                              if (columnProperty.disables) {
                                if (!_tmpColumn[columnProperty.name]) {
                                  setDisabledColumnProperties([...disabledColumnProperties, ...columnProperty.disables,]);
                                  columnProperty.disables.map((prop) => _tmpColumn[prop] = false);
                                } else {
                                  setDisabledColumnProperties(disabledColumnProperties.filter((prop) => !columnProperty.disables?.includes(prop)));
                                }
                              }
                              handleChange({
                                ..._tmpColumn,
                                [columnProperty.name]: !_tmpColumn[columnProperty.name],
                              });
                            }}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox', }}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <TextField
                        value={column[columnProperty.name]}
                        type={columnProperty.isNumeric ? 'number' : 'text'}
                        size={'small'}
                        style={{ paddingRight: 10, }}
                        label={columnProperty.displayName}
                        variant={'outlined'}
                        onChange={(e) => {
                          handleChange({
                            ...column,
                            [columnProperty.name]: e.target.value,
                          });
                        }}
                      />
                    )
                  }
                </Grid>
              );
            })
          }
        </div>
      </Grid>
      <Grid item xs={3}>
        {
          actions && (
            <>
              <Button
                variant={'outlined'}
                color='primary'
                disabled={column.name === '' || column.typeName === ''}
                onClick={() => {
                  if (column.tests && column.tests.assertion) {
                    handleChange({
                      ...column,
                      tests: {
                        assertion: [...column.tests.assertion, {
                          ...emptyTest,
                          _uuid: v4(),
                        },],
                      },
                    });
                  } else {
                    handleChange({
                      ...column,
                      tests: {
                        assertion: [
                          {
                            ...emptyTest,
                            _uuid: v4(),
                          },
                        ],
                      },
                    });
                  }
                }}
                style={{
                  marginRight: 10,
                  marginTop: 10,
                  maxHeight: 30,
                }}
              >
                Add test
              </Button>
              <Tooltip title='Delete' placement='top'>
                <IconButton
                  style={{
                    marginTop: 10,
                    maxHeight: 30,
                  }}
                  onClick={props.removeColumn}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )
        }
      </Grid>
      {
        column.tests && column.tests.assertion && column.tests.assertion.map((test) => (
          <TableDesignerColumnTest
            key={test._uuid}
            test={test}
            handleChange={(test) => {
              const tmpTestIndex =
                column.tests && column.tests.assertion
                  ? column.tests.assertion.findIndex((_test) => _test._uuid === test._uuid)
                  : -1;
              if (tmpTestIndex !== -1) {
                const tests = column.tests && column.tests.assertion ? [...column.tests.assertion,] : [];
                tests[tmpTestIndex] = test;
                handleChange({
                  ...column,
                  tests: { assertion: tests, },
                });
              }
            }}
            removeTest={(uuid) => {
              column.tests && handleChange({
                ...column,
                tests: {
                  assertion: column.tests?.assertion.filter((_test) => _test._uuid !== uuid),
                },
              });
            }}
          />
        ))
      }
    </Grid>
  );
};

export { TableDesignerColumn, };
