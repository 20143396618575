import React from 'react';
import {
  GraphConfigType,
  GroupedSnapshotsType,
  TimeLineGraphType,
} from '../interfaces';
import { useStyles, } from './styles';
import { Grid, } from '@material-ui/core';
import { TimeLineFilterIcon, } from './Elements';
import moment from 'moment/moment';

const RenderItems = ({ graphConfig, data, selectedFilter, handleFilterSelect, } : TimeLineGraphType) => {
  const {
    initialX,
    sectionWidth,
    initialY,
  } = graphConfig;

  let len = 0;
  let prevLen = 0;

  return (
    <g>
      <TimeLineFilterIcon
        point={{
          x: initialX + 35,
          y: initialY,
        }}
        text={'W'}
        tooltipText={'Show runs for the last week'}
        selected={selectedFilter === 'week'}
        onClick={() => handleFilterSelect('week')}
      />
      <TimeLineFilterIcon
        point={{
          x: initialX + 60,
          y: initialY,
        }}
        text={'M'}
        textXPos={initialX + 61}
        tooltipText={'Show runs for the last month'}
        selected={selectedFilter === 'month'}
        onClick={() => handleFilterSelect('month')}
      />
      <TimeLineFilterIcon
        point={{
          x: initialX + 85,
          y: initialY,
        }}
        textXPos={initialX + 88}
        text={'Y'}
        tooltipText={'Show runs for the last year'}
        selected={selectedFilter === 'year'}
        onClick={() => handleFilterSelect('year')}
      />
      <TimeLineFilterIcon
        point={{
          x: initialX + 110,
          y: initialY,
        }}
        w={22}
        text={'All'}
        tooltipText={'Show all runs'}
        selected={selectedFilter === 'all'}
        onClick={() => handleFilterSelect('all')}
      />
      {
        data.map((runRecords, index) => {
          prevLen = len;
          len += runRecords.expanded ? runRecords.snapshots.length : 2;
          return runRecords.snapshots.map((record, idx) => {
            if (idx === 0 && index === 0) {
              return (
                <g key={index * idx}>
                  <text
                    x={initialX + sectionWidth}
                    y={initialY}
                    dominantBaseline={'central'}
                  >
                    {moment(record.properties?.createdDate).format('DD MMM')}
                  </text>
                </g>
              );
            }
            if (idx === 0) {
              return (
                <g key={index * idx}>
                  <text
                    x={initialX + sectionWidth * (prevLen + 1)}
                    y={initialY}
                    dominantBaseline={'central'}
                  >
                    {moment(record.properties?.createdDate).format('DD MMM')}
                  </text>
                </g>
              );
            } else {
              return (
                <g key={index * idx}>
                  {
                    runRecords.expanded ? (
                      <text
                        x={initialX + sectionWidth * (prevLen + idx + 1)}
                        y={initialY}
                        dominantBaseline={'central'}
                      >
                        {moment(record.properties?.createdDate).format('DD MMM')}
                      </text>
                    ) : idx === 1 && (
                      <text
                        x={initialX + sectionWidth * (len)}
                        y={initialY}
                        dominantBaseline={'central'}
                      >
                        {moment(record.properties?.createdDate).format('DD MMM')}
                      </text>
                    )
                  }
                </g>
              );
            }
          });
        })
      }
    </g>
  );
};

const TimeLineGraph = ({
  groupedSnapshots,
  selectedIcon,
  handleFilterSelect,
}:{
  groupedSnapshots: Array<GroupedSnapshotsType>;
  selectedIcon: 'week' | 'month' | 'year' | 'all';
  // eslint-disable-next-line no-unused-vars
  handleFilterSelect: (filter: 'week' | 'month' | 'year' | 'all') => void;
}) => {
  const classes = useStyles();
  const config: GraphConfigType = {
    initialX: -20,
    initialY: 10,
    sectionHeight: -70,
    sectionWidth: 140,
    circleRadius: 11,
  };
  const getExpanded = () => {
    let x = 0;
    groupedSnapshots.forEach((rr) => {
      if (rr.expanded) {
        x += rr.snapshots.length;
      } else {
        x += 2;
      }
    });
    return x;
  };
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.timeLineGraphContainer}>
        <div
          id={'timelinegraph'}
          className={classes.timeLineGraphSvg}
        >
          <div>
            <svg
              id="svg"
              xmlns="http://www.w3.org/2000/svg"
              className="run-records-graph"
              style={{
                minWidth: `${(config.sectionWidth * getExpanded() + 64) * 0.0625}rem`,
                maxWidth: '100vw',
                overflow: 'auto',
                flexDirection: 'column-reverse',
                height: 70,
              }}
            >
              <RenderItems
                graphConfig={config}
                data={groupedSnapshots}
                selectedFilter={selectedIcon}
                handleFilterSelect={handleFilterSelect}
              />
            </svg>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export { TimeLineGraph, };
