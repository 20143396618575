import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faDatabase, } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const TryNowText = () => {
  const linearGradient = 'linear-gradient(180deg, rgba(232,232,232,0.15730042016806722) 6%, rgba(218,218,218,0.33657212885154064) 41%, rgba(227,200,168,0.1741071428571429) 52%, rgba(255,145,13,0.14329481792717091) 82%)';

  return (
    <Box padding={2}>
      <Box display='flex' alignContent='ceneter' justifyContent='flex-start'>
        <FontAwesomeIcon
          icon={faDatabase}
          style={{
            color: '#ff910d',
            fontSize: '40px',
            marginRight: '10px',
            border: `1px solid #eaeaea`,
            background: linearGradient,
            padding: '10px',
            borderRadius: '5px',
          }}
        />
        <Typography
          variant={'h6'}
          style={{
            paddingTop: '2px',
            fontWeight: 'bolder',
          }}
        >
          Create your Free RosettaDB Cloud Account
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant={'body1'} >
          Sign up now for a free account and instantly access RosettaDB Cloud. Start migrating your schemas between Data Sources effortlessly. Join us today for seamless data management!
        </Typography>
        <Typography variant={'body1'} style={{ marginTop: '2rem',
          fontWeight: 'bolder', }}>
          What’s included:
        </Typography>
        <Box display='flex' alignContent={'center'} justifyContent={'flex-start'} marginTop={1}>
          <CheckCircleIcon style={{ marginRight: '5px', }} color='primary'/>
          <Typography variant={'body1'} style={{ marginTop: 1, }}>
            Multi database support
          </Typography>
        </Box>
        <Box display='flex' alignContent={'center'} justifyContent={'flex-start'} marginTop={1}>
          <CheckCircleIcon style={{ marginRight: '5px', }} color='primary'/>
          <Typography variant={'body1'} style={{ marginTop: 1, }}>
            Declarative Data Modeling
          </Typography>
        </Box>
        <Box display='flex' alignContent={'center'} justifyContent={'flex-start'} marginTop={1}>
          <CheckCircleIcon style={{ marginRight: '5px', }} color='primary'/>
          <Typography variant={'body1'} style={{ marginTop: 1, }}>
            Migrate your Databases
          </Typography>
        </Box>
        <Box display='flex' alignContent={'center'} justifyContent={'flex-start'} marginTop={1}>
          <CheckCircleIcon style={{ marginRight: '5px', }} color='primary'/>
          <Typography variant={'body1'} style={{ marginTop: 1, }}>
            Generate DDL
          </Typography>
        </Box>
        <Box display='flex' alignContent={'center'} justifyContent={'flex-start'} marginTop={1}>
          <CheckCircleIcon style={{ marginRight: '5px', }} color='primary'/>
          <Typography variant={'body1'} style={{ marginTop: 1, }}>
            Database Test Framework
          </Typography>
        </Box>
        <Box display='flex' alignContent={'center'} justifyContent={'flex-start'} marginTop={1}>
          <CheckCircleIcon style={{ marginRight: '5px', }} color='primary'/>
          <Typography variant={'body1'} style={{ marginTop: 1, }}>
            DBT Model Generator
          </Typography>
        </Box>
      </Box>

    </Box>
  );
};

export default TryNowText;
