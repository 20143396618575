import {
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';


const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      textAlign: 'center',
    },
  })
);

interface Props {
  size?: number;
  message?: string;
  secondaryMessage?: string;
  marginTop?: number;
}

export default function LoadingComponent(props: Props) {
  const classes = useStyles();
  const { message, secondaryMessage, marginTop, size, } = props;

  return (
    <Grid container>
      <Grid item xs={4}/>
      <Grid item xs={4} className={classes.loader} style={{ marginTop: marginTop !== undefined ? marginTop : 100, }}>
        <CircularProgress size={size ? size : 80}/>
        {
          message && (
            <Typography style={{
              color: '#949494',
              marginTop: 5,
            }}>{`${message} ...`}</Typography>
          )
        }
        {
          secondaryMessage && (
            <Typography style={{
              color: '#949494',
              marginTop: 8,
              fontSize: '10pt',
            }}>{secondaryMessage}</Typography>
          )
        }
      </Grid>
      <Grid item xs={4}/>
    </Grid>
  );
}
