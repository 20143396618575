import React, {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  createStyles,
  Grid,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Popper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { TableDesigner, } from '../TableDesigner';
import { SchemaTree, } from '../SchemaTree';
import {
  addIndexedUID,
  emptyTable,
  removeAdditionalFields,
  validateTable,
} from '../helpers';
import { Interfaces, } from '../../../config';
import { useSnackbar, } from 'notistack';
import {
  collectionServices,
  datasourceServices,
  gitConfigServices,
} from '../../../services';
import {
  Prompt,
  useHistory,
  useParams,
} from 'react-router-dom';
import { AxiosResponse, } from 'axios';
import { helpers, } from '../../../utils';
import LoadingComponent from '../../Loading';
import _ from 'lodash';
import { TabPanel, } from './TabPanel';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/snippets/yaml';
import 'ace-builds/src-noconflict/ext-error_marker';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import {
  stringify as yamlStringify,
  parse as yamlParser,
} from 'yaml';
import {
  ArrowDropDown,
  ArrowDropUp,
  FileCopy,
  Save,
} from '@material-ui/icons';
import { v4, } from 'uuid';
import { DeleteDialog, } from '../DeleteDialog';
import GitIcons from '../../AdminPanel/GitConfigs/GitIcons';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CodeIcon from '@material-ui/icons/Code';
import dbtIcon from '../../../assets/icons/dbt.svg';
import { withStyles, } from '@material-ui/core/styles';
import { DBTModels, } from '../DBTModels';

const StyledTab = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      '& svg': {
        marginRight: theme.spacing(1),
        marginTop: 5,
      },
    },
  },
}))(Tab);

const emptyDatabase: Interfaces.CollectionDatabase = {
  databaseProductName: '',
  databaseType: '',
  tables: [],
};

const emptyCollection: Interfaces.Collection = {
  name: '',
  version: '1',
  description: '',
  dataSourceId: '-1',
  database: emptyDatabase,
};

interface Params {
  id?: string;
}

const useStyles = makeStyles(() => createStyles({
  editorIcons: {
    color: '#736c5d',
    '&:hover': {
      color: '#f1e7c7',
    },
  },
}));

const AddCollection = () => {
  const history = useHistory();
  const { id, } = useParams<Params>();
  const { enqueueSnackbar, } = useSnackbar();
  const classes = useStyles();
  const inEditMode = !!id;

  const [unsavedChanges, setUnsavedChanges,] = useState(false);
  const [loading, setLoading,] = useState(true);
  const [collection, setCollection,] = useState<Interfaces.Collection>(emptyCollection);
  const [yamlCollection, setYamlCollection,] = useState<string>(yamlStringify(collection));
  const [selectedTable, setSelectedTable,] = useState<Interfaces.CollectionTable | null>(null);
  const [tableToDelete, setTableToDelete,] = useState<Interfaces.CollectionTable | null>(null);
  const [schemaToDelete, setSchemaToDelete,] = useState<string | null>(null);
  const [dataSources, setDataSources,] = useState<Array<Interfaces.JDBCDataSource>>([]);
  const [loadingDataSources, setLoadingDataSources,] = useState<boolean>(false);
  const [tablesFromDatasource, setTablesFromDatasource,] = useState<Array<string>>([]);
  const [loadingDatabase, setLoadingDatabase,] = useState(false);

  const [repos, setRepos,] = useState<Array<Interfaces.ExternalRepo>>([]);
  const [loadingRepos, setLoadingRepos,] = useState<boolean>(false);

  const [dbmsTypes, setDbmsTypes,] = useState<Array<Interfaces.JDBCDriver>>([]);
  const [dbmsColumnTypes, setDbmsColumnTypes,] = useState<Array<Interfaces.DBMSType>>([]);
  const [buttonValue, setButtonValue,] = useState('');
  const [disabled, setDisabled,] = useState(false);

  const handleSubmitSave = () => {
    const _collection: Interfaces.Collection = JSON.parse(JSON.stringify(collection));
    removeAdditionalFields(_collection);
    setUnsavedChanges(false);
    setDisabled(true);
    if (id) {
      collectionServices.updateCollection(id, _collection)
        .then(() => {
          setDisabled(false);
          enqueueSnackbar('success update', { variant: 'success', });
          history.push('/collections');
        })
        .catch((error) => {
          setDisabled(false);
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    } else {
      collectionServices.addCollection(_collection)
        .then(() => {
          setDisabled(false);
          enqueueSnackbar('success add', { variant: 'success', });
          history.push('/collections');
        })
        .catch((error) => {
          setDisabled(false);
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    }
  };

  const handleSubmitSaveAndDeploy = () => {
    const _collection: Interfaces.Collection = JSON.parse(JSON.stringify(collection));
    removeAdditionalFields(_collection);
    setUnsavedChanges(false);
    setDisabled(true);
    if (id) {
      collectionServices.updateCollection(id, _collection)
        .then(() => {
          setDisabled(false);
          enqueueSnackbar('success update', { variant: 'success', });
          history.push(`/collection/${collection.id}/deployment`);
        })
        .catch((error) => {
          setDisabled(false);
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    } else {
      collectionServices.addCollection(_collection)
        .then((response) => {
          setDisabled(false);
          enqueueSnackbar('success add', { variant: 'success', });
          response.data.data.id && history.push(`/collection/${response.data.data.id}/deployment`);
        })
        .catch((error) => {
          setDisabled(false);
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    }
  };

  const [tabValue, setTabValue,] = useState(0);

  const [open, setOpen,] = React.useState(false);
  const anchorRef = React.useRef(null);
  const _aceEditor = React.useRef(null);

  const handleClose = (event: { target: any; }) => {
    if (anchorRef.current && (anchorRef?.current as any).contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const fetchDataSources = () => {
    setLoadingDataSources(true);
    datasourceServices.fetchDataSources().then((result)=>{
      setDataSources(result.data.filter((db: Interfaces.BaseDataSource)=>db.type === 'JDBC'));
    }).catch((error)=>{
      enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
    }).finally(()=>{
      setLoadingDataSources(false);
    });
  };

  const fetchRepos = () => {
    setLoadingRepos(true);
    gitConfigServices.fetchGitConfigs()
      .then((result: AxiosResponse<{ data: Array<Interfaces.ExternalRepo> }>) => {
        setRepos(result.data.data);
      }).catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      }).finally(() => {
        setLoadingRepos(false);
      });
  };

  const fetchDBMSTypes = () => {
    datasourceServices.fetchSupportedDrivers()
      .then((result: AxiosResponse<Array<Interfaces.JDBCDriver>>) => {
        setDbmsTypes(result.data.filter((dbmsType: Interfaces.JDBCDriver) => dbmsType.names.indexOf('generic') === -1)
          .sort((a, b) => a.title.localeCompare(b.title)));
      }).catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  const fetchDBMSColumnsTypes = (dbmsType: string, updateCollection?: boolean) => {
    datasourceServices.fetchDBMSColumnTypes(dbmsType)
      .then((result: AxiosResponse<Array<Interfaces.DBMSType>>) => {
        const sorted = result.data.sort((a, b) => a.type.localeCompare(b.type));
        setDbmsColumnTypes(sorted);
        if (updateCollection) {
          setCollection({
            ...collection,
            database: {
              ...collection.database,
              databaseType: dbmsType,
            },
          });
        }
      }).catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      });
  };

  const fetchDatasourceDatabase = (dataSourceId: string) => {
    setLoadingDatabase(true);
    const originalTables = collection.database.tables.filter((table) => !tablesFromDatasource.includes(table.name));
    datasourceServices.fetchDatabase(dataSourceId)
      .then((result: AxiosResponse<Interfaces.CollectionDatabase>) => {
        setTablesFromDatasource(result.data.tables.map((table) => table.name));
        const newDB: Interfaces.CollectionDatabase={
          ...result.data,
        };
        const indexedNewCollection = addIndexedUID({
          ...emptyCollection,
          database: newDB,
        });
        const tables = _.merge(indexedNewCollection.database.tables, originalTables);
        setCollection({
          ...collection,
          database: {
            ...indexedNewCollection.database,
            tables: tables,
          },
        });
        fetchDBMSColumnsTypes(result.data.databaseType, false);
      }).catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        setCollection({
          ...collection,
          database: {
            ...collection.database,
            tables: originalTables,
          },
        });
      }).finally(() => {
        setLoadingDatabase(false);
      });
  };

  const handleTableSave = (table: Interfaces.CollectionTable, callback: boolean = true) => {
    let _tables = [...collection.database.tables,];
    if (table._new) {
      table._uuid = `collection.${table.schema || '(no-schema)'}.database.${table.name}`;
    }
    const { isValid, table: valid_table, } = validateTable(table, _tables, collection.database.databaseType, !!table._new);
    if (isValid) {
      const tableIdx = _tables.findIndex((_table) => _table._uuid === valid_table._uuid);
      if (tableIdx !== -1) {
        _tables[tableIdx] = valid_table;
      } else {
        _tables = [..._tables, valid_table,];
      }
      setCollection({
        ...collection,
        database: {
          ...collection.database,
          tables: _tables,
        },
      });
      setUnsavedChanges(true);
      if (callback) {
        enqueueSnackbar('Saved table', { variant: 'success', });
      }
    } else {
      if (callback) {
        enqueueSnackbar('Table validation failed', { variant: 'error', });
      }
    }
  };

  useEffect(() => {
    fetchDataSources();
    fetchRepos();
    if (id) {
      collectionServices.fetchCollectionById(id)
        .then((response: AxiosResponse<{ data: Interfaces.Collection }>) => {
          setCollection(addIndexedUID(response.data.data));
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (collection.dataSourceId !== '-1' && !inEditMode) {
      fetchDatasourceDatabase(collection.dataSourceId);
    } else {
      const originalTables = collection.database.tables.filter((table) => !tablesFromDatasource.includes(table.name));
      setCollection({
        ...collection,
        database: {
          ...collection.database,
          tables: originalTables,
        },
      });
      fetchDBMSTypes();
    }
  },[collection.dataSourceId,]);

  useEffect(() => {
    if (collection.database.databaseType) {
      fetchDBMSColumnsTypes(collection.database.databaseType);
    }
  }, [collection.database.databaseType,]);

  useEffect(() => {
    const tmpCollection: Interfaces.Collection = JSON.parse(JSON.stringify(collection));
    removeAdditionalFields(tmpCollection);
    setYamlCollection(yamlStringify(tmpCollection));
  }, [collection,]);

  useEffect(() => {
    if (_aceEditor?.current !== null && selectedTable) {
      const tmpData = yamlCollection.split('\n');
      let counter = 0;
      for (let i = 0; i < tmpData.length; i++) {
        if (tmpData[i].split(':').map((el) => el.trim()).includes(selectedTable.name) && tmpData[i].includes('name')) {
          if (selectedTable.schema) {
            if (tmpData[i + 1].includes(selectedTable.schema)) {
              counter = i;
              break;
            }
          } else {
            if (!tmpData[i + 1].includes('schema')) {
              counter = i;
              break;
            }
          }
        }
      }

      (_aceEditor.current as any)?.editor?.gotoLine(counter + 1, 0, true);
    }
  }, [selectedTable,]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Grid
      container
      direction='column'
      style={{
        paddingInline: 30,
        marginTop: 10,
      }}
    >
      <Prompt
        when={unsavedChanges}
        message="There are unsaved changes, do you wish to discard them?"
      />
      <DeleteDialog
        open={!!tableToDelete}
        title={'Delete table'}
        handleClose={() => setTableToDelete(null)}
        action={() => {
          const _tables = collection.database?.tables?.filter((table) => table?._uuid !== tableToDelete?._uuid);
          setCollection({
            ...collection,
            database: {
              ...collection.database,
              tables: _tables,
            },
          });
          enqueueSnackbar(`Successfully deleted table '${tableToDelete?.name}'`, { variant: 'info', });
          setTableToDelete(null);
        }}
      />
      <DeleteDialog
        open={!!schemaToDelete}
        title={`Delete schema ${schemaToDelete}`}
        handleClose={() => setSchemaToDelete(null)}
        action={() => {
          setCollection({
            ...collection,
            database: {
              ...collection.database,
              tables: collection.database.tables.filter((table) => table.schema !== schemaToDelete),
            },
          });
          enqueueSnackbar(`Successfully deleted schema '${schemaToDelete}'`, { variant: 'info', });
          setSchemaToDelete(null);
        }}
      />
      <form
        id={'collection-form'}
        onSubmit={(e) => {
          e.preventDefault();
          buttonValue === 'save' && handleSubmitSave();
          buttonValue === 'saveAndDeploy' && handleSubmitSaveAndDeploy();
        }}
      >
        <Grid container xs={12} style={{
          background: 'white',
          padding: '10px 5px 5px 5px',
        }}>
          <Grid item xs={4} style={{ paddingRight: 7, }}>
            <TextField
              label="Collection Name"
              value={collection.name}
              onChange={(e) => {
                setCollection({
                  ...collection,
                  name: e.target.value,
                });
                setUnsavedChanges(true);
              }}
              id="outlined-size-small"
              variant="outlined"
              size="small"
              style={{ marginBottom: 10, }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={3} style={{ paddingRight: 7, }}>
            <TextField
              id="outlined-select-currency"
              select
              label="Data Source"
              size='small'
              fullWidth
              value={collection.dataSourceId}
              onChange={(e) => {
                setCollection({
                  ...collection,
                  dataSourceId: e.target.value,
                });
                setUnsavedChanges(true);
              }}
              defaultValue={-1}
              variant="outlined"
              disabled={loadingDataSources || inEditMode}
              InputProps={{
                startAdornment: loadingDataSources ? <CircularProgress size={20} style={{ marginRight: 10, }} /> : '',
              }}
            >
              <MenuItem key='-1' value='-1'>
                None
              </MenuItem>
              {dataSources.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.driverName} - {option.databaseName} ({option.name})
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {collection?.dataSourceId === '-1' && <Grid item xs={2} style={{ paddingRight: 7, }} direction='row'>
            <TextField
              id="outlined-select-currency"
              select
              label="DBMS Type"
              size='small'
              fullWidth
              value={collection?.database?.databaseType}
              onChange={(e) => {
                fetchDBMSColumnsTypes(e.target.value, true);
                setUnsavedChanges(true);
              }}
              variant="outlined"
              disabled={loadingDataSources}
              required
              InputProps={{
                startAdornment: loadingDataSources ? <CircularProgress size={20} style={{ marginRight: 10, }} /> : '',
              }}
            >
              {dbmsTypes.map((option) => (
                <MenuItem key={option?.names[0]} value={option?.names[0]}>
                  {option?.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          }
          <Grid item xs direction='row'>
            <TextField
              id="outlined-select-repo"
              select
              label="Git Repo"
              size='small'
              fullWidth
              value={collection.externalRepoId}
              onChange={(e) => {
                setCollection({
                  ...collection,
                  externalRepoId: e.target.value,
                });
                setUnsavedChanges(true);
              }}
              variant="outlined"
              disabled={loadingRepos}
              defaultValue={-1}
              InputProps={{
                startAdornment: loadingRepos ? <CircularProgress size={20} style={{ marginRight: 10, }} /> : '',
              }}
            >
              <MenuItem key='-1' value='-1'>
                None
              </MenuItem>
              {repos.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {<GitIcons type={option?.type} height={20} style={{ marginRight: '10px', }}/>} {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <TextField
            label="Collection Description"
            id="outlined-size-small"
            variant="outlined"
            value={collection.description}
            onChange={(e) => {
              setCollection({
                ...collection,
                description: e.target.value,
              });
              setUnsavedChanges(true);
            }}
            size='small'
            fullWidth
          />
        </Grid>
      </form>
      <Grid container xs={12} style={{ marginTop: 10, }}>
        <Grid item style={{ width: '300px', }}>
          <SchemaTree
            collection={collection}
            handleTableSelect={(table) => {
              if (selectedTable) {
                handleTableSave(selectedTable, false);
              }
              setSelectedTable(table);
            }}
            handleDeleteTable={(_table) => {
              if (_table._uuid == selectedTable?._uuid) {
                setSelectedTable(null);
              }
              setTableToDelete(_table);
            }}
            disabledAddTableButton={collection.database.databaseType === ''}
            loadingReason={loadingDatabase ? 'Loading schema' : undefined}
            handleDeleteSchema={(schema) => {
              setSchemaToDelete(schema);
            }}
          />
        </Grid>
        <Grid item xs style={{ background: 'white', }}>
          <Tabs indicatorColor={'primary'} value={tabValue} onChange={(event, newValue) => setTabValue(newValue)} style={{ paddingLeft: 20, }}>
            <StyledTab
              icon={<ListAltIcon color='primary'/>}
              label="Table designer"
              disabled={loadingDatabase || collection.database.databaseType === ''}
            />
            <StyledTab
              icon={<CodeIcon color='primary'/>}
              label="DBML Editor"
              disabled={loadingDatabase || collection.database.databaseType === ''}
            />
            <StyledTab
              icon={
                <img
                  style={{
                    height: 16,
                    width: 16,
                    marginRight: 10,
                    marginTop: 5,
                  }}
                  src={dbtIcon}
                  alt={'dbt'}
                />
              }
              label="DBT Model"
              disabled={loadingDatabase || collection.database.databaseType === ''}
            />
          </Tabs>
          <div
            style={{
              padding: 20,
              borderRadius: 2,
              minHeight: 'calc(-360px + 100vh)',
              maxHeight: 'calc(-360px + 100vh)',
              marginTop: 10,
            }}
          >
            <TabPanel value={tabValue} index={0}>
              {
                selectedTable ? (
                  <TableDesigner
                    table={selectedTable}
                    updateTable={(table) => setSelectedTable(table)}
                    tableColumnTypes={dbmsColumnTypes}
                    dbmsType={collection.database.databaseType}
                    handleTableSave={(table: Interfaces.CollectionTable, callback: boolean = true) => {
                      handleTableSave(table, callback);
                      setSelectedTable(table);
                    }}
                  />
                ) : (
                  collection.database.databaseType !== '' ? (
                    <div style={{ textAlign: 'center',
                      paddingTop: 'calc(-750px + 100vh)', }}>
                      <Typography>
                      Please select a table to edit or
                        <Typography
                          onClick={() => setSelectedTable({
                            ...emptyTable,
                            _uuid: v4(),
                          })}
                          style={{ cursor: 'pointer', }}
                          color='primary'
                        >
                        add a new one!
                        </Typography>
                      </Typography>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center',
                      paddingTop: 'calc(-750px + 100vh)', }}>
                      <Typography variant='h6' style={{ opacity: 0.5, }} >
                      Please select a database type
                      </Typography>
                    </div>
                  )
                )
              }
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <div style={{ position: 'relative', }}>
                <AceEditor
                  ref={_aceEditor}
                  mode="yaml"
                  width="auto"
                  fontSize={16}
                  height={'calc(-400px + 100vh)'}
                  theme={'tomorrow'}
                  value={yamlCollection}
                  onChange={(e) => setYamlCollection(e)}
                  onBlur={() => {
                    try {
                      const _collection = yamlParser(yamlCollection) as Interfaces.Collection;
                      setSelectedTable(null);
                      addIndexedUID(_collection);
                      setCollection(_collection);
                    } catch (ignore) {
                      enqueueSnackbar('Make sure your YAML config is correct!', { variant: 'error', });
                    }
                  }}
                  setOptions={{
                    tabSize: 2,
                    autoScrollEditorIntoView: true,
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: -10,
                    right: 25,
                  }}
                >
                  <Tooltip title={'Save changes'} placement={'top'}>
                    <IconButton
                      style={{
                        marginTop: 6,
                        fontWeight: 1000,
                      }}
                      onClick={() => {
                        try {
                          const _collection = yamlParser(yamlCollection) as Interfaces.Collection;
                          addIndexedUID(_collection);
                          setCollection(_collection);
                          setUnsavedChanges(true);
                          setSelectedTable( null);
                          setTabValue(0);
                        } catch (ignore) {
                          enqueueSnackbar('Make sure your YAML config is correct!', { variant: 'error', });
                        }
                      }}
                    >
                      <Save className={classes.editorIcons} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Copy yaml content to clipboard'} placement={'top'}>
                    <IconButton
                      style={{
                        marginTop: 6,
                        fontWeight: 1000,
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(yamlCollection)
                          .then(() => {
                            enqueueSnackbar('Copied to clipboard', { variant: 'info', });
                          });
                      }}
                    >
                      <FileCopy className={classes.editorIcons} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <DBTModels
                collection={collection}
                selectedTable={selectedTable}
              />
            </TabPanel>
          </div>
        </Grid>
      </Grid>

      <div>
        <Button
          style={{ marginTop: 10,
            marginLeft: 15,
            float: 'right', }}
          variant='contained'
          onClick={() => history.push('/collections')}
        >
            Cancel
        </Button>
        <React.Fragment >
          <ButtonGroup
            disabled={disabled}
            style={{
              marginTop: 10,
              float: 'right',
            }}
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button
              type={'submit'}
              form={'collection-form'}
              color={'primary'}
              onClick={()=> setButtonValue('saveAndDeploy')}
            >
              Save and Deploy
            </Button>
            <Button
              color={'primary'}
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={() => setOpen((prevOpen) => !prevOpen)}
            >
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement, }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Button
                      color={'primary'}
                      variant={'outlined'}
                      type={'submit'}
                      form={'collection-form'}
                      onClick={()=> setButtonValue('save')}
                      style={{
                        marginBottom: 1,
                        width: 210,
                      }}
                    >
                      Save
                    </Button>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </React.Fragment>
      </div>
    </Grid>
  );
};

export default AddCollection;
