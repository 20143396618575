import React, { useEffect, } from 'react';
import { withRouter, } from 'react-router';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Description as DescriptionIcon,
  Search as SearchIcon,
  Storage as StorageIcon,
} from '@material-ui/icons';
import { helpers, } from '../../utils';
import {
  cards,
  rosettaCards,
} from './mockData';
import {
  useStyles,
  StyledButton,
  StyledCardContent,
  CardTitle,
} from './styles';
import { dataServices, } from '../../services';
import { useSnackbar, } from 'notistack';
import { CircularProgress, } from '@material-ui/core';
import { useAuth, } from '../../context';
import { RosettaIInfoSection, } from './RosettaIInfoSection';
import { DriverInfoSection, } from './DriverInfoSection';

const emptyStats = {
  activeScheduleCount : 0,
  catalogCount : 0,
  dataSourceCount : 0,
  tagCount : 0,
  collectionCount: 0,
  deploymentCount: 0,
};

function Dashboard(props: any) {
  const classes = useStyles();
  const {  health, deploymentType, } = useAuth();
  const { enqueueSnackbar, } = useSnackbar();
  const [loading, isLoading,] = React.useState(false);
  const [stats, setSetStats,] = React.useState(emptyStats);

  const fetchStats = async () => {
    isLoading(true);
    return await dataServices.fetchDashboardStats()
      .then((response) => {
        setSetStats(response.data.data?.statistics);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        isLoading(false);
      });
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div className={classes.dashboard}>
      <Grid
        container
        className={classes.root}
        spacing={2}
        justifyContent="center"
      >
        <Grid container item spacing={2} md={4} xs={12}>
          {loading ? (
            <Box className={classes.centerDiv}>
              <CircularProgress size={120} />
            </Box>
          ) : (
            <>
              { health.deploymentType === 'local' ? (
                <>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        props.history.push('/data-sources');
                      }}
                    >
                      <StorageIcon
                        fontSize="medium"
                        style={{
                          color: 'white',
                        }}
                      />
                      <Typography>{stats.dataSourceCount} Data Sources</Typography>
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        props.history.push('/catalogs');
                      }}
                    >
                      <SearchIcon
                        style={{
                          color: 'white',
                        }}
                        fontSize="medium"
                      />
                      <Typography>{stats.catalogCount} Catalogs</Typography>
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        props.history.push('/tags');
                      }}
                    >
                      <DescriptionIcon
                        style={{
                          color: 'white',
                        }}
                        fontSize="medium"
                      />
                      <Typography>{stats.tagCount} Tags</Typography>
                    </StyledButton>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        props.history.push('/data-sources');
                      }}
                    >
                      <StorageIcon
                        fontSize="medium"
                        style={{
                          color: 'white',
                        }}
                      />
                      <Typography>{stats.dataSourceCount} Data Sources</Typography>
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        props.history.push('/collections');
                      }}
                    >
                      <StorageIcon
                        fontSize="medium"
                        style={{
                          color: 'white',
                        }}
                      />
                      <Typography>{stats.collectionCount} Collections</Typography>
                    </StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        props.history.push('/collections');
                      }}
                    >
                      <StorageIcon
                        fontSize="medium"
                        style={{
                          color: 'white',
                        }}
                      />
                      <Typography>{stats.deploymentCount} Deployments</Typography>
                    </StyledButton>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
        {deploymentType === 'cloud' ? <RosettaIInfoSection /> : <DriverInfoSection />}
      </Grid>
      <Grid container justifyContent="center" spacing={6}>
        {(deploymentType === 'cloud' ? rosettaCards : cards).map(({ title, Logo, description, uri, }: any) => (
          <Grid item key={title} xs={12} md={4}>
            <Card
              style={{
                minHeight: '290px',
                maxHeight: '290px',
                cursor: 'pointer',
              }}
              onClick={() => uri && window.open(uri, '_blank', 'noopener,noreferrer')}
            >
              <StyledCardContent>
                <Logo />
                <CardTitle
                  variant="h6"
                >
                  {title}
                </CardTitle>
              </StyledCardContent>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  align="center"
                >
                  {description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withRouter(Dashboard);
