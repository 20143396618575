import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  Tab,
} from '@material-ui/core';

import {
  TabContext,
  TabList,
} from '@material-ui/lab';

import { useStyles, } from './styles';
import { Interfaces, } from '../../../../config';
import { Headers, } from './Headers';


const ApiCall = ({
  alert,
  setAlert,
  handleValidation,
}: {
  alert: Interfaces.Alert;
  // eslint-disable-next-line no-unused-vars
  setAlert: (alert: Interfaces.Alert) => void;
  // eslint-disable-next-line no-unused-vars
  handleValidation: (value: boolean) => void;
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep,] = useState(2);

  const methods = [
    {
      method: 'GET',
    },
    {
      method: 'POST',
    },
  ];

  useEffect(() => {
    const method = alert.outputRule.rule['method'];
    const url = alert.outputRule.rule['url'];

    const isValid = !!(
      method && method !== ''
      && url && url !== ''
    );
    handleValidation(isValid);
  }, [alert.outputRule,]);

  return (
    <>
      <Box
        display="flex"
        width="100%"
        marginBottom="1em"
      >
        <Box display="flex" width="120%">
          <FormControl variant="outlined" className={classes.methodInput} size="small">
            <InputLabel id="demo-simple-select-label">Method</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Method"
              value={alert.outputRule.rule['method']}
              onChange={(event) => {
                setAlert({
                  ...alert,
                  outputRule: {
                    ...alert.outputRule,
                    rule: {
                      ...alert.outputRule.rule,
                      ['method']: event.target.value,
                    },
                  },
                });
              }}
            >
              {
                methods.map((option) => (
                  <MenuItem key={option.method} value={option.method}>
                    {option.method}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <TextField
            id="file-path"
            label=""
            variant="outlined"
            value={alert.outputRule.rule['url']}
            onChange={(event) => {
              setAlert({
                ...alert,
                outputRule: {
                  ...alert.outputRule,
                  rule: {
                    ...alert.outputRule.rule,
                    ['url']: event.target.value,
                  },
                },
              });
            }}
            className={classes.urlInput}
            placeholder="Url"
            fullWidth
            size="small"
          />
        </Box>
      </Box>
      <Box sx={{
        borderBottom: 1,
        borderColor: 'divider',
      }}/>
      <Headers
        alert={alert}
        onAddAttribute={((value) => {
          if (alert.outputRule.rule['headers']) {
            if (alert.outputRule.rule['headers'][value.key]) {
              return;
            }
          }
          setAlert({
            ...alert,
            outputRule: {
              ...alert.outputRule,
              rule: {
                ...alert.outputRule.rule,
                ['headers']: {
                  ...alert.outputRule.rule['headers'],
                  [value.key]: [value.value,],
                },
              },
            },
          });
        })}
        onRemoveAttribute={(key) => {
          const tmpHeaders = alert.outputRule.rule['headers'];
          if (tmpHeaders) {
            delete tmpHeaders[key];
            setAlert({
              ...alert,
              outputRule: {
                ...alert.outputRule,
                rule: {
                  ...alert.outputRule.rule,
                  ['headers']: tmpHeaders,
                },
              },
            });
          }
        }}
      />
      <TabContext value={activeStep as any}>
        <TabList
          TabIndicatorProps={{ style: { backgroundColor: '#3f51b5', }, }}
          onChange={(event: any, value: number) => {
            setActiveStep(value);
          }}
          aria-label="content-tabs">
          <Tab
            label="Body"
            value={2}
          />
        </TabList>
      </TabContext>
      <Box marginTop="1em">
        {activeStep === 2 &&
          <Box>
            <TextField
              value={alert.outputRule.rule['body']}
              onChange={(event) => {
                setAlert({
                  ...alert,
                  outputRule: {
                    ...alert.outputRule,
                    rule: {
                      ...alert.outputRule.rule,
                      ['body']: event.target.value,
                    },
                  },
                });
              }}
              id="body"
              multiline
              minRows={8}
              maxRows={35}
              placeholder="Add content"
              variant="outlined"
              fullWidth />
          </Box>}
      </Box>
    </>
  );
};

export { ApiCall, };
