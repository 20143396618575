import React, {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RunHistoryTableHead, } from './RunHistoryTableHead';
import moment from 'moment/moment';
import { useHistory, } from 'react-router-dom';
import LoadingComponent from '../../../Loading';
import { catalogsServices, } from '../../../../services';
import { AxiosResponse, } from 'axios';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../helpers';
import { Interfaces, } from '../../../../config';
import { useStyles, } from './styles';


const RunHistoryTable = ({
  catalogId,
}: {
  catalogId: string;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, } = useSnackbar();
  const [isLoading, setIsLoading,] = useState(false);
  const [runHistory, setRunHistory,] = useState<Array<any>>([]);
  const [hasError, setHasError,] = useState(false);

  const fetchRunHistory = () => {
    setIsLoading(true);
    catalogsServices.fetchRunHistory(catalogId)
      .then((response: AxiosResponse<Array<Interfaces.Schema>>) => {
        const sortedData = response.data.sort((a, b) => {
          return new Date(a.properties?.createdDate).getTime() - new Date(b.properties?.createdDate).getTime();
        });
        setRunHistory(sortedData);
      })
      .catch((error) => {
        setHasError(true);
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchRunHistory();
    return () => {
      setRunHistory([]);
      setHasError(false);
    };
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (hasError) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
      >
        <Grid
          item
          xs={4}
        >
          <Typography>
            Could not fetch run history
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Button
            disabled={isLoading}
            onClick={() => fetchRunHistory()}
            variant={'outlined'}
            color={'primary'}
          >
            Retry
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container style={{ height: 'calc(100vh - 164px)', }}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <TableContainer className={`${classes.table} ${classes.stickyTable}`}>
            <Table stickyHeader aria-label="enhanced table">
              <RunHistoryTableHead classes={classes} />
              <TableBody>
                {
                  runHistory.map((item: any, index) => (
                    <TableRow
                      key={index}
                      className={classes.tableRow}
                      onClick={() => history.push({
                        pathname: `/search/details/${item.properties?.runId}`,
                        state: {
                          item: {
                            name: item.properties?.catalogName,
                            dataSourceName: item.properties?.dataSourceName,
                            tags: item.tags,
                            labels: item.labels,
                          },
                          row: index + 1,
                        },
                      })}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align="left">{item.properties?.catalogId}</TableCell>
                      <TableCell align="left">{item.properties?.catalogName}</TableCell>
                      <TableCell align="left">{item.properties?.dataSourceName}</TableCell>
                      <TableCell align="left">{item.properties?.runId}</TableCell>
                      <TableCell
                        sortDirection={'asc'}
                        align="left"
                      >
                        {
                          moment(item.properties?.createdDate).format('YYYY-MM-DD HH:mm:ss')
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export { RunHistoryTable, };
