import React from 'react';
import { Interfaces, } from '../../../config';
import { TreeItems, } from './TreeItems';
import { TreeItem, } from '@material-ui/lab';

const RenderTree = ({
  collectionName,
  groupedTables,
}: {
    collectionName: string;
    groupedTables: Interfaces.CollectionSchema;
}) => {
  return (
    <>
      {
        Object.keys(groupedTables).map((schema) => (
          <TreeItem
            key={`${collectionName}.${schema || 'tables'}`}
            nodeId={`${collectionName}.${schema || 'tables'}`}
            label={<TreeItems.Schema label={schema || 'tables'}/>}
          >
            {
              groupedTables[schema].map((table: Interfaces.CollectionTable) => (
                <TreeItem
                  key={`${collectionName}.${table._uuid}`}
                  nodeId={`${collectionName}.${table._uuid}`}
                  label={<TreeItems.Table label={table.name}/>}
                >
                  {
                    table.columns.map((column) => (
                      <TreeItem
                        key={`${collectionName}.${table.name}.${column._uuid}`}
                        nodeId={`${collectionName}.${table.name}.${column._uuid}`}
                        label={<TreeItems.Column label={column.name}/>}
                      />
                    ))
                  }
                </TreeItem>
              ))
            }
          </TreeItem>
        ))
      }
    </>
  );
};

export { RenderTree, };
