import React from 'react';
import { Interfaces, } from '../../../config';
import { TreeItems, } from './TreeItems';
import { TreeItem, } from '@material-ui/lab';
import { emptyTable, } from '../helpers';

const RenderTree = ({
  collectionName,
  groupedTables,
  handleTableSelect,
  handleDeleteTable,
  handleDeleteSchema,
}: {
  collectionName: string;
  groupedTables: Interfaces.CollectionSchema;
  // eslint-disable-next-line no-unused-vars
  handleTableSelect: (table: Interfaces.CollectionTable) => void;
  // eslint-disable-next-line no-unused-vars
  handleDeleteTable: (table: Interfaces.CollectionTable) => void;
  // eslint-disable-next-line no-unused-vars
  handleDeleteSchema: (schema: string) => void;
}) => {
  return (
    <>
      {
        Object.keys(groupedTables).map((schema) => (
          <TreeItem
            key={`${collectionName}.${schema || 'tables'}`}
            nodeId={`${collectionName}.${schema || 'tables'}`}
            label={
              <TreeItems.Schema
                label={schema || 'tables'}
                handleAddTable={() => handleTableSelect({
                  ...emptyTable,
                  schema: schema,
                })}
                handleDeleteSchema={() => handleDeleteSchema(schema)}
              />
            }
          >
            {
              groupedTables[schema].map((table: Interfaces.CollectionTable) => (
                <TreeItem
                  key={`${collectionName}.${table._uuid}`}
                  nodeId={`${collectionName}.${table._uuid}`}
                  label={
                    <TreeItems.Table
                      label={table.name}
                      handleDelete={() => handleDeleteTable(table)}
                    />
                  }
                  onClick={() => handleTableSelect(table)}
                >
                  {
                    table.columns.map((column) => (
                      <TreeItem
                        key={`${collectionName}.${table.name}.${column._uuid}`}
                        nodeId={`${collectionName}.${table.name}.${column._uuid}`}
                        label={<TreeItems.Column label={column.name}/>}
                        onClick={() => handleTableSelect(table)}
                      />
                    ))
                  }
                </TreeItem>
              ))
            }
          </TreeItem>
        ))
      }
    </>
  );
};

export { RenderTree, };
