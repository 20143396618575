import React, { useState, } from 'react';
import {
  CircularProgress,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Delete, } from '@material-ui/icons';
import { useStyles, } from './styles';
import { Interfaces, } from '../../../config';
import { useSnackbar, } from 'notistack';
import { scheduleServices, } from '../../../services';
import { helpers, } from '../../../utils';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation';

interface TableRowScheduleProps {
  schedule: Interfaces.CatalogScheduleJob;
  index: number;
  writeAccess: boolean;
  adminAccess: boolean;
  fetchSchedules: () => void;
}


const TableRowSchedule = ({ schedule,
  index,
  writeAccess,
  adminAccess,
  fetchSchedules, } : TableRowScheduleProps) => {
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const [loadingOneSchedule, setLoadingOneSchedule,] = useState<boolean>(false);
  const [selectedSchedule, setSelectedSchedule,] = useState<Interfaces.CatalogScheduleJob | null>(null);

  const deleteSchedule = (catalogId: string) => {
    setLoadingOneSchedule(true);
    scheduleServices.deleteSchedule(catalogId)
      .then(() => {
        enqueueSnackbar('Schedule deleted successfully', { variant: 'success', });
        fetchSchedules();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingOneSchedule(false);
      });
  };

  const deactivateSchedule = (catalogId: string) => {
    setLoadingOneSchedule(true);
    scheduleServices.deactivateSchedule(catalogId)
      .then(() => {
        enqueueSnackbar('Schedule deactivated successfully', { variant: 'success', });
        fetchSchedules();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingOneSchedule(false);
      });
  };

  const activateSchedule = (catalogId: string) => {
    setLoadingOneSchedule(true);
    scheduleServices.activateSchedule(catalogId)
      .then(() => {
        enqueueSnackbar('Schedule activated successfully', { variant: 'success', });
        fetchSchedules();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoadingOneSchedule(false);
      });
  };

  const deleteAction = () => {
    if (loadingOneSchedule) { return; }
    selectedSchedule && deleteSchedule(selectedSchedule.id);
    setSelectedSchedule(null);
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={schedule.id}
      >
        <TableCell padding="checkbox">
          <Typography variant="subtitle2" align="center">
            {index + 1}
          </Typography>
        </TableCell>
        <TableCell align="left">
          {schedule.inputCatalogMetadata?.name}
        </TableCell>
        <TableCell align="left">
          {schedule.cronExpression}
        </TableCell>
        <TableCell align="left">{schedule.inputCatalogMetadata?.lastRun}</TableCell>
        <TableCell align="left">
          {schedule.nextRun}
        </TableCell>
        <TableCell>
          <div>
            {
              adminAccess && (
                <Tooltip title="Delete">
                  {loadingOneSchedule ? (
                    <IconButton className={classes.iconStyle}>
                      <CircularProgress
                        color="inherit"
                        size={36}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setSelectedSchedule(schedule);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Tooltip>
              )
            }
            {
              writeAccess && (
                <Tooltip title={schedule.active ? 'Deactivate' : 'Activate'}>
                  <Switch
                    color='primary'
                    checked={schedule.active}
                    onClick={() => {
                      if (schedule.active) {
                        deactivateSchedule(schedule.id);
                      } else {
                        activateSchedule(schedule.id);
                      }
                    }}
                  />
                </Tooltip>
              )
            }
          </div>
        </TableCell>
      </TableRow>
      <ModalConfirmation
        open={!!selectedSchedule}
        onClose={() => setSelectedSchedule(null)}
        title={'Are you sure you want to delete the Schedule?'}
        onBtnClick={() => deleteAction()}
        colorBtn={'secondary'}
        btnText={'Delete'}
        onCancelBtnClick={() => setSelectedSchedule(null)} />
    </React.Fragment>
  );
};

export default TableRowSchedule;
