import React from 'react';
import {
  TabContext,
  TabList,
} from '@material-ui/lab';
import {
  Box,
  Tab,
} from '@material-ui/core';
import { Interfaces, } from '../../../../../config';

const HeaderTabsView = ({
  activeStep,
  setActiveStep,
  loadingSchema,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  activeStep: number;
  // eslint-disable-next-line no-unused-vars
  setActiveStep: (step: number) => void;
  schemaError: boolean;
  loadingSchema: boolean;
  inEditMode: boolean;
}) => {
  return (
    <Box sx={{ display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center', }}>
      <Box>
        <TabContext value={activeStep as any}>
          <TabList
            TabIndicatorProps={{ style: { backgroundColor: '#3f51b5', }, }}
            onChange={(event: any, value: number) => {
              setActiveStep(value);
            }}
            aria-label="basic tabs example">
            <Tab
              label="Catalog"
              value={1}
            />
            <Tab
              disabled={loadingSchema}
              label="ER Diagram"
              value={2}
            />
          </TabList>
        </TabContext>
      </Box>
    </Box>
  );
};

export { HeaderTabsView, };
