
import { v4, } from 'uuid';
import { Interfaces, } from '../../config';
import { ColumnTypes, } from './TableDesigner/ColumnTypes';

export const emptyTable: Interfaces.CollectionTable = {
  name: '',
  type: '',
  description: '',
  schema: '',
  columns: [],
  _uuid: undefined,
  _new: true,
};

export const emptyColumn: Interfaces.CollectionTableColumn = {
  name: '',
  typeName: '',
  primaryKey: false,
  nullable: true,
  isAutoincrement: false,
  scale: 0,
  precision: 0,
  foreignKeys: [],
  tests: { assertion: [], },
};

export const emptyTest: Interfaces.CollectionTableTest = {
  operator: '',
  value: '',
  expected: 0,
};

export const addUUID = (collection: Interfaces.Collection) => {
  collection.database.tables.map((table) => {
    table._uuid = v4();
    table.columns.map((column) => {
      column._uuid = v4();
      column.tests && column.tests.assertion.map((test) => {
        test._uuid = v4();
      });
    });
  });
  return collection;
};

export const addIndexedUID = (collection: Interfaces.Collection) => {
  collection.database.tables.map((table) => {
    table._uuid = `collection.${table.schema || '(no-schema)'}.database.${table.name}`;
    table.columns.map((column) => {
      column._uuid = `${table._uuid}.${column.name}`;
      column.tests && column.tests.assertion.map(async (test) => {
        test._uuid = `${column._uuid}.tests.${JSON.stringify(test)}`;
      });
    });
  });
  return collection;
};

export const removeAdditionalFields = (collection: Interfaces.Collection) => {
  collection.database.tables.forEach((table) => {
    delete table._uuid;
    delete table._errors;
    delete table._new;
    if (table.schema === '(no-schema)') {
      delete table.schema;
    }
    table.columns.forEach((column) => {
      delete column._uuid;
      delete column._errors;
      column.tests && column.tests.assertion.forEach((test) => {
        delete test._uuid;
      });
    });
  });
};

export const test_table: Interfaces.CollectionTable = {
  ...emptyTable,
  name: 'test_table',
  schema: 'test_dataset',
  columns: [
    {
      ...emptyColumn,
      name: 'test_column_1',
      typeName: 'string',
      _uuid: v4(),
    },
    {
      ...emptyColumn,
      name: 'test_column_2',
      typeName: 'integer',
      primaryKey: true,
      _uuid: v4(),
      tests: {
        assertion: [
          {
            ...emptyTest,
          },
        ],
      },
    },
  ],
};

export const isTableNameUsed = (table: Interfaces.CollectionTable, tables: Array<Interfaces.CollectionTable>, newTable: boolean) => {
  const sameTables = tables.filter((_table) => _table._uuid === table._uuid);
  return newTable ? sameTables.length !== 0 : sameTables.length > 1;
};

export const validateTable = (table: Interfaces.CollectionTable, tables: Array<Interfaces.CollectionTable>, dbmsType: any, newTable: boolean) => {
  const uniqueValues = new Set(table.columns.map((column) => column.name));
  const validColumns = uniqueValues.size === table.columns.length;
  let invalidColumnTypes = false;

  table.columns.forEach((column) => {
    column._errors = [];
    if (!ColumnTypes[dbmsType] || !ColumnTypes[dbmsType][column.typeName.toLowerCase()]) {
      invalidColumnTypes = true;
      column._errors.push('Column type not supported');
    }
    let counter = 0;
    if (!validColumns) {
      table.columns.forEach((_column2) => {
        if (column.name === _column2.name) {
          counter += 1;
        }
        if (counter > 1) {
          column._errors?.push('Column name already in use');
        }
      });
    }
  });

  const tableNameValid = !isTableNameUsed(table, tables, newTable);

  if (!tableNameValid) {
    table._errors = ['Table name already used',];
  } else {
    table._errors = undefined;
  }

  if (uniqueValues.size === table.columns.length && tableNameValid && !invalidColumnTypes) {
    delete table._new;
    return  {
      isValid: true,
      table: table,
    };
  } else {
    return {
      isValid: false,
      table: table,
    };
  }
};
