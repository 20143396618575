import React, {
  useEffect,
  useState,
} from 'react';
import { CollectionTableHead, } from './CollectionTableHead';
import { CollectionTableToolbar, } from './CollectionTableToolbar';
import {
  Chip,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  Delete,
  Edit,
} from '@material-ui/icons';
import { Interfaces, } from '../../../config';
import { collectionServices, } from '../../../services';
import { useHistory, } from 'react-router-dom';
import { useSnackbar, } from 'notistack';
import { helpers, } from '../../../utils';
import { useAuth, } from '../../../context';
import ModalConfirmation from '../../ModalConfirmation/ModalConfirmation';
import { DataSourceLogo, } from '../../DatasourceIcon/DatasourceIcon';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: '10px',
  },
  table: {
    minWidth: 750,
  },
  dataSourceActionsCell: {
    minWidth:'154px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const CollectionTable = ({
  collections,
  updateCollections,
}: {
  collections: Array<Interfaces.Collection>;
  // eslint-disable-next-line no-unused-vars
  updateCollections: (collections: Array<Interfaces.Collection>) => void;
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [filteredCollections, setFilteredCollections,] = useState<Array<Interfaces.Collection>>([]);
  const [collectionToDelete, setCollectionToDelete,] = useState<Interfaces.Collection | null>(null);
  const [deletePopupState, setDeletePopupState,] = useState(false);
  const [filters, setFilters,] = useState({
    checked: false,
    order: 'asc',
    orderBy: 'connectionName',
    page: 0,
    rowsPerPage: 5,
  });
  const { enqueueSnackbar, } = useSnackbar();

  const { isAuthorized, deploymentType, } = useAuth();
  const [writeAccess,] = React.useState(isAuthorized('ADAPTIVE_COL_WRITE'));
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_COL_READ'));
  const [readDSAccess,] = React.useState(isAuthorized('ADAPTIVE_DS_READ'));
  const [readDepAccess,] = React.useState(isAuthorized('ADAPTIVE_DEP_READ'));
  const [adminAccess,] = React.useState(isAuthorized('ADAPTIVE_ADMIN'));
  const [executeAccess,] = React.useState(isAuthorized('ADAPTIVE_COL_EXECUTE'));

  useEffect(() => {
    setFilteredCollections(collections);
  }, []);

  const descendingComparator = (a: any, b: any, orderBy: any) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: any, orderBy: any) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (dataSources: any, comparator: any) => {
    const stabilizedThis = dataSources.map((el: any, index: number) => [
      el,
      index,
    ]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  };

  const handleSearch = (event: any) => {
    let filteredData = collections.filter((value) =>
      value.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredCollections(filteredData);
  };

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = filters.orderBy === property && filters.order === 'asc';
    setFilters({
      ...filters,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setFilters({
      ...filters,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setFilters({
      ...filters,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };


  const handleEdit = (collection: Interfaces.Collection) => {
    history.push(`/collection/edit/${collection.id}`);
  };

  const handleDeletePopup = (collection: Interfaces.Collection | null) => {
    setDeletePopupState(!deletePopupState);
    setCollectionToDelete(collection);
  };

  const deleteAction = () => {
    if (collectionToDelete && collectionToDelete.id) {
      collectionServices.deleteCollection(collectionToDelete.id)
        .then(() => {
          setDeletePopupState(false);
          enqueueSnackbar('delete success', { variant: 'success', });
          updateCollections(collections.filter((_collection) => _collection.id !== collectionToDelete.id));
          setFilteredCollections(collections.filter((_collection) => _collection.id !== collectionToDelete.id));
        })
        .catch((error) => {
          enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        });
    }
  };

  return (
    <div className={classes.root}>
      <Paper hidden={!readAccess} className={classes.paper}>
        <CollectionTableToolbar handleSearch={handleSearch}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CollectionTableHead
              classes={classes}
              order={filters.order}
              orderBy={filters.orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {
                stableSort(
                  filteredCollections,
                  getComparator(filters.order, filters.orderBy)
                )
                  .slice(filters.page * filters.rowsPerPage, filters.page * filters.rowsPerPage + filters.rowsPerPage)
                  .map(
                    (collection: Interfaces.Collection, index: number) => {
                      console.log({ collection, });
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={collection.name}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell align="left">{collection.name}</TableCell>
                          <TableCell align="left" >
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}>
                              <DataSourceLogo
                                name={collection.database.databaseType as any}
                                type={collection.database.databaseType as any}
                                margin={'0 20px'}
                                maxWidth={'50px'}
                                maxHeight={'30px'}
                              />
                              {collection.dataSourceId !== '-1' ?
                                `${collection.database.databaseProductName} / ${collection.database.databaseType.toUpperCase()}` :
                                `New DataSource / ${collection.database.databaseType.toUpperCase()}`}
                            </div>

                          </TableCell>
                          <TableCell align="left" className={classes.dataSourceActionsCell}>
                            {
                              readDSAccess && readAccess && writeAccess && (
                                <>
                                  <Tooltip title="Edit">
                                    <IconButton
                                      style={{
                                        padding: '3px 3px',
                                        marginRight: '10px',
                                      }}
                                      aria-label="edit"
                                      onClick={() => {
                                        handleEdit(collection);
                                      }}
                                    >
                                      <Edit/>
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )
                            }
                            {
                              (adminAccess || deploymentType === 'cloud') && (
                                <>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      style={{
                                        padding: '3px 3px',
                                        marginRight: '10px',
                                      }}
                                      aria-label="delete"
                                      onClick={() => {
                                        handleDeletePopup(collection);
                                      }}
                                    >
                                      <Delete/>
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )
                            }
                            {
                              executeAccess && readDepAccess && (
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label="Deploy"
                                  style={{
                                    fontWeight: 'bold',
                                    color: 'white',
                                    background: 'green',
                                    borderColor: 'white',
                                  }}
                                  onClick={() => history.push(`/collection/${collection.id}/deployment`)}
                                />
                              )
                            }
                          </TableCell>

                        </TableRow>
                      );
                    }
                  )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25,]}
          component="div"
          count={filteredCollections.length}
          rowsPerPage={filters.rowsPerPage}
          page={filters.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ModalConfirmation
        open={deletePopupState}
        onClose={() => handleDeletePopup(null)}
        title={'Are you sure you want to delete the Git Config?'}
        onBtnClick={() => deleteAction()}
        colorBtn={'secondary'}
        btnText={'Delete'}
        onCancelBtnClick={() => handleDeletePopup(null)} />
    </div>
  );
};

export { CollectionTable, };
