import React, {
  useEffect,
  useState,
} from 'react';
import {
  Chart,
  registerables,
} from 'chart.js';

Chart.register(...registerables);
import { Bar, } from 'react-chartjs-2';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
} from '@material-ui/core';

const TotalRecordsChart = ({
  totalRecords,
  fetchTotalRecords,
  loadingTotalRecords,
  tableName,
}: {
  totalRecords: Array<any> | null;
  fetchTotalRecords: () => void;
  loadingTotalRecords: boolean;
  tableName?: string;
}) => {
  const [value, setValue,] = useState<any>(null);

  const getData = (totalRecords: Array<any>) => ({
    datasets: [
      {
        backgroundColor: '#425a8f',
        barPercentage: 0.8,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 1,
        data: totalRecords.map((tr: any) => tr.totalRecords),
        label: 'Total records',
      },
      {
        backgroundColor: '#53d55d',
        barPercentage: 0.8,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 1,
        data: totalRecords.map((tr: any) => tr.sizeInBytes >= 0 ? tr.sizeInBytes / 1024 : 0),
        label: 'Size in KB',
      },
    ],
    labels: totalRecords.map((tr: any) => tr.name),
  });

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    if (totalRecords && Symbol.iterator in Object(totalRecords)) {
      const element = [...totalRecords,].find((_table) => _table.name === tableName);
      if (element) {
        setValue(element);
        return;
      }
      const elements = tableName === '' ? [...totalRecords,] : [...totalRecords,].filter((_table) => _table.name.startsWith(tableName));
      if (elements.length === 0) {
        setValue({
          totalRecords: -1,
          sizeInBytes: -1,
        });
        return;
      }
      let totalR = 0;
      let totalS = 0;
      elements.forEach((element) => {
        totalR += element.totalRecords;
        totalS += element.sizeInBytes;
      });
      setValue({
        totalRecords: totalR,
        sizeInBytes: totalS,
      });
    }
  }, [totalRecords, tableName,]);

  const elements = React.useMemo(() => {
    if (totalRecords) {
      return tableName === '' ? [...totalRecords,] : [...totalRecords,].filter((_table) => _table.name.startsWith(tableName));
    }
    return [];
  }, [tableName, totalRecords,]);

  return (
    <Grid
      container
      justifyContent="center"
      style={{
        marginTop: 10,
        overflowY: 'auto',
      }}
    >
      {
        value?.totalRecords !== -1 && elements.length > 0 && (
          <Card style={{ width: '100%', }}>
            <CardHeader title={'Total records'}/>
            <CardContent>
              <Box sx={{
                position: 'relative',
                textAlign: 'center',
              }}>
                {
                  loadingTotalRecords && (
                    <CircularProgress size={40}/>
                  )
                }
                {
                  !loadingTotalRecords && elements.length > 0 && (
                    <div
                      style={{
                        minHeight: elements.length * 40,
                        overflow: 'auto',
                      }}
                    >
                      <Bar
                        data={getData(elements)}
                        style={{
                          position: 'relative',
                        }}
                        options={options}
                      />
                    </div>
                  )
                }
                {
                  !loadingTotalRecords && tableName === '' && (
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      style={{ marginTop: 20, }}
                      onClick={() => {
                        fetchTotalRecords();
                      }}
                    >
                      {totalRecords ? 'Update total records' : 'Get total records'}
                    </Button>
                  )
                }
              </Box>
            </CardContent>
          </Card>
        )
      }
    </Grid>
  );
};

export { TotalRecordsChart, };
