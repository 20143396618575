import React from 'react';
import { useStyles, } from '../../styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from '@material-ui/core';
import { VpnKey, } from '@material-ui/icons';
import { Interfaces, } from '../../../../../config';
import { helpers, } from '../../helpers';

const StyledTableCell = styled(TableCell)(({ theme, }) => ({
  maxWidth: '350px',
  maxHeight: '50px',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  [`&.th`]:
    {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  [`&.tbody`]:
    {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme, }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const NodeMetadataFields = ({
  catalog,
  selectedNode,
  handleSelectNode,
  setScrollToNode,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  selectedNode: Interfaces.Schema;
  // eslint-disable-next-line no-unused-vars
  handleSelectNode: (node: Interfaces.Schema | null) => void;
  // eslint-disable-next-line no-unused-vars
  setScrollToNode?: (node: Interfaces.Schema) => void;
}) => {
  const classes = useStyles();

  const onCellClick = (value: Interfaces.Schema) => {
    if (value) {
      handleSelectNode(value);
      setScrollToNode && setScrollToNode(value);
    }
  };

  return selectedNode?.fields?.length > 0 ? (
    <Grid item xs={12}>
      <p className={classes.sectionTitle} style={{ marginTop: '20px', }}>
        Fields
      </p>
      <TableContainer
        style={{
          width: '100%',
          overflowX: 'auto',
          border: '1px solid #e0e0e0',
        }}
        component={Paper}
      >
        <Table stickyHeader size="small" aria-label="customized table">
          <TableHead key="table_head_1">
            <TableRow key="header-1">
              <StyledTableCell style={{ fontWeight: 'bold', }} align="left" width={60} key={0}>#</StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', }} align="left">Name</StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', }} align="left">Source Type</StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', }} align="left">Type</StyledTableCell>
              {selectedNode.sourceType === 'table' &&
                <StyledTableCell style={{ fontWeight: 'bold', }} align="left">Foreign Key</StyledTableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (selectedNode.sourceType === 'table' || selectedNode.sourceType === 'schema' || selectedNode.sourceType === 'view') &&
              selectedNode.fields.map((item: any, index: number) => {
                return (
                  <StyledTableRow
                    key={index}
                    style={{ cursor: 'pointer', }}
                  >
                    <StyledTableCell align="left" onClick={() => onCellClick(item)}>{index + 1}</StyledTableCell>
                    <StyledTableCell align="left" onClick={() => onCellClick(item)}>{item.name}</StyledTableCell>
                    <StyledTableCell align="left" onClick={() => onCellClick(item)}>{item.sourceType}</StyledTableCell>
                    <StyledTableCell align="left" onClick={() => onCellClick(item)}>{item.type}</StyledTableCell>
                    {selectedNode.sourceType === 'table' &&
                      <StyledTableCell align="left">{item.properties?.foreignKeys ?
                        <p
                          style={{
                            color: '#0b52a4',
                            textDecoration: 'underline',
                            margin: 0,
                          }}
                          onClick={() => {
                            const path =
                              `${item.properties?.foreignKeys[0].primaryTableSchema}.`
                              + `${item.properties.foreignKeys[0].primaryTableName}.`
                              + `${item.properties.foreignKeys[0].primaryColumnName}.`;
                            const node = helpers.findElement(catalog.schema.fields, path);
                            if (node) {
                              handleSelectNode(node);
                              setScrollToNode && setScrollToNode(node);
                            }
                          }}
                        >
                          <VpnKey className={classes.foreignKeyIcon} />
                          {
                            `${item.properties.foreignKeys[0].primaryTableName}.${item.properties.foreignKeys[0].primaryColumnName}`
                          }
                        </p>
                        : <p style={{ margin: 0, }} onClick={() => onCellClick(item)}>-</p>}
                      </StyledTableCell>
                    }
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  ) : null;
};

export { NodeMetadataFields, };
