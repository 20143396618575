import React from 'react';
import { Interfaces, } from '../../../config';
import {
  Button,
  createStyles,
  Grid,
  Input,
  TextField,
  Typography,
  Box,
  Switch,
} from '@material-ui/core';
import { CatalogCard, } from './CatalogCard';
import LoadingComponent from '../../Loading';
import { makeStyles, } from '@material-ui/core/styles';
import { useHistory, } from 'react-router-dom';
import { useAuth, } from '../../../context';
import { catalogsServices, } from '../../../services';
import { useSnackbar, } from 'notistack';
import { AxiosResponse, } from 'axios';
import { helpers, } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    searchInput: {
      display: 'flex',
      width: '100%',
    },
    container: {
      paddingInline: '10%',
      alignSelf: 'center',
    },
    fieldsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
    },
    fullHeight: {
      height: '100%',
    },
  })
);

interface CatalogCardProps {
  toggle: boolean;
  // eslint-disable-next-line no-unused-vars
  changeToggle: () => void;
  catalogs: Interfaces.InputCatalogMetadata[];
  loading: boolean;
  fetchCatalogs: () => void;
}

const CatalogCardsView = ({ changeToggle, toggle, catalogs, loading, fetchCatalogs, }: CatalogCardProps) => {
  const { enqueueSnackbar, } = useSnackbar();
  const [runningCatalogs, setRunningCatalogs,] = React.useState<Array<string>>([]);
  const [filteredCatalogs, setFilteredCatalogs,] = React.useState<Array<Interfaces.InputCatalogMetadata>>(catalogs);
  const [keyword, setKeyword,] = React.useState<string>('');
  const classes = useStyles();
  const history = useHistory<any>();
  const { isAuthorized, } = useAuth();
  const [readAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_READ'));
  const [writeAccess,] = React.useState(isAuthorized('ADAPTIVE_CAT_WRITE'));

  const parseJsonFile= async (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      // @ts-ignore
      fileReader.onload = (event) => resolve(JSON.parse(event.target.result));
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsText(file);
    });
  };

  const uploadCatalog = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    try {
      // @ts-ignore
      const externalCatalog: Interfaces.InputCatalogMetadata = await parseJsonFile(event?.currentTarget?.files[0]);
      catalogsServices.addThirdPartyCatalog(externalCatalog).then(() => {
        enqueueSnackbar('Added successfully', { variant: 'success', });
        fetchCatalogs();
      }).catch((error: any) => {
        enqueueSnackbar(error.message || 'Unknown error', { variant: 'error', });
      });
    } catch (error: any) {
      enqueueSnackbar(error.message || 'Unknown error', { variant: 'error', });
    }
  };

  const runCatalog = (catalogId: string) => {
    setRunningCatalogs([...runningCatalogs, catalogId,]);
    catalogsServices.runCatalog(catalogId)
      .then((response: AxiosResponse<Interfaces.Schema>) => {
        enqueueSnackbar(`Successfully ran catalog: '${response.data?.properties?.catalogName || catalogId}'`, { variant: 'info', });
        fetchCatalogs();
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        const _idx = runningCatalogs.findIndex((cId: string) => cId !== catalogId);
        runningCatalogs.splice(_idx, 1);
        setRunningCatalogs(runningCatalogs);
      });
  };

  const deleteCatalog = (catalogId: string) => {
    catalogsServices.deleteCatalog(catalogId)
      .then(() => {
        fetchCatalogs();
        enqueueSnackbar(`Catalog with id ${catalogId} deleted`, { variant: 'info', });
      })
      .catch(() => {
        enqueueSnackbar(`Failed to delete catalog with id = '${catalogId}'!`, { variant: 'error', });
      });
  };

  React.useEffect(() =>{
    if (keyword !== '') {
      setFilteredCatalogs(filterCatalogs(catalogs));
    } else {
      setFilteredCatalogs(catalogs);
    }
  }, [keyword,]);

  React.useEffect(() => {
    setFilteredCatalogs(catalogs);
  }, [catalogs,]);

  const filterCatalogs = (catalogs: Array<Interfaces.InputCatalogMetadata>) => {
    return catalogs.filter((catalog) => catalog.name.toLowerCase().includes(keyword.toLowerCase()));
  };

  if (loading && catalogs.length === 0) {
    return <LoadingComponent message={'Loading catalogs'} size={80} />;
  }

  return (
    <Grid container className={classes.container}>
      <Box display="flex" justifyContent="space-between" pb={2} width="100%">
        <Box display="flex" justifyContent='start' alignItems="center" flexGrow="1" mr="20px">
          {writeAccess && (
            <>
              <Button
                variant='contained'
                color='primary'
                style={{
                  marginRight: '10px',
                  minWidth: '180px',
                }}
                onClick={()=> history.push('/catalog/add')}
              >
          Add Catalog
              </Button>
              <Box mr={2}>
                <Input
                  id="contained-button-file"
                  type="file"
                  inputProps={{ accept:'application/json', }}
                  onChange={async (event) => uploadCatalog(event)}
                  hidden
                  style={{ display: 'none', }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{
                      marginLeft: '10px',
                      minWidth: '180px',
                      height: '100%',
                    }}
                  >
                  Upload Catalog
                  </Button>
                </label>
              </Box>
            </>
          )}

          <TextField
            variant={'outlined'}
            size="small"
            placeholder={'Filter catalogs'}
            className={classes.searchInput}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center">
          <Grid item>
              Table View
          </Grid>
          <Grid item>
            <Switch
              color='primary'
              checked={toggle}
              onChange={changeToggle}
              name="toggleSwitch" />
          </Grid>
          <Grid item>
              List View
          </Grid>
        </Box>
      </Box>
      <Grid item xs={12}>
        { filteredCatalogs.length === 0 ? (
          <Grid
            container
            style={{
              marginTop: '10%',
            }}
          >
            <Grid item xs={2} />
            <Grid
              item
              xs={7}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography
                color="textSecondary"
                style={{ fontSize: 20, }}
              >
                No Catalogs with this name
              </Typography>
            </Grid>
          </Grid>
        ) : (
          readAccess &&
          filteredCatalogs.map((catalog) => (
            <CatalogCard
              key={catalog.id}
              catalog={catalog}
              runCatalog={runCatalog}
              deleteCatalog={deleteCatalog}
              loadingCatalogs={runningCatalogs}
            />
          )))
        }
      </Grid>
    </Grid>
  );
};

export default CatalogCardsView;


