import React from 'react';

interface SidebarContextType {
  isOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (val: boolean) => void;
}

const SidebarContext = React.createContext<SidebarContextType>({
  isOpen: false,
  setIsOpen: () => {},
});

const SidebarProvider = ({ children, }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen,] = React.useState(false);

  const value = React.useMemo(() => {
    return {
      isOpen,
      setIsOpen,
    };
  }, [isOpen,]);

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
};

const useSidebar = () => {
  return React.useContext(SidebarContext);
};

export { useSidebar, SidebarProvider, };

