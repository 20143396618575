import { makeStyles, } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    borderTop: 'solid 1px #80808017',
    background: '#8080800a',
    textAlign: 'center',
    paddingBottom: '0.35rem',
  },
  img: {
    display: 'flex',
    paddingBottom: '25px',
    position: 'relative',
    width: '39%',
    height: 'auto',
    margin: 'auto',
    paddingTop: '20px',
  },
  imgJdbc: {
    display: 'flex',
    paddingBottom: '24px',
    position: 'relative',
    width: '39%',
    height: 'auto',
    margin: 'auto',
    paddingTop: '19px',
  },
  boxContent: {
    p: 3,
    height: 180,
    width: 240,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    maxWidth: 345,
    width: 240,
  },
  media: {
    width: 140,
  },
  fileMedia: {
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
