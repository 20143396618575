import React, { useState, } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Box,
} from '@material-ui/core';
import { Interfaces, } from '../../../config';
import { useHistory, } from 'react-router-dom';
import LoadingComponent from '../../Loading';
// import moment from 'moment';
import { alertsServices, } from '../../../services';
import AlertsTableToolbar from './AlertsTableToolbar';
import AlertsTableHead from './AlertsTableHead';
import { useSnackbar, } from 'notistack';
import {
  getComparator,
  stableSort,
} from './helpers';
import { useStyles, } from './styles';
import { NoDataComponent, } from '../../NoDataComponent';
import { useAuth, } from '../../../context';
import { ModalConfirmation, } from '../../ModalConfirmation';
import { AlertsTableRow, } from './AlertsTableRow';
import { AxiosResponse, } from 'axios';
import { helpers, } from '../../../utils';

const navigationData = {
  component: 'alerts',
  header: 'No Alerts',
  text1: 'You have not created an alert yet',
  text2: 'Create an alert to get started',
  buttonText: 'Add an Alert',
  route: '/alerts/add',
};

const AlertsTable = () => {
  const classes = useStyles();
  const { enqueueSnackbar, } = useSnackbar();
  const history = useHistory();
  const { isAuthorized, } = useAuth();

  const [loading, setLoading,] = React.useState(false);
  const [alerts, setAlerts,] = React.useState<Array<Interfaces.Alert>>([]);
  const [error, setError,] = useState<string>();
  const [triggerUpdate, setTriggerUpdate,] = React.useState(false);
  const [filteredAlerts, setFilteredAlerts,] = React.useState<Array<Interfaces.Alert>>([]);
  const [alertToDelete, setAlertToDelete,] = React.useState<Interfaces.Alert | null>(null);
  const [rowsPerPage, setRowsPerPage,] = React.useState(5);
  const [filters, setFilters,] = React.useState({
    checked: false,
    order: 'asc' as 'asc' | 'desc',
    orderBy: 'id',
    page: 0,
  });
  const [readAccess,] = useState(isAuthorized('ADAPTIVE_ALERT_READ'));
  const [writeAccess,] = useState(isAuthorized('ADAPTIVE_ALERT_WRITE'));
  const [readDSAccess,] = useState(isAuthorized('ADAPTIVE_DS_READ'));
  const [readCATAccess,] = useState(isAuthorized('ADAPTIVE_CAT_READ'));
  const [adminAccess,] = useState(isAuthorized('ADAPTIVE_ADMIN'));

  const fetchAlerts = () => {
    alertsServices.fetchAlerts()
      .then((response: AxiosResponse<{ data: Array<Interfaces.Alert>}>) => {
        setAlerts(response.data.data);
        setFilteredAlerts(response.data.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
        setError(helpers.getErrorMessage(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    fetchAlerts();
  }, []);

  if (error) {
    return <div id={'error'}>{error}</div>;
  }

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div id={'alerts-table-container'}>
      {
        alerts.length === 0 ? (
          <NoDataComponent data={navigationData} />
        ) : (
          <div className={classes.root}>
            <Box display="flex" justifyContent='space-between' pb={2} >
              <Box display="flex" justifyContent='center'>
                <Button
                  style={{
                    marginRight: 10,
                    minWidth: 180,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push('/alerts/add');
                  }}
                >
                  Add alert
                </Button>
              </Box>
            </Box>
            {readAccess &&(
              <Paper className={classes.paper}>
                <AlertsTableToolbar
                  handleSearch={(event) => {
                    setFilteredAlerts(
                      alerts.filter((value) => value.name.toLowerCase().includes(event.target.value.toLowerCase()))
                    );
                  }}
                />
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="enhanced table"
                  >
                    <AlertsTableHead
                      order={filters.order}
                      orderBy={filters.orderBy}
                      onRequestSort={(property) => {
                        const isAsc = filters.orderBy === property && filters.order === 'asc';
                        setFilters({
                          ...filters,
                          order: isAsc ? 'desc' : 'asc',
                          orderBy: property,
                        });
                      }}
                    />
                    <TableBody>
                      {
                        stableSort(
                          filteredAlerts,
                          getComparator(filters.order, filters.orderBy)
                        )
                          .slice(filters.page * rowsPerPage, filters.page * rowsPerPage + rowsPerPage)
                          .map((alert: Interfaces.Alert, index: number) => (
                            <AlertsTableRow
                              key={index}
                              index={index}
                              alert={alert}
                              readAccess={readAccess}
                              writeAccess={writeAccess}
                              readDSAccess={readDSAccess}
                              readCATAccess={readCATAccess}
                              adminAccess={adminAccess}
                              handleDelete={(alert) => setAlertToDelete(alert)}
                            />
                          ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25,]}
                  component="div"
                  count={filteredAlerts.length}
                  rowsPerPage={rowsPerPage}
                  page={filters.page}
                  onPageChange={(_event, newPage) => {
                    setFilters({
                      ...filters,
                      page: newPage,
                    });
                  }}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value));
                  }}
                />
              </Paper>
            )}
            <ModalConfirmation
              open={alertToDelete !== null}
              onClose={() => setAlertToDelete(null)}
              title={'Are you sure you want to delete the alert?'}
              onBtnClick={() => {
                if (alertToDelete?.id) {
                  alertsServices.deleteAlert(alertToDelete.id)
                    .then(() => {
                      enqueueSnackbar('Successfully deleted alert', { variant: 'success', });
                      setTriggerUpdate(!triggerUpdate);
                    });
                  setAlertToDelete(null);
                }
              }}
              colorBtn={'secondary'}
              btnText={'Delete'}
              onCancelBtnClick={() => setAlertToDelete(null)} />
          </div>
        )
      }
    </div>
  );
};

export { AlertsTable, };
