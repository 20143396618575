import React, { useEffect, } from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {  Description, } from '@material-ui/icons';
import LoadingComponent from '../Loading';
import { Interfaces, } from '../../config';
import { useHistory, } from 'react-router-dom';
import { useSnackbar, } from 'notistack';
import { catalogsServices, } from '../../services';
import { AxiosResponse, } from 'axios';
import { helpers, } from '../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '500px',
      maxWidth: 360,
      backgroundColor: 'transparent',
    },
    titleMargin: {
      marginBottom: 10,
    },
    listBorder: {
      border: '1px solid #e2dfdf',
    },
  })
);

function CatalogSelection () {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, } = useSnackbar();
  const [catalogs, setCatalogs,] = React.useState<Interfaces.InputCatalogMetadata[]>([]);
  const [loading, setLoading,] = React.useState<boolean>(true);

  const fetchCatalogs = () => {
    setLoading(true);
    catalogsServices.fetchCatalogsWithDataSources()
      .then((response: AxiosResponse<Array<Interfaces.InputCatalogMetadata>>) => {
        setCatalogs(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(helpers.getErrorMessage(error), { variant: 'error', });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCatalogs();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Typography variant="h5" className={classes.titleMargin}>
        Select a catalog:
      </Typography>
      <List component="nav" className={classes.root} aria-label="contacts">
        {
          catalogs && catalogs.map((catalog: Interfaces.InputCatalogMetadata, index: number) => (
            <ListItem
              key={index}
              button
              className={classes.listBorder}
              onClick={() => history.push(`/catalog/edit/${catalog.id}/3`)}>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText primary={catalog.name} />
            </ListItem>
          ))
        }
      </List>
    </Grid>
  );
}

export default CatalogSelection;
