import React from 'react';
import { Typography, } from '@material-ui/core';
import { Inbox, } from '@material-ui/icons';

const DefaultMetadataPlaceHolder = () => {
  return (
    <div style={{
      position: 'relative',
      textAlign: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
    >
      <Typography
        style={{
          color: '#949494',
          marginTop: 5,
        }}
        variant="h4"
      >
        Metadata will appear here
      </Typography>
      <Inbox color="disabled" style={{ fontSize: '70', }} />
    </div>
  );
};

export { DefaultMetadataPlaceHolder, };
