import React from 'react';
import {
  IconButton,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import {
  Delete,
  Description,
  Edit,
} from '@material-ui/icons';
import { alertsServices, } from '../../../services';
import { Interfaces, } from '../../../config';
import { useSnackbar, } from 'notistack';
import { useHistory, } from 'react-router-dom';
import { useStyles, } from './styles';


interface Props {
  index: number;
  alert: Interfaces.Alert;
  readAccess: boolean;
  writeAccess: boolean;
  readDSAccess: boolean;
  readCATAccess: boolean;
  adminAccess: boolean;
  // eslint-disable-next-line no-unused-vars
  handleDelete: (alert: Interfaces.Alert) => void;
}

const AlertsTableRow = ({
  index,
  alert,
  readAccess,
  writeAccess,
  readDSAccess,
  readCATAccess,
  adminAccess,
  handleDelete,
}: Props) => {
  const { enqueueSnackbar, } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const [isActive, setIsActive,] = React.useState<boolean>(alert.active);

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={alert.name}
      className={'alerts-table-row'}
    >
      <TableCell>{index + 1}</TableCell>
      <TableCell align="left">{alert.name}</TableCell>
      <TableCell align="left">{alert.inputRule.name}</TableCell>
      <TableCell align="left">{alert.outputRule.name}</TableCell>
      <TableCell align="left" className={classes.dataSourceActionsCell}>
        {
          (readAccess && writeAccess && readDSAccess && readCATAccess) && (
            <Tooltip title="Edit">
              <IconButton
                style={{
                  padding: '3px 3px',
                  marginRight: '10px',
                }}
                aria-label="edit"
                onClick={() => {
                  history.push(`/alerts/add/${alert.id}`);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )
        }
        <Tooltip title="Report">
          <IconButton
            style={{
              padding: '3px 3px',
              marginRight: '10px',
            }}
            aria-label="report"
            onClick={() => {
              history.push(`alerts/report/${alert.id}`,'_self');
            }}
          >
            <Description/>
          </IconButton>
        </Tooltip>
        {
          adminAccess && (
            <Tooltip title="Delete">
              <IconButton
                style={{
                  padding: '3px 3px',
                  marginRight: '10px',
                }}
                aria-label="delete"
                onClick={() => {
                  handleDelete(alert);
                }}
              >
                <Delete/>
              </IconButton>
            </Tooltip>
          )
        }
        <Tooltip title={isActive ? 'Deactivate' : 'Activate'}>
          <Switch
            color='primary'
            checked={isActive}
            onClick={() => {
              if (isActive) {
                alertsServices.deactivateAlert(alert.id)
                  .then(() => {
                    enqueueSnackbar('Successfully deactivated alert', { variant: 'success', });
                    setIsActive(false);
                  });
              } else {
                alertsServices.activateAlert(alert.id)
                  .then(() => {
                    enqueueSnackbar('Successfully activated alert', { variant: 'success', });
                    setIsActive(true);
                  });
              }
            }}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export { AlertsTableRow, };
