import React from 'react';
import {
  TabContext,
  TabList,
} from '@material-ui/lab';
import {
  Box,
  Tab,
} from '@material-ui/core';
import { Interfaces, } from '../../../../config';
import { DataSourceType, } from '../helpers';

const HeaderTabs = ({
  catalog,
  activeStep,
  setActiveStep,
  loadingSchema,
  inEditMode,
}: {
  catalog: Interfaces.InputCatalogMetadata;
  activeStep: number;
  // eslint-disable-next-line no-unused-vars
  setActiveStep: (step: number) => void;
  selectedDataSource: DataSourceType | null;
  schemaError: boolean;
  loadingSchema: boolean;
  inEditMode: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <TabContext value={activeStep as any}>
          <TabList
            TabIndicatorProps={{ style: { backgroundColor: '#3f51b5', }, }}
            onChange={(event, value: number) => {
              setActiveStep(value);
            }}
            aria-label="basic tabs example">
            <Tab
              label="Catalog"
              value={1}
            />
            <Tab
              disabled={loadingSchema || !catalog.schema}
              label="ER Diagram"
              value={2}
            />
            <Tab
              disabled={(!catalog.name && !catalog.dataSourceId) || loadingSchema}
              label="Schedule"
              value={3}
            />
            <Tab
              disabled={!inEditMode || loadingSchema}
              label="Run History"
              value={4}
            />
          </TabList>
        </TabContext>
      </Box>
    </Box>
  );
};

export { HeaderTabs, };
