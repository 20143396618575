import { axiosClient, } from '../config/helpers';
import {
  Constants,
  Interfaces,
} from '../config';

export const collectionServices = {
  fetchCollections,
  fetchCollectionById,
  addCollection,
  updateCollection,
  deleteCollection,
  generateDbtModels,
};

function fetchCollections() {
  return axiosClient().get(Constants.api.endpoints.collections);
}

function fetchCollectionById(id: string) {
  return axiosClient().get(`${Constants.api.endpoints.collections}${id}`);
}

function addCollection(body: Interfaces.Collection) {
  return axiosClient().post(`${Constants.api.endpoints.collection}`, body);
}

function updateCollection(id: string, body: Interfaces.Collection) {
  return axiosClient().patch(`${Constants.api.endpoints.collections}${id}`, body);
}

function deleteCollection(id: string) {
  return axiosClient().delete(`${Constants.api.endpoints.collections}${id}`);
}

function generateDbtModels(collection: Interfaces.Collection) {
  return axiosClient().post(`${Constants.api.endpoints.collection}generate-dbt`, collection);
}
