import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  AdaptiveScaleDriver,
  AdaptiveScaleText,
} from './styles';
import adaptivescale from '../../images/adaptivescale.png';



const DriverInfoSection  = () => {
  return (
    <Grid item md={8} xs={12}>
      <Card>
        <CardContent>
          <Grid container item spacing={2} xs={12} alignItems='center'>
            <Grid item md={7} xs={12}>
              <AdaptiveScaleText
                align="center"
              >
                <span>AdaptiveScale JDBC Driver</span><br />
                AdaptiveScale JDBC driver enables interaction with its data sources and catalogs, and supports application interaction with different SQL clients.<br />
              </AdaptiveScaleText>
            </Grid>
            <Grid item md={5} xs={12}>
              <AdaptiveScaleDriver>
                <img
                  src={adaptivescale}
                  alt='adaptive_scale_logo'
                />
                <Typography gutterBottom variant='h6'>JDBC Driver</Typography>
                <Button variant='contained' color='primary' onClick={() => window.open('https://www.adaptivescale.com/wp-content/uploads/2023/02/jdbc-driver-2.1.9.jar_.zip', '_blank')}>DOWNLOAD DRIVER</Button>
              </AdaptiveScaleDriver>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export { DriverInfoSection, };
