import React from 'react';
import TreeItem, { TreeItemProps, } from '@material-ui/lab/TreeItem';
import {
  createStyles,
  SvgIconProps,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  size: any;
  time: any;
  labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
    },
    content: {
      color: theme.palette.text.secondary,
      paddingRight: theme.spacing(1),
      // fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
      minWidth: 440,
      marginBottom: 5,
    },
    group: {
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
      border: '1px solid #8080808a',
      padding: 5,
      borderRadius: 3,
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      minWidth: 150,
      maxWidth: 190,
    },
    labelInfo: {
      minWidth: 140,
      position: 'absolute',
      right: 4,
    },
  })
);

const StyledTreeItem = (props: StyledTreeItemProps) => {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, size, time, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Tooltip title={labelText}>
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </Tooltip>
          <div className={classes.labelInfo}>
            {
              size !== 0 && <Typography variant="body2">
                  Size: {size}
              </Typography>
            }
            {
              time !== '1/1/1970' && <Typography variant="body2">
                  Created at: {time}
              </Typography>
            }
          </div>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

export { StyledTreeItem, };
