/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Rect,
  Text,
  Group,
} from 'react-konva';
import { Interfaces, } from '../../../../config';

interface Props {
  x: number;
  y: number;
  fields: Array<Interfaces.Schema>;
  table: Interfaces.Schema;
  sourceType?: string;
  detailed: boolean;
  columnHeight: number;
  handleSelect: (colIdx: number) => void;
  selectedFields: Array<Interfaces.FieldWithPosition>;
}

const DBDiagramTable = ({
  x,
  y,
  table,
  fields,
  sourceType,
  detailed,
  columnHeight,
  handleSelect,
  selectedFields,
}: Props) => {
  const TABLE_WIDTH = 200;
  return (
    <>
      <Rect
        width={TABLE_WIDTH}
        x={x}
        y={y}
        height={detailed ? fields.length * columnHeight + 28 : 35 }
        stroke={'#989797'}
        fill={'#ffffff'}
        cornerRadius={4}
      />
      <Rect
        width={TABLE_WIDTH}
        x={x}
        y={y - 10}
        height={35}
        fill={sourceType === 'view' ? '#0774d0' : '#034e8d'}
        stroke={'#989797'}
        cornerRadius={4}
      />
      <Text
        height={35}
        y={y - 10}
        x={x}
        text={table.name}
        width={TABLE_WIDTH}
        fontSize={22}
        fill="white"
        verticalAlign="middle"
        align={'center'}
      />
      {
        detailed && fields.map(((field, index) => (
          <Group
            key={index}
          >
            { index % 2 === 0 ? (
              <Rect
                width={TABLE_WIDTH - 2}
                key={index}
                height={columnHeight - 2}
                stroke={selectedFields.find((_field) => _field.properties.path === table.properties?.path)?.matchingIndex === index ? 'blue' : '#f1f1f1'}
                strokeWidth={2}
                fill={'#f1f1f1'}
                x={x + 1}
                y={y + (index + 1) * columnHeight}
              />
            ) : (
              <Rect
                width={TABLE_WIDTH - 2}
                key={index}
                height={columnHeight - 2}
                stroke={selectedFields.find((_field) => _field.properties.path === table.properties?.path)?.matchingIndex === index ? 'blue' : 'white'}
                strokeWidth={2}
                fill={'white'}
                x={x + 1}
                y={y + (index + 1) * columnHeight}
              />
            )
            }
            <Text
              padding={10}
              onClick={() => handleSelect(index)}
              key={index}
              x={x}
              y={y + (index + 1) * columnHeight}
              height={columnHeight}
              text={field.name.length >= 13 ? `${field.name.slice(0,13)}...` : field.name}
              fontSize={16}
              fill="black"
              verticalAlign="middle"
              align={'left'}
            />
            <Text
              onClick={() => handleSelect(index)}
              padding={10}
              key={index}
              x={x}
              y={y + (index + 1) * columnHeight}
              height={columnHeight}
              width={TABLE_WIDTH}
              text={`${field.type} ${field.properties?.foreignKeys?.length > 0 ? ' - FK' : ''}`}
              fontSize={12}
              fill="gray"
              verticalAlign="middle"
              align={'right'}
            />
          </Group>
        )))
      }
    </>
  );
};

export { DBDiagramTable, };
