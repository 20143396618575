import React from 'react';
import TreeItem, { TreeItemProps, } from '@material-ui/lab/TreeItem';
import {
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
// import Collapse from '@material-ui/core/Collapse';
// import {
//   animated,
//   useSpring,
// } from '@react-spring/web'; // web.cjs is required for IE 11 support
// import { TransitionProps, } from '@material-ui/core/transitions';

// function TransitionComponent(props: TransitionProps) {
//   const style = useSpring({
//     from: {
//       opacity: 0,
//       transform: 'translate3d(20px,0,0)',
//     },
//     to: {
//       opacity: props.in ? 1 : 0,
//       transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
//     },
//   });
//
//   return (
//     <animated.div style={style}>
//       <Collapse {...props} />
//     </animated.div>
//   );
// }

export const StyledSchemaItem = withStyles(() =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 26,
    },
  })
)((props: TreeItemProps) => (
  <TreeItem {...props}  />
));
